import styled from 'styled-components'

import font from '../../style/font'
import colors from '../../style/colors'

export default styled.th`
  color: ${colors.DARK};
  font-family: ${font.family};
  font-size: 1rem;
  padding: 0.75rem 0 0.5rem;
  text-align: left;
`
