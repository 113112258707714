import styled from 'styled-components'

import colors from '../../style/colors'
import font from '../../style/font'

export default styled.div<{secondary?: boolean}>`
  color: ${colors.PRIMARY};
  font-family: ${font.family};
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;

  ${({secondary}) =>
    secondary &&
    `
    color: ${colors.WHITE};
  `}
`
