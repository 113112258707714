import React from 'react'
import {useTranslation} from 'react-i18next'

import Column from '../Column'
import Title from './Title'
import Value from './Value'

interface Props {
  revenue: string
}

const Revenue: React.FC<Props> = ({revenue}) => {
  const {t} = useTranslation()

  return (
    <Column>
      <Title>{t('volume.psr.revenue')}</Title>
      <Value>{revenue}</Value>
    </Column>
  )
}

export default Revenue
