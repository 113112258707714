import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import ReactGA from 'react-ga'

import {useAuthContext} from '../../shared/auth/Provider'
import useAxios from '../../shared/useFetch'
import AuthLink from '../../shared/components/AuthLink'
import ROUTES from '../../routes'
import Form from './Form'

const initialValues = {email: '', password: ''}

const Login: React.FC = () => {
  const {t} = useTranslation()
  const {authenticate} = useAuthContext()
  const [{error, isFetching}, call] = useAxios(
    {
      url: '/auth/authenticate',
      method: 'POST'
    },
    {
      success: ({data: {accessToken}}) => {
        authenticate(accessToken)
      },
      fail: err => {
        if (err.response) {
          ReactGA.event({
            category: 'Login',
            action: `${err.response.status} - ${err.response.data.name}`,
            label: 'Login failed',
            nonInteraction: true
          })
        }
      }
    }
  )

  const onSubmit = useCallback(
    values => {
      call({data: values})
    },
    [call]
  )

  return (
    <>
      <Form
        error={error}
        isFetching={isFetching}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />

      <AuthLink
        text={t('login.forgot.text')}
        linkText={t('login.forgot.link')}
        link={ROUTES.RESET_PASSWORD}
      />
      <AuthLink
        text={t('login.signUp.text')}
        linkText={t('login.signUp.link')}
        link={ROUTES.SIGN_UP}
      />
    </>
  )
}

export default Login
