import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconProp} from '@fortawesome/fontawesome-svg-core'

import colors from '../../style/colors'

const Icon = styled(FontAwesomeIcon)`
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
`

const Container = styled.div`
  text-align: center;
  width: 1.5rem;
`

interface Props {
  icon: IconProp
}

const Extender: React.FC<Props> = props => {
  return (
    <Container>
      <Icon {...props} color={colors.DARK} />
    </Container>
  )
}

export default Extender
