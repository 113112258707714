import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router'
import ReactGA from 'react-ga'

import ROUTES from '../../routes'
import Message from '../../shared/components/Message'
import Button from '../../shared/components/Button'
import useAxios from '../../shared/useFetch'
import Form from './Form'

const initialValues = {password: ''}

const Change: React.FC<RouteComponentProps> = ({location, history}) => {
  const {t} = useTranslation()
  const [isChanged, setIsChanged] = useState(false)
  const [{error, isFetching}, call] = useAxios(
    {
      url: '/user/password/change',
      method: 'POST'
    },
    {
      success: () => {
        ReactGA.event({
          category: 'Change Password',
          action: 'Changed',
          label: 'User password has been changed'
        })
        setIsChanged(true)
      },
      fail: err => {
        if (err.response) {
          ReactGA.event({
            category: 'Change Password',
            action: `${err.response.status} - ${err.response.data.name}`,
            label: 'Change password failed',
            nonInteraction: true
          })
        }
      }
    }
  )

  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  const onSubmit = useCallback(
    values => {
      call({data: {...values, token}})
    },
    [call, token]
  )

  useEffect(() => {
    if (!token) {
      ReactGA.event({
        category: 'Change Password',
        action: 'Token missing',
        label: 'Change password token is missing'
      })
    }
  }, [token])

  if (!token) {
    return <Message>{t('change.error.token.missing')}</Message>
  }

  if (isChanged)
    return (
      <>
        <Message>{t('change.success')}</Message>
        <Button type="button" secondary onClick={() => history.push(ROUTES.LOGIN)}>
          {t('change.login')}
        </Button>
      </>
    )

  return (
    <Form error={error} isFetching={isFetching} initialValues={initialValues} onSubmit={onSubmit} />
  )
}

export default withRouter(Change)
