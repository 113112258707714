import React from 'react'

import colors from '../../../style/colors'

interface Props {
  color?: string
}

const Team: React.FC<Props> = ({color = colors.WHITE}) => (
  <svg width='39' height='42' xmlns='http://www.w3.org/2000/svg'>
    <g
      transform='translate(1 1)'
      stroke={color}
      fill='none'
      fillRule='evenodd'
      opacity='1'
      strokeLinecap='round'
    >
      <circle strokeLinejoin='round' cx='11.574' cy='11.074' r='6.574' />
      <path
        d='M13.648 6.996a4.574 4.574 0 0 0-4.155 8.153M23.254 2.748a4.574 4.574 0 0 0 4.983 7.675M10.948 18a10.968 10.968 0 0 1 10.968 10.968v10.967H0V28.968C0 22.91 4.91 18 10.968 18h-.02z'
        strokeLinejoin='round'
      />
      <circle strokeLinejoin='round' cx='25.71' cy='6.703' r='6.574' />
      <path
        d='M16.194 19.11a11.077 11.077 0 0 1 9.516-5.697c6.057 0 10.967 4.91 10.967 10.968v10.967H22.065'
        strokeLinejoin='round'
      />
      <path d='M5.83 21.283c-2.41 2.326-3.616 4.612-3.616 6.856v9.347M20.773 16.62c-1.693 1.411-2.396 2.135-2.108 2.17 2.92 1.251 5.251 6.947 5.251 8.005v3.847' />
    </g>
  </svg>
)

export default Team
