import React, {useCallback, useState} from 'react'

import colors from '../../../style/colors'
import Container from './Container'

interface Props {
  icon: React.ReactElement
  route: string
  selected: boolean
}

const Item: React.FC<Props> = ({icon, route, selected}) => {
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [setIsHovered])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [setIsHovered])

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selected={selected}
      href={route}
    >
      {isHovered && !selected ? React.cloneElement(icon, {color: colors.PRIMARY}) : icon}
    </Container>
  )
}

export default Item
