import {AxiosError} from 'axios'

interface ErrorConfig {
  key: string
  check: (err: AxiosError) => boolean
}

export const getErrorMessageKey = (error: AxiosError, checks?: ErrorConfig[]): string => {
  let messageKey = 'common.error.spa'
  if (error.response && error.response.status === 500) {
    messageKey = 'common.error.500'
  } else if (error.response && error.response.status === 403) {
    messageKey = 'common.error.403'
  }
  const validErrorConfig = checks && checks.find((c: ErrorConfig) => c.check(error))
  if (validErrorConfig) messageKey = validErrorConfig.key

  return messageKey
}
