import React from 'react'
import {useTranslation} from 'react-i18next'

import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import LineGraph, {LineGraphProps} from '../LineGraph'

interface Props extends CategorySelectorProps, LineGraphProps {}

const TemperatureGraph: React.FC<Props> = ({timeFormat, data, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Card title={t('technic.temperature.title')} {...rest}>
      <LineGraph
        data={data}
        timeFormat={timeFormat}
        valueType="&#8451;"
        emptyText={t('technic.temperature.empty')}
      />
    </Card>
  )
}

export default TemperatureGraph
