import styled from 'styled-components'

import media from '../../shared/style/media'

export default styled.div`
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  justify-items: stretch;
  
  ${media.lessThan('M')`
    display: flex;
    flex-direction: column;
  `}
`
