import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import TextArea from '../../../shared/components/TextArea'
import Select from '../../../shared/components/Select'
import Button from '../../../shared/components/Button'
import FormContainer from '../FormContainer'
import ButtonsContainer from '../ButtonsContainer'

const options: any[] = []

for (let i = 0; i < 24; i++) {
  options.push({
    label: `${i}:00`,
    value: i
  })
}

interface FormProps {
  address: string
  currency: string
  id?: number
  openingHour?: number
}

interface Props extends FormikConfig<FormProps> {
  cancel: () => void
  error?: AxiosError
  isFetching?: boolean
}

const Form: React.FC<Props> = ({cancel, error, initialValues, isFetching, onSubmit, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        address: Yup.string(),
        currency: Yup.string().required(t('common.error.required')),
        openingHour: Yup.number().required(t('common.error.required')),
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && (
              <Error
                component={ErrorComponent}
                error={error}
                converters={[
                  {
                    key: 'config.info.error.validation',
                    check: err => !!err.response && err.response.status === 400
                  },
                  {
                    key: 'config.info.error.notFound',
                    check: err => !!err.response && err.response.status === 404
                  }
                ]}
              />
            )}
            <Field
              id="config-form-address"
              name="address"
              component={TextArea}
              label={t('config.info.form.address.label')}
              placeholder={t('config.info.form.address.placeholder')}
            />
            <Field
              id="config-form-opening-hour"
              name="openingHour"
              options={options}
              component={Select}
              label={t('config.info.form.openingHour.label')}
              placeholder={t('config.info.form.openingHour.placeholder')}
            />
            <Field
              id="config-form-currency"
              name="currency"
              type="text"
              component={Input}
              label={t('config.info.form.currency.label')}
              placeholder={t('config.info.form.currency.placeholder')}
            />

            <ButtonsContainer>
              <Button
                tertiary
                disabled={isFetching}
                type="button"
                onClick={() => {
                  props.handleReset()
                  cancel()
                }}
              >
                {t('config.info.form.cancel')}
              </Button>

              <Button disabled={isFetching} type="submit">
                {isFetching && <FontAwesomeIcon icon={faCog} spin />}
                {!isFetching && t('config.info.form.submit')}
              </Button>
            </ButtonsContainer>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default Form
