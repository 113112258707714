import {Key, VolumeEvent, VolumeEventV2, WasteEventV2} from '../../types'

export const teamNormalizer = (keys: Key[], volumes: VolumeEvent[], waste: VolumeEvent[]) => {
  const team = keys.reduce((t: any, key: Key) => {
    t[key.identifier] = {
      name: key.owner
        ? `[${key.owner.name}] ${key.owner.owner.firstname} ${key.owner.owner.lastname}`
        : key.name,
      sold: {},
      waste: {}
    }
    return t
  }, {})
  volumes.forEach((v: VolumeEvent) => {
    const keyIdentifier = parseInt(v.emp_key, 10)
    if (v.product && typeof v.product !== 'string' && keyIdentifier in team) {
      const category = v.product.category
      if (!(category in team[keyIdentifier].sold)) {
        team[keyIdentifier].sold[category] = {
          revenue: 0,
          volume: 0,
          products: {}
        }
      }
      if (['Coffee', 'Bottles'].includes(category)) {
        team[keyIdentifier].sold[category].revenue += v.product.price
        team[keyIdentifier].sold[category].volume += 1

        if (v.product.id in team[keyIdentifier].sold[category].products) {
          team[keyIdentifier].sold[category].products[v.product.id].revenue += v.product.price
          team[keyIdentifier].sold[category].products[v.product.id].volume += 1
        } else {
          team[keyIdentifier].sold[category].products[v.product.id] = {
            revenue: v.product.price,
            volume: 1,
            details: v.product
          }
        }
      } else {
        const rev = parseFloat(((v.volume / v.product.portion) * v.product.price).toFixed(2))
        team[keyIdentifier].sold[category].revenue += rev
        team[keyIdentifier].sold[category].volume += v.volume

        if (v.product.id in team[keyIdentifier].sold[category].products) {
          team[keyIdentifier].sold[category].products[v.product.id].revenue += rev
          team[keyIdentifier].sold[category].products[v.product.id].volume += v.volume
          team[keyIdentifier].sold[category].products[v.product.id].count += 1
        } else {
          team[keyIdentifier].sold[category].products[v.product.id] = {
            revenue: rev,
            volume: v.volume,
            count: 1,
            details: v.product
          }
        }
      }
    }
  })
  waste.forEach((v: VolumeEvent) => {
    const keyIdentifier = parseInt(v.emp_key, 10)
    if (v.product && typeof v.product === 'string' && keyIdentifier in team) {
      const category = v.category
      const productName = v.product
      if (category && !(category in team[keyIdentifier].waste))
        team[keyIdentifier].waste[category] = {}
      if (category && productName && !(productName in team[keyIdentifier].waste[category]))
        team[keyIdentifier].waste[category][productName] = []
      if (category && productName) team[keyIdentifier].waste[category][productName].push(v)
    }
  })

  return team
}

export const teamNormalizerV2 = (
  keys: Key[],
  volumes: VolumeEventV2[],
  waste: WasteEventV2[],
  pos: any[],
  storno: any[]
) => {
  const team = keys.reduce((t: any, key: Key) => {
    t[key.identifier] = {
      name: key.owner
        ? `[${key.owner.name}] ${key.owner.owner.firstname} ${key.owner.owner.lastname}`
        : key.name,
      sold: {},
      waste: {},
      pos: {},
      storno: {}
    }
    return t
  }, {})

  volumes.forEach((v: VolumeEventV2) => {
    if (v.product && v.key_id in team) {
      if (!(v.product.category in team[v.key_id].sold)) {
        team[v.key_id].sold[v.product.category] = {
          revenue: 0,
          volume: 0,
          products: {}
        }
      }
      if (['Coffee', 'Bottles'].includes(v.product.category)) {
        const rev = parseFloat((v.product.price * v.quantity).toFixed(2))
        team[v.key_id].sold[v.product.category].revenue += rev
        team[v.key_id].sold[v.product.category].volume += v.quantity

        if (v.product.id in team[v.key_id].sold[v.product.category].products) {
          team[v.key_id].sold[v.product.category].products[v.product.id].revenue += rev
          team[v.key_id].sold[v.product.category].products[v.product.id].volume += v.quantity
        } else {
          team[v.key_id].sold[v.product.category].products[v.product.id] = {
            revenue: rev,
            volume: v.quantity,
            details: v.product
          }
        }
      } else {
        const rev = parseFloat(
          (((v.volume * v.quantity) / v.product.portion) * v.product.price).toFixed(2)
        )
        const vol = v.volume * v.quantity
        team[v.key_id].sold[v.product.category].revenue += rev
        team[v.key_id].sold[v.product.category].volume += vol

        if (v.product.id in team[v.key_id].sold[v.product.category].products) {
          team[v.key_id].sold[v.product.category].products[v.product.id].revenue += rev
          team[v.key_id].sold[v.product.category].products[v.product.id].volume += vol
          team[v.key_id].sold[v.product.category].products[v.product.id].count += v.quantity
        } else {
          team[v.key_id].sold[v.product.category].products[v.product.id] = {
            revenue: rev,
            volume: vol,
            count: v.quantity,
            details: v.product
          }
        }
      }
    }
  })

  pos.forEach((v: VolumeEventV2) => {
    if (v.product && v.key_id in team) {
      if (!(v.product.category in team[v.key_id].pos)) {
        team[v.key_id].pos[v.product.category] = {
          quantity: 0,
          products: {}
        }
      }
      if (['Coffee', 'Bottles'].includes(v.product.category)) {
        team[v.key_id].pos[v.product.category].qty += v.quantity

        if (v.product.id in team[v.key_id].pos[v.product.category].products) {
          team[v.key_id].pos[v.product.category].products[v.product.id].quantity += v.quantity
        } else {
          team[v.key_id].pos[v.product.category].products[v.product.id] = {
            quantity: v.quantity,
            details: v.product
          }
        }
      } else {
        team[v.key_id].pos[v.product.category].quantity += v.quantity

        if (v.product.id in team[v.key_id].pos[v.product.category].products) {
          team[v.key_id].pos[v.product.category].products[v.product.id].quantity += v.quantity
        } else {
          team[v.key_id].pos[v.product.category].products[v.product.id] = {
            quantity: v.quantity,
            details: v.product
          }
        }
      }
    }
  })

  storno.forEach((v: VolumeEventV2) => {
    if (v.product && v.key_id in team) {
      if (!(v.product.category in team[v.key_id].storno)) {
        team[v.key_id].storno[v.product.category] = {
          quantity: 0,
          products: {}
        }
      }
      if (['Coffee', 'Bottles'].includes(v.product.category)) {
        team[v.key_id].storno[v.product.category].qty += v.quantity

        if (v.product.id in team[v.key_id].storno[v.product.category].products) {
          team[v.key_id].storno[v.product.category].products[v.product.id].quantity += v.quantity
        } else {
          team[v.key_id].storno[v.product.category].products[v.product.id] = {
            quantity: v.quantity,
            details: v.product
          }
        }
      } else {
        team[v.key_id].storno[v.product.category].quantity += v.quantity

        if (v.product.id in team[v.key_id].storno[v.product.category].products) {
          team[v.key_id].storno[v.product.category].products[v.product.id].quantity += v.quantity
        } else {
          team[v.key_id].storno[v.product.category].products[v.product.id] = {
            quantity: v.quantity,
            details: v.product
          }
        }
      }
    }
  })

  waste.forEach((v: WasteEventV2) => {
    if (v.category && v.name && v.key_id in team) {
      if (!(v.category in team[v.key_id].waste)) team[v.key_id].waste[v.category] = {}
      if (!(v.name in team[v.key_id].waste[v.category]))
        team[v.key_id].waste[v.category][v.name] = []
      team[v.key_id].waste[v.category][v.name].push(v)
    }
  })

  return team
}
