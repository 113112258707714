import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import FormContainer from '../../shared/components/Form'
import Button from '../../shared/components/Button'
import Input from '../../shared/components/Input'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'

interface Form {
  email: string
  password: string
}

interface Props extends FormikConfig<Form> {
  isFetching?: boolean
  error?: AxiosError
}

const LoginForm: React.FC<Props> = ({onSubmit, initialValues, isFetching, error}) => {
  const {t} = useTranslation()

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('common.error.email.format'))
          .required(t('common.error.required')),
        password: Yup.string().required(t('common.error.required'))
      })}
      render={({handleSubmit}) => (
        <FormContainer onSubmit={handleSubmit}>
          {error && (
            <Error
              component={ErrorComponent}
              secondary
              error={error}
              converters={[
                {
                  key: 'login.error.match',
                  check: err => !!err.response && err.response.status === 400
                }
              ]}
            />
          )}

          <Field
            id="login-email"
            name="email"
            type="email"
            component={Input}
            label={t('common.email')}
            placeholder={t('common.email')}
            secondary
          />
          <Field
            id="login-password"
            name="password"
            type="password"
            component={Input}
            label={t('common.password.label')}
            placeholder={t('common.password.label')}
            secondary
          />

          <Button disabled={isFetching} type="submit" secondary>
            {isFetching && <FontAwesomeIcon icon={faCog} spin />}
            {!isFetching && t('login.text')}
          </Button>
        </FormContainer>
      )}
    />
  )
}

export default LoginForm
