import React from 'react'

import {useAuthContext} from '../../../shared/auth/Provider'
import Container from './Container'
import Content from './Content'
import Revenue from './Revenue'
import PSR from './PSR'
import Marketing from './Marketing'

interface Props {
  psr: any[]
  revenue: string
  horeca: any
}

const Pulse: React.FC<Props> = ({psr, revenue, horeca, ...rest}) => {
  const {isAdmin} = useAuthContext()

  return (
    <Container {...rest}>
      <Content>
        {(isAdmin || horeca.activationType === 'PRO+') && <PSR psr={psr} />}
        <Revenue revenue={revenue} />
      </Content>
      <Marketing />
    </Container>
  )
}

export default Pulse
