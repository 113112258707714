import React from 'react'
import {useTranslation} from 'react-i18next'

// @ts-ignore
import CanvasJSReact from '../../../shared/canvasjs/canvasjs.react'
import colors from '../../../shared/style/colors'
import font from '../../../shared/style/font'
import EmptyState from '../BarGraph/EmptyState'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

interface Props {
  containerProps?: {[key: string]: string}
  dataPoints: any[]
  isPSR?: boolean
  emptyText?: string
  fontColor?: string
  valueType?: string
}

const PieChart: React.FC<Props> = ({
  dataPoints,
  isPSR,
  emptyText,
  valueType,
  fontColor = colors.WHITE,
  containerProps = {}
}) => {
  const {t} = useTranslation()

  const subtitles = isPSR
    ? [
        {
          dockInsidePlotArea: true,
          fontColor: colors.WHITE,
          fontFamily: font.family,
          fontSize: 24,
          text: `${t('volume.psr.short')}: ${dataPoints[1].y.toFixed(2)}%`,
          verticalAlign: 'center'
        }
      ]
    : []

  const contentFormatter =
    isPSR || valueType === 'L'
      ? (e: any) => {
          let content = ''
          for (let i = 0; i < e.entries.length; i++) {
            content = `${e.entries[i].dataPoint.name}:<br />${e.entries[i].dataPoint.volume.toFixed(
              2
            )} L`
          }
          return content
        }
      : (e: any) => {
          let content = ''
          for (let i = 0; i < e.entries.length; i++) {
            content = `${e.entries[i].dataPoint.name}:<br />${e.entries[i].dataPoint.volume.toFixed(
              2
            )} ${t('volume.coffee.unit')}`
          }
          return content
        }

  if (!isPSR && dataPoints.length === 0 && emptyText) {
    return <EmptyState text={emptyText} />
  }

  return (
    <CanvasJSChart
      containerProps={{
        maxWidth: '400px',
        width: '100%',
        ...containerProps
      }}
      options={{
        animationEnabled: true,
        backgroundColor: 'transparent',
        data: [
          {
            dataPoints,
            showInLegend: true,
            startAngle: -90,
            type: 'doughnut',
            yValueFormatString: "#,##'%'"
          }
        ],
        height: 350,
        legend: {
          fontColor
        },
        subtitles,
        toolTip: {contentFormatter},
        width: 400
      }}
    />
  )
}

export default PieChart
