import { CSSProperties } from 'react'

import font from '../../style/font'
import colors from '../../style/colors'
import {transparentize} from 'polished'

export default {
  option: (provided: CSSProperties) => ({
    ...provided,
    borderTop: `0.0625rem solid ${colors.WHITE}`,
    color: colors.WHITE,
    fontFamily: font.family
  }),

  container: (provided: CSSProperties) => ({
    ...provided,
    width: '12.5rem',
  }),

  control: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: colors.PRIMARY,
    border: 'none',
    boxShadow: 'none',
    color: colors.WHITE,
    fontFamily: font.family
  }),

  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    width: '2rem',
  }),

  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: transparentize(0.8, colors.WHITE)
  }),

  menu: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: colors.PRIMARY,
    margin: '0'
  }),

  menuList: (provided: CSSProperties) => ({
    ...provided,
    padding: '0'
  })
}
