import styled from 'styled-components'

import colors from '../../../shared/style/colors'
import font from '../../../shared/style/font'

export default styled.div`
  background-color: ${colors.SUCCESS};
  border-radius: 0.25rem;
  color: ${colors.WHITE};
  font-family: ${font.family};
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
  width: 100%;
`
