import React from 'react'

import Dimming from './Dimming'
import Container from './Container'

interface Props {
  close?: () => void
}

const Modal: React.FC<Props> = ({close, ...rest}) => {
  return (
    <>
      <Dimming onClick={close} />
      <Container {...rest} />
    </>
  )
}

export default Modal
