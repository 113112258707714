import React from 'react'
import styled from 'styled-components'

import colors from '../../shared/style/colors'
import font from '../../shared/style/font'

const Container = styled.span`
  cursor: pointer;
  color: ${colors.PRIMARY};
  font-size: 1rem;
  font-family: ${font.family};
  position: absolute;
  right: 0;
  text-decoration: underline;
  text-transform: uppercase;
  top: -2rem;

  &:hover {
    color: ${colors.PRIMARY_DARK};
  }

  &:focus {
    outline: 0;
  }
`

interface Props {
  onClick: () => void
  text: string
}

const Switch: React.FC<Props> = ({text, ...rest}) => {
  return <Container {...rest}>{text}</Container>
}

export default Switch
