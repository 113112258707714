import React from 'react'
import {useTranslation} from 'react-i18next'

import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import colors from '../../../shared/style/colors'
import PieGraph from '../PieGraph'

interface Props extends CategorySelectorProps {
  dataPoints: any[]
  emptyText: string
  valueType: string
}

const WasteGraph: React.FC<Props> = ({dataPoints, emptyText, valueType, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Card title={t('volume.waste.title')} {...rest}>
      <PieGraph
        dataPoints={dataPoints}
        emptyText={emptyText}
        fontColor={colors.DARK}
        valueType={valueType}
        containerProps={{
          margin: '0 auto'
        }}
      />
    </Card>
  )
}

export default WasteGraph
