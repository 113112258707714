import React from 'react'
import styled from 'styled-components'

import colors from '../../style/colors'
import font from '../../style/font'

interface Props {
  disabled?: boolean
  onClick?: () => void
  secondary?: boolean
  tertiary?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Btn = styled.button<Props>`
  background-color: ${colors.PRIMARY};
  border: none;
  color: ${colors.WHITE};
  cursor: pointer;
  font-family: ${font.family};
  font-size: 1em;
  margin: 0;
  padding: 0.625em 0;
  width: 100%;

  &:hover {
    background-color: ${colors.PRIMARY_DARK};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: ${colors.GREY_DARK};
    color: ${colors.WHITE};
  }

  ${({secondary}) => secondary && `
    background-color: ${colors.WHITE};
    color: ${colors.PRIMARY};
    text-transform: uppercase;
  `}

  ${({tertiary}) => tertiary && `
    background-color: transparent;
    border: solid 0.125rem ${colors.PRIMARY};
    color: ${colors.PRIMARY};

    &:hover {
      background-color: ${colors.PRIMARY};
      color: ${colors.WHITE};
    }
  `}
`

const Button: React.FC<Props> = props => {
  return <Btn {...props} />
}

export default Button
