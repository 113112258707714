import React, {useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleRight, faEdit} from '@fortawesome/pro-regular-svg-icons'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {AxiosError} from 'axios'

import {Horeca, Product, Device, ProductGroup} from '../../../types'
import Card from '../../../shared/components/Card'
import EmptyState from '../../../shared/components/EmptyState'
import useAxios from '../../../shared/useFetch'
import {TableExtender} from '../../../shared/components/Table'
import colors from '../../../shared/style/colors'
import IconButton from '../IconButton'
import CaveForm from './CaveForm'
import CascadeForm from './CascadeForm'
import List from './List'
import Loading from '../../../shared/components/Loading'
import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'

interface Props {
  horeca?: Horeca
  devices?: Device[]
  onUpdate: (device: Device) => void
  products?: Product[]
  groups?: ProductGroup[]
  isGetting: boolean
  getError?: AxiosError
}

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`

const StatusCircle = styled.div`
  margin-right: 6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({status}) => (status ? 'green' : 'red')};
`

const DeviceCard: React.FC<Props> = ({
  horeca,
  groups,
  devices,
  onUpdate,
  products,
  isGetting,
  getError
}) => {
  const {t} = useTranslation()
  const [initialValues, setInitialValues] = useState<any>()
  const currency = horeca ? horeca.currency : ''

  const [{error: updateError, isFetching: isUpdating}, updateCall] = useAxios(
    {
      url: '/horeca/:horecaId/device/:id',
      method: 'PATCH'
    },
    {
      success: ({data: {device}}) => {
        onUpdate(device)
        setInitialValues(undefined)
      }
    }
  )

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        id: 'expander',
        Cell: ({row}: any) => (
          <TableExtender
            icon={row.isExpanded ? faAngleDown : faAngleRight}
            {...row.getExpandedToggleProps()}
          />
        )
      },
      {
        Header: t('config.device.column.name'),
        Cell: ({row: {original}}: any) => {
          return (
            <NameContainer>
              <StatusCircle status={original.status} /> [{original.serial}] {original.name}
            </NameContainer>
          )
        }
      },
      {
        Header: t('config.device.column.model'),
        accessor: 'model'
      },
      {
        Header: t('config.device.column.actions'),
        Cell: ({row: {original}}: any) => {
          return (
            <>
              <IconButton
                data-tip={t('config.device.edit')}
                type="button"
                onClick={() => {
                  setInitialValues({
                    id: original.id,
                    name: original.name,
                    config: original.config,
                    model: original.model
                  })
                }}
              >
                <FontAwesomeIcon icon={faEdit} color={colors.PRIMARY} />
              </IconButton>
            </>
          )
        }
      }
    ]
  }, [setInitialValues])
  return (
    <Card title={t('config.device.title')}>
      {isGetting && <Loading text={t('config.loading.device')} />}
      {!isGetting && getError && (
        <Error component={ErrorComponent} error={getError} converters={[]} />
      )}

      {!isGetting && horeca && initialValues && initialValues.model === 'CELLAR' && (
        <CaveForm
          isFetching={isUpdating}
          error={updateError}
          cancel={() => setInitialValues(undefined)}
          initialValues={initialValues}
          onSubmit={values => {
            updateCall({
              data: values,
              url: `/horeca/${horeca.id}/device/${initialValues.id}`
            })
          }}
        />
      )}
      {!isGetting && horeca && initialValues && initialValues.model === 'CASCADE+' && (
        <CascadeForm
          groups={groups}
          isFetching={isUpdating}
          error={updateError}
          cancel={() => setInitialValues(undefined)}
          initialValues={initialValues}
          onSubmit={values => {
            Object.keys(values.config).forEach((k: string) => {
              if (values.config[k] !== null) {
                // @ts-ignore
                values.config[k] = parseInt(values.config[k], 10)
              }
            })
            updateCall({
              data: values,
              url: `/horeca/${horeca.id}/device/${initialValues.id}`
            })
          }}
        />
      )}
      {!isGetting && !initialValues && (
        <>
          {devices && devices.length > 0 && (
            <List
              horeca={horeca}
              data={devices}
              groups={groups}
              columns={columns}
              products={products}
              currency={currency}
            />
          )}
          {devices && devices.length === 0 && <EmptyState text={t('config.device.empty')} />}
        </>
      )}
    </Card>
  )
}

export default DeviceCard
