import styled from 'styled-components'

import media from '../../../style/media'

export default styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  
  ${media.lessThan('S')`
    flex-direction: column;
  `}
`
