import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'

import ROUTES from '../../../routes'
import {useAuthContext} from '../../auth/Provider'
import Container from './Container'
import {useHorecaContext} from '../../horeca/Provider'

interface Props extends RouteProps {
  component: React.ComponentType<any>
}

const PrivateRoute: React.FC<Props> = ({location, component: Component, ...rest}) => {
  const {user} = useAuthContext()
  const {horecas} = useHorecaContext()

  if (!user) return <Redirect to={ROUTES.LOGIN} push />

  if (
    location &&
    !location.pathname.includes(ROUTES.ONBOARDING) &&
    ((user && user.roles && user.roles.length === 0) || (horecas && horecas.length === 0))
  ) {
    return <Redirect to={ROUTES.ONBOARDING} push />
  }

  return (
    <Container>
      <Route {...rest} render={props => <Component {...props} user={user} />} />
    </Container>
  )
}

export default PrivateRoute
