import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import Select from '../../../shared/components/Select'
import Button from '../../../shared/components/Button'
import FormContainer from '../FormContainer'
import ButtonsContainer from '../ButtonsContainer'
import {Role} from '../../../types'

interface FormProps {
  id?: number
  name?: string
  identifier: number
  owner?: number
}

interface Props extends FormikConfig<FormProps> {
  cancel: () => void
  error?: AxiosError
  roles?: Role[]
  isFetching?: boolean
}

const Form: React.FC<Props> = ({cancel, error, roles, initialValues, isFetching, onSubmit, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        identifier: Yup.string().required(t('common.error.required')),
        owner: Yup.number(),
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && (
              <Error
                component={ErrorComponent}
                error={error}
                converters={[]}
              />
            )}
            <Field
              id='key-form-name'
              name='name'
              type='text'
              component={Input}
              label={t('config.key.form.name.label')}
              placeholder={t('config.key.form.name.placeholder')}
            />
            {roles && roles.length > 0 && (
              <Field
                id='key-form-owner'
                name='owner'
                options={roles.filter((role: Role) => {
                  return role.name !== 'Investor'
                }).map((role: Role) => {
                  return {
                    label: `${role.owner.firstname} ${role.owner.lastname} [${role.name}]`,
                    value: role.id
                  }
                })}
                component={Select}
                label={t('config.key.form.owner.label')}
                placeholder={t('config.key.form.owner.placeholder')}
              />
            )}
            <Field
              id='key-form-identifier'
              name='identifier'
              type='number'
              component={Input}
              label={t('config.key.form.identifier.label')}
              placeholder={t('config.key.form.identifier.placeholder')}
            />

            <ButtonsContainer>
              <Button tertiary disabled={isFetching} type='button' onClick={() => {
                props.handleReset()
                cancel()
              }}>
                {t('config.key.form.cancel')}
              </Button>

              <Button disabled={isFetching} type='submit'>
                {isFetching && <FontAwesomeIcon icon={faCog} spin />}
                {!isFetching && t(`config.key.form.submit.${initialValues.id ? 'update' : 'create'}`)}
              </Button>
            </ButtonsContainer>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default Form
