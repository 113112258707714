import React from 'react'
import {useTranslation} from 'react-i18next'

import ModalContainer from '../../../shared/components/Modal'
import Title from './Title'
import Instruction from './Instruction'
import Form from './Form'
import useAxios from '../../../shared/useFetch'
import {useHorecaContext} from '../../../shared/horeca/Provider'

const initialValues = {name: '', address: ''}

const Company: React.FC = () => {
  const {t} = useTranslation()
  const {refresh} = useHorecaContext()

  const [{error, isFetching}, call] = useAxios(
    {
      url: '/company',
      method: 'POST'
    },
    {
      success: () => {
        refresh()
      }
    }
  )

  return (
    <ModalContainer>
      <Title>{t('onboarding.company.title')}</Title>
      <Instruction>{t('onboarding.company.instruction')}</Instruction>
      <Form
        isFetching={isFetching}
        error={error}
        initialValues={initialValues}
        onSubmit={values => call({data: values})}
      />
    </ModalContainer>
  )
}

export default Company
