import React, {useEffect, useMemo} from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'
import {CSVLink} from 'react-csv'

import {useTimeContext} from '../../shared/time/Provider'
import {useHorecaContext} from '../../shared/horeca/Provider'
import Loading from '../../shared/components/Loading'
import EmptyState from '../../shared/components/EmptyState'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'
import useAxios from '../../shared/useFetch'
import {Horeca} from '../../types'
import {CATEGORIES, Category, TIME_FORMAT} from '../../constants'
import {teamNormalizer, teamNormalizerV2} from './normalizers'
import TeamList from './TeamList'
import Container from './Container'
import {useAuthContext} from '../../shared/auth/Provider'

let columns: string[] = []

const Team: React.FC<
  RouteComponentProps<{
    horecaId?: string
  }>
> = ({match}) => {
  const {t} = useTranslation()
  const {user, isAdmin} = useAuthContext()
  const {to, from} = useTimeContext()
  const {horecas} = useHorecaContext()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const horeca = horecaIdParam && horecas && horecas.find((h: Horeca) => h.id === horecaIdParam)
  const openingHour = horeca ? horeca.openingHour : null
  const currency = horeca ? horeca.currency : 'CHF'
  const isV1 = horeca && !!horeca.org_id
  const isWaiter =
    user &&
    user.roles &&
    user.roles.some(r => r.horeca && r.name === 'Waiter' && r.horeca.id === horecaIdParam)

  const [{data, error, isFetching}, call] = useAxios({
    url: `/horeca/:horecaId/team`,
    method: 'GET'
  })
  const team = data
    ? isV1
      ? teamNormalizer(data.keys, data.volumes, data.waste)
      : teamNormalizerV2(data.keys, data.volumes, data.waste, data.pos, data.storno)
    : null

  const sortedTeamData = team
    ? Object.values(team).sort((a: any, b: any) => {
        const aTotalRevenue = Object.values(a.sold).reduce((t: number, c: any) => t + c.revenue, 0)
        const bTotalRevenue = Object.values(b.sold).reduce((t: number, c: any) => t + c.revenue, 0)
        // @ts-ignore
        return bTotalRevenue - aTotalRevenue
      })
    : null

  // @ts-ignore
  const totalRevenue: number = sortedTeamData
    ? sortedTeamData.reduce((t, x) => {
        // @ts-ignore
        return t + Object.values(x.sold).reduce((t: number, c: any) => t + c.revenue, 0)
      }, 0)
    : 0

  const csvData = useMemo(
    () =>
      sortedTeamData && [
        columns,
        ...sortedTeamData.map((k: any) => {
          const data = [k.name]
          // @ts-ignore
          const revenue: number = Object.values(k.sold).reduce(
            (t: number, c: any) => t + c.revenue,
            0
          )
          data.push(revenue)
          CATEGORIES.forEach((category: Category) => {
            if (category.name in k.sold) {
              if (['Coffee', 'Bottles'].includes(category.name)) {
                data.push(k.sold[category.name].volume)
              } else {
                data.push(`${k.sold[category.name].volume.toFixed(2)}`)
              }
              data.push(k.sold[category.name].revenue)
            } else {
              data.push(0)
              data.push(0)
            }
          })
          return data
        })
      ],
    [sortedTeamData]
  )

  useEffect(() => {
    columns.push(t('common.key'))
    columns.push(t('common.revenue'))
    CATEGORIES.forEach((category: Category) => {
      columns.push(
        `${t(category.i18nKey)} ${t('common.volume')} (${
          ['Coffee', 'Bottles'].includes(category.name) ? t('common.unit') : 'L'
        })`
      )
      columns.push(`${t(category.i18nKey)} ${t('common.revenue')} (${currency})`)
    })
  }, [currency])

  useEffect(() => {
    if (openingHour !== null) {
      call({
        url: `/horeca/${horecaIdParam}/team`,
        params: {
          start: isV1
            ? from.add(openingHour, 'hour').format(TIME_FORMAT)
            : from.add(openingHour, 'hour').toISOString(),
          end: isV1
            ? to.add(openingHour, 'hour').format(TIME_FORMAT)
            : to.add(openingHour, 'hour').toISOString()
        }
      })
    }
  }, [horecaIdParam, openingHour, to, from])

  if (isFetching) {
    return <Loading text={t('team.loading')} />
  }

  if (data && data.keys.length === 0) {
    return <EmptyState text={t('team.empty.key')} />
  }

  if (data && data.volumes.length === 0 && data.waste.length === 0) {
    return <EmptyState text={t('team.empty.events')} />
  }

  return (
    <>
      {error && (
        <Error
          component={ErrorComponent}
          error={error}
          converters={[
            {
              key: 'common.notfound.horeca',
              check: (err: AxiosError) => (err.response ? err.response.status === 404 : false)
            },
            {
              key: 'common.error.notActivated',
              check: (err: AxiosError) => {
                return err.response
                  ? err.response.status === 400 && err.response.data.name === 'NotActive'
                  : false
              }
            }
          ]}
        />
      )}
      {sortedTeamData && (
        <Container>
          <TeamList
            isWaiter={isWaiter}
            totalRevenue={totalRevenue}
            data={sortedTeamData}
            currency={currency}
            download={
              (isAdmin || (horeca && ['PRO', 'PRO+'].includes(horeca.activationType))) &&
              csvData ? (
                <CSVLink
                  filename={`${t('common.key')}-${to.format('DD-MM-YYYY')}-${from.format(
                    'DD-MM-YYYY'
                  )}.csv`}
                  data={csvData}
                >
                  {t('common.download')}
                </CSVLink>
              ) : (
                undefined
              )
            }
          />
        </Container>
      )}
    </>
  )
}

export default withRouter(Team)
