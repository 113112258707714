import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import FormContainer from '../../shared/components/Form'
import Button from '../../shared/components/Button'
import Input from '../../shared/components/Input'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'

interface Form {
  password: string
}

interface Props extends FormikConfig<Form> {
  isFetching?: boolean
  error?: AxiosError
}

const ChangeForm: React.FC<Props> = ({onSubmit, initialValues, isFetching, error}) => {
  const {t} = useTranslation()

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, t('common.error.password.length'))
          .required(t('common.error.required'))
      })}
      render={({handleSubmit}) => (
        <FormContainer onSubmit={handleSubmit}>
          {error && (
            <Error
              component={ErrorComponent}
              secondary
              error={error}
              converters={[
                {
                  key: 'change.error.token.invalid',
                  check: err =>
                    !!err.response &&
                    ['JsonWebTokenError', 'TokenInvalid', 'TokenMissing'].includes(
                      err.response.data.name
                    )
                },
                {
                  key: 'change.error.token.expired',
                  check: err => !!err.response && err.response.data.name === 'TokenExpired'
                },
                {
                  key: 'change.error.password.invalid',
                  check: err =>
                    !!err.response && ['UsageError', 'TypeError'].includes(err.response.data.name)
                }
              ]}
            />
          )}
          <Field
            id="change-password"
            name="password"
            type="password"
            component={Input}
            label={t('common.password.label')}
            placeholder={t('common.password.placeholder')}
            secondary
          />
          <Button disabled={isFetching} type="submit" secondary>
            {isFetching && <FontAwesomeIcon icon={faCog} spin />}
            {!isFetching && t('change.text')}
          </Button>
        </FormContainer>
      )}
    />
  )
}

export default ChangeForm
