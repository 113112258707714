import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faQuestionCircle} from '@fortawesome/pro-solid-svg-icons'

import colors from '../../shared/style/colors'
import Container from './Container'
import Text from './Text'

interface Props {
  text: string
}

const NotFound: React.FC<Props> = ({text, ...rest}) => {
  return (
    <Container {...rest}>
      <FontAwesomeIcon icon={faQuestionCircle} size='4x' color={colors.PRIMARY} />
      <Text>{text}</Text>
    </Container>
  )
}

export default NotFound
