import styled from 'styled-components'

import media from '../../style/media'

export default styled.div`
  padding: 0.625rem 0 0 0.625rem;
  width: 3.5rem;
  
  ${media.greaterThan('M')`
    display: none;
  `}
`
