import React from 'react'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {withRouter} from 'react-router'
import DatePicker, {registerLocale} from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('en-GB', enGB)

import {useHorecaContext} from '../../../horeca/Provider'
import {useTimeContext} from '../../../time/Provider'
import {useAuthContext} from '../../../auth/Provider'
import Container from './Container'
import DatePickerContainer from './DatePickerContainer'
import ItemsContainer from './ItemsContainer'
import Item from './Item'
import Label from './Label'

const TimeSelector = ({match}) => {
  const {t} = useTranslation()
  const {
    option,
    from,
    to,
    selectToday,
    selectYesterday,
    selectWeek,
    selectFilter,
    selectFilterFrom,
    selectFilterTo
  } = useTimeContext()
  const {horecas} = useHorecaContext()
  const {isAdmin} = useAuthContext()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const selectedHoreca = horecas && horecas.find(h => h.id === horecaIdParam)

  const op =
    !isAdmin &&
    selectedHoreca &&
    !['PRO', 'PRO+'].includes(selectedHoreca.activationType) &&
    option === 3
      ? 0
      : option

  return (
    <Container>
      <ItemsContainer>
        <Item selected={op === 0} onClick={selectYesterday}>
          {t('time.yesterday')}
        </Item>
        <Item selected={op === 1} onClick={selectToday}>
          {t('time.today')}
        </Item>
        <Item selected={op === 2} onClick={selectWeek}>
          {t('time.week')}
        </Item>
        {(isAdmin ||
          (selectedHoreca && ['PRO', 'PRO+'].includes(selectedHoreca.activationType))) && (
          <Item selected={op === 3} onClick={selectFilter}>
            {t('time.filter')}
          </Item>
        )}
      </ItemsContainer>
      {op === 3 && (
        <DatePickerContainer>
          <Label>
            {t('time.start')}
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="en-GB"
              selected={from.toDate()}
              onChange={selectFilterFrom}
              maxDate={new Date()}
            />
          </Label>
          <Label>
            {t('time.end')}
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="en-GB"
              selected={to.toDate()}
              onChange={selectFilterTo}
              maxDate={
                from
                  .add(7, 'day')
                  .endOf('day')
                  .isSameOrAfter(dayjs())
                  ? new Date()
                  : from
                      .add(7, 'day')
                      .endOf('day')
                      .toDate()
              }
              minDate={from.toDate()}
            />
          </Label>
        </DatePickerContainer>
      )}
    </Container>
  )
}

export default withRouter(TimeSelector)
