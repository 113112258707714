import React from 'react'
import styled from 'styled-components'

import {useTimeContext} from '../../shared/time/Provider'
import media from '../../shared/style/media'

interface Props {
  hideTimeSelector: boolean
}

interface ContainerProps extends Props{
  filterOpen: boolean
}

const Container = styled.div<ContainerProps>`
  padding-top: 7rem;
  
  ${({filterOpen}) => filterOpen && `
    padding-top: 10.6875rem;
  `}
  
  ${({hideTimeSelector}) => hideTimeSelector && `
    padding-top: 5rem;
  `}
  
  ${({filterOpen}) => filterOpen && media.lessThan('S')`
    padding-top: 11.8125rem;
  `}
`

const ContentContainer: React.FC<Props> = (props) => {
  const {option} = useTimeContext()

  return (
    <Container {...props} filterOpen={option === 3} />
  )
}

export default ContentContainer
