import React from 'react'
import styled from 'styled-components'
import {CSVLink} from 'react-csv'

import font from '../../shared/style/font'

const List = styled.ul`
  margin: 0;
  padding: 0.5rem 0 0.5rem 4rem;
`

const Item = styled.li`
  font-family: ${font.family};
  font-size: 0.925rem;
  padding: 0.5rem 0;
`

const Volume = styled.span`
  font-weight: bold;
  padding-left: 0.5rem;
`

const Category = styled.div``

interface Row {
  original: any
}

interface Props {
  row: Row
  currency: string
  translate: (key: string) => string
}

const KeySubComponent: React.FC<Props> = ({row: {original}, currency, translate, to, from}) => {
  const csvData = [
    ['Category', 'Name', 'PLU', `Revenue (in ${currency})`, 'Volume (in L)', 'Quantity (in Units)']
  ]

  Object.keys(original.sold).map(category => {
    return Object.values(original.sold[category].products).map(x => {
      csvData.push([
        category,
        x.details.name,
        x.details.plu,
        x.revenue.toFixed(2),
        ['Coffee', 'Bottles'].includes(category) ? ' - ' : (x.volume / 1000).toFixed(2),
        ['Coffee', 'Bottles'].includes(category) ? x.volume : x.count
      ])
      return
    })
  })

  return (
    <List>
      {Object.keys(original.sold).map((category: string) => {
        const volume = ['Coffee', 'Bottles'].includes(category)
          ? `${original.sold[category].volume} ${translate('common.unit')}`
          : `${(original.sold[category].volume / 1000).toFixed(2)} L`
        return (
          <Item key={category}>
            <Category>
              {category}:{' '}
              <Volume>
                {original.sold[category].revenue.toFixed(2)} {currency} ({volume})
              </Volume>
            </Category>
            <hr />
            <ul>
              {Object.values(original.sold[category].products).map((x: any) => {
                const vol = ['Coffee', 'Bottles'].includes(category)
                  ? `${x.volume} ${translate('common.unit')}`
                  : `${(x.volume / 1000).toFixed(2)} L | Qty ${x.count}`

                const posQty =
                  original.pos[category] && original.pos[category].products[x.details.id]
                    ? original.pos[category].products[x.details.id].quantity
                    : 0
                const stornoQty =
                  original.storno[category] && original.storno[category].products[x.details.id]
                    ? original.storno[category].products[x.details.id].quantity
                    : 0

                const diff =
                  posQty -
                  stornoQty -
                  (['Coffee', 'Bottles'].includes(category) ? x.volume : x.count)

                return (
                  <li style={{fontSize: '0.75rem', padding: '0.125rem 0'}} key={x.details.id}>
                    {x.details.name} [{x.details.plu}]: {x.revenue.toFixed(2)} {currency} ({vol})
                    {Object.keys(original.pos).length ? `(Qty diff with POS: ${diff})` : null}
                  </li>
                )
              })}
            </ul>
          </Item>
        )
      })}
      <CSVLink
        filename={`${original.name}-${to.format('DD-MM-YYYY')}-${from.format('DD-MM-YYYY')}.csv`}
        data={csvData}
      >
        {translate('common.download')}
      </CSVLink>
    </List>
  )
}

export default KeySubComponent
