import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import media from '../../../style/media'

export default styled(FontAwesomeIcon)<{onClick: () => void}>`
  cursor: pointer;
  display: none;
  margin: 1.5rem 1rem 0 0;
  
  ${media.lessThan('M')`
    display: inline-block;
  `}
`
