import React from 'react'
import {useTranslation} from 'react-i18next'

import ModalContainer from '../../../shared/components/Modal'
import useAxios from '../../../shared/useFetch'
import {useAuthContext} from '../../../shared/auth/Provider'
import {useHorecaContext} from '../../../shared/horeca/Provider'
import Title from './Title'
import Instruction from './Instruction'
import Form from './Form'

const initialValues = {name: '', address: '', currency: '', openingHour: 5}

const Horeca: React.FC = () => {
  const {t} = useTranslation()
  const {user} = useAuthContext()
  const {refresh} = useHorecaContext()

  const company = user && user.roles[0] && user.roles[0].company
    ? user.roles[0].company.id : null

  const [{error, isFetching}, call] = useAxios(
    {
      url: '/horeca',
      method: 'POST'
    },
    {
      success: () => {
        refresh()
      }
    }
  )

  return (
    <ModalContainer>
      <Title>{t('onboarding.horeca.title')}</Title>
      <Instruction>{t('onboarding.horeca.instruction')}</Instruction>
      <Form
        isFetching={isFetching}
        error={error}
        initialValues={initialValues}
        onSubmit={values => call({data: {...values, company}})}
      />
    </ModalContainer>
  )
}

export default Horeca
