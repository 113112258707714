import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {CSVLink} from 'react-csv'

import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import {GRAPH_COLORS} from '../../../constants'
import colors from '../../../shared/style/colors'
import BarGraph from '../BarGraph'
import ActivityOverTimeGraph from '../ActivityOverTimeGraph'
import PieGraph from '../PieGraph'
import {useTimeContext} from '../../../shared/time/Provider'
import {useAuthContext} from '../../../shared/auth/Provider'

interface Props extends CategorySelectorProps {
  activityDataPoints: any[]
  currency: string
  isMobile: boolean
  intervalChange: (interval: number) => void
  selectedInterval: string
  time: number
  timeFormat: string
  quantityGraphDataPoints: any[]
  wasteDataPoints: any[]
}

const CoffeeGraph: React.FC<Props> = ({
  time,
  timeFormat,
  activityDataPoints,
  quantityGraphDataPoints,
  currency,
  isMobile,
  selectedInterval,
  intervalChange,
  wasteDataPoints,
  horeca,
  ...rest
}) => {
  const {t} = useTranslation()
  const {isAdmin} = useAuthContext()
  const {to, from} = useTimeContext()
  const csvData = useMemo(
    () => [
      [t('common.product'), `${t('common.revenue')} (${currency})`, t('common.quantity')],
      ...quantityGraphDataPoints.map(dp => [dp.label, dp.revenue, dp.y])
    ],
    [quantityGraphDataPoints, currency]
  )

  return (
    <Card title={t('volume.coffee.title')} {...rest}>
      {rest.selectedCategory === 'Quantities' && (
        <>
          <BarGraph
            currency={currency}
            valueType={t('volume.coffee.unit')}
            emptyText={t('volume.coffee.empty')}
            isMobile={isMobile}
            dataPoints={quantityGraphDataPoints}
          />
          {(isAdmin || (horeca && ['PRO', 'PRO+'].includes(horeca.activationType))) && (
            <CSVLink
              filename={`${t('volume.coffee.title')}-${to.format('DD-MM-YYYY')}-${from.format(
                'DD-MM-YYYY'
              )}.csv`}
              data={csvData}
            >
              {t('common.download')}
            </CSVLink>
          )}
        </>
      )}
      {rest.selectedCategory === 'Activity' && (
        <ActivityOverTimeGraph
          currency={currency}
          valueType={t('volume.coffee.unit')}
          timeFormat={timeFormat}
          time={time}
          dataPoints={[
            {
              type: 'column',
              color: GRAPH_COLORS.Coffee,
              xValueFormatString: timeFormat,
              showInLegend: true,
              legendText: t('volume.coffee.title'),
              dataPoints: activityDataPoints
            }
          ]}
          emptyText={t('volume.coffee.empty')}
          selectedInterval={selectedInterval}
          intervalChange={intervalChange}
          name="Coffee"
        />
      )}
      {rest.selectedCategory === 'Waste' && (
        <PieGraph
          dataPoints={wasteDataPoints}
          fontColor={colors.DARK}
          emptyText={t('volume.coffee.empty')}
        />
      )}
    </Card>
  )
}

export default CoffeeGraph
