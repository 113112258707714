import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import ReactGA from 'react-ga'

import {useAuthContext} from '../../shared/auth/Provider'
import useAxios from '../../shared/useFetch'
import AuthLink from '../../shared/components/AuthLink'
import ROUTES from '../../routes'
import Form from './Form'

interface Props {
  location: any
}

const initialValues = {firstname: '', lastname: '', email: '', password: ''}

const Login: React.FC<Props> = ({location}) => {
  const {t} = useTranslation()
  const {authenticate} = useAuthContext()
  const [{error, isFetching}, call] = useAxios(
    {
      url: '/user',
      method: 'POST'
    },
    {
      success: ({data: {accessToken}}) => {
        authenticate(accessToken)
      },
      fail: err => {
        if (err.response) {
          ReactGA.event({
            category: 'Signup',
            action: `${err.response.status} - ${err.response.data.name}`,
            label: 'Signup failed',
            nonInteraction: true
          })
        }
      }
    }
  )

  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  const onSubmit = useCallback(
    values => {
      call({data: {...values, token}})
    },
    [call, token]
  )

  return (
    <>
      <Form
        error={error}
        isFetching={isFetching}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />

      <AuthLink
        text={t('signup.login.text')}
        linkText={t('signup.login.link')}
        link={ROUTES.LOGIN}
      />
    </>
  )
}

export default withRouter(Login)
