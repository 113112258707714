import React from 'react'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'

import {getErrorMessageKey} from '../../error'

interface ErrorConfig {
  key: string
  check: (err: AxiosError) => boolean
}

interface Props {
  converters?: ErrorConfig[]
  component: React.ComponentType<any>
  error: AxiosError
  secondary?: boolean
}

const Error: React.FC<Props> = ({component: Component, error, converters, ...rest}) => {
  const {t} = useTranslation()
  const messageKey = getErrorMessageKey(error, converters)

  return <Component {...rest}>{t(messageKey)}</Component>
}

export default Error
