import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../style/colors'
import font from '../../style/font'

export default styled.p`
  color: ${transparentize(0.5, colors.WHITE)};
  font-family: ${font.family};
  font-size: 0.875rem;
  margin: 0.25rem 0;
  text-align: center;

  & a {
    color: ${colors.WHITE};
    cursor: pointer;
    margin-left: 0.325rem;
    text-decoration: none;

    &:hover {
      color: ${transparentize(0.25, colors.WHITE)};
    }
  }
`
