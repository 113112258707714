import styled from 'styled-components'

import colors from '../../style/colors'

export default styled.span<{secondary?: boolean}>`
  color: ${colors.PRIMARY};
  float: right;
  
  ${({secondary}) => secondary && `
    color: ${colors.WHITE};
  `}
`
