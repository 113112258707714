import React, {useCallback} from 'react'
import Select from 'react-select'

import colors from '../../style/colors'
import styles from './styles'

export interface Option {
  label: string
  value: string
}

interface Props {
  categories?: string[]
  categoryOptions?: Option[]
  onChange: (category: string) => void
  selected?: string
}

const CategorySelector = ({categories, categoryOptions, onChange, selected, ...rest}: Props) => {
  const options = categories
    ? categories.map((category: string) => {
        return {
          label: category,
          value: category
        }
      })
    : categoryOptions
    ? categoryOptions
    : []

  const change = useCallback(
    (option: Option) => {
      if (option) {
        onChange(option.value)
      }
    },
    [onChange]
  )

  return (
    <Select
      styles={styles}
      value={
        selected
          ? options.find((o: any) => o.value === selected)
          : {
              label: '',
              value: ''
            }
      }
      onChange={change}
      options={options}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: colors.PRIMARY_DARK,
          primary: colors.PRIMARY_DARK,
          neutral20: '#fff',
          neutral30: '#fff',
          neutral40: '#fff',
          neutral50: '#fff',
          neutral60: '#fff',
          neutral70: '#fff',
          neutral80: '#fff'
        }
      })}
      {...rest}
    />
  )
}

export default CategorySelector
