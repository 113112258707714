import React from 'react'

import {INTERVALS} from '../../../../shared/time'
import Container from './Container'

interface Props {
  time: number
  name: string
  onChange: (interval: number) => void
  selectedInterval: string
}

const IntervalSelector: React.FC<Props> = ({selectedInterval, time, name, onChange}) => {
  return (
    <Container>
      {INTERVALS.map((i: string, n: number) => {
        let selected
        if (time < 2 && n > 0) {
          selected = selectedInterval === INTERVALS[0] ? INTERVALS[1] : selectedInterval
        } else if (time === 2 && n < 2) {
          selected = selectedInterval === INTERVALS[2] || selectedInterval === INTERVALS[2]
            ? INTERVALS[0] : selectedInterval
        } else if (time === 3) {
          selected = selectedInterval
        } else {
          return null
        }

        return (
          <span key={i}>
            <input
              checked={selected === i}
              name={name}
              type='radio'
              onChange={() => onChange(n)}
            />
            {i}
          </span>
        )
      })}
    </Container>
  )
}

export default IntervalSelector
