import styled from 'styled-components'

import font from '../../../style/font'
import colors from '../../../style/colors'

export default styled.div<{selected: boolean}>`
  color: ${colors.DARK}; 
  cursor: pointer;
  font-family: ${font.family};
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
    
  ${({selected}) => selected && `
    border-bottom: 0.0625rem solid ${colors.PRIMARY};
    color: ${colors.PRIMARY};
  `}
`
