import React from 'react'

// @ts-ignore
import CanvasJSReact from '../../../shared/canvasjs/canvasjs.react'
import EmptyState from './EmptyState'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

interface Props {
  currency: string
  dataPoints: any[]
  emptyText: string
  valueType: string
  isMobile: boolean
}

const BarGraph: React.FC<Props> = ({isMobile, emptyText, dataPoints, valueType, currency}) => {
  if (dataPoints && dataPoints.length === 0) {
    return (
      <EmptyState text={emptyText} />
    )
  }

  return (
    <CanvasJSChart
      options={{
        animationEnabled: true,
        theme: 'light2',
        toolTip: {
          contentFormatter: (e: any) => {
            let content = ''
            for (let i = 0; i < e.entries.length; i++) {
              const volume = `${e.entries[i].dataPoint.y} ${valueType}`
              const revenue = `${e.entries[i].dataPoint.revenue} ${currency}`
              content = `${e.entries[i].dataPoint.label}<br />${revenue}<br />${volume}`
            }
            return content
          }
        },
        axisX: {
          labelAngle: -30
        },
        axisY: {
          minimum: 0,
          labelFormatter: (e: any) => `${e.value} ${valueType}`
        },
        data: [
          {
            type: isMobile ? 'bar' : 'column',
            dataPoints
          }
        ]
      }}
    />
  )
}

export default BarGraph
