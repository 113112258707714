import React from 'react'

//TODO needs content
const Privacy = () => {
  return (
    <div>
      Privacy policy
    </div>
  )
}

export default Privacy
