import React, {useCallback, useState} from 'react'
import {faTimes, faBars} from '@fortawesome/pro-regular-svg-icons'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router'

import colors from '../../../style/colors'
import {useHorecaContext} from '../../../horeca/Provider'
import {useAuthContext} from '../../../auth/Provider'
import MobileIcon from './MobileIcon'
import CloseIcon from './CloseIcon'
import Container from './Container'
import Item from './Item'
import ItemButton from './ItemButton'

const Menu: React.FC<
  RouteComponentProps<{
    horecaId?: string
    page?: string
  }>
> = ({match, history}) => {
  const {t} = useTranslation()
  const {logout, isAdmin} = useAuthContext()
  const {horecas} = useHorecaContext()
  const [isOpen, setIsOpen] = useState(false)
  const horecaIdParam = match.params.horecaId
  const pageParam = match.params.page
  const horeca =
    horecaIdParam &&
    horecas &&
    horecas.find(h => h.id === (horecaIdParam ? parseInt(match.params.horecaId) : undefined))

  const logoutCallback = useCallback(() => {
    logout(history)
  }, [history, logout])

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])

  return (
    <>
      <MobileIcon onClick={toggleMenu} icon={faBars} color={colors.PRIMARY} size="2x" />
      {isOpen && (
        <Container>
          <CloseIcon onClick={toggleMenu} icon={faTimes} size="2x" />
          {!!horecaIdParam && pageParam !== 'volume' && (
            <Item href={`/h/${horecaIdParam}/volume`}>{t('pages.volume')}</Item>
          )}
          {!!horecaIdParam && pageParam !== 'team' && (
            <Item href={`/h/${horecaIdParam}/team`}>{t('pages.team')}</Item>
          )}
          {(isAdmin || (horeca && horeca.activationType === 'PRO+')) &&
            !!horecaIdParam &&
            pageParam !== 'technic' && (
              <Item href={`/h/${horecaIdParam}/technic`}>{t('pages.technic')}</Item>
            )}
          {!!horecaIdParam && pageParam !== 'config' && (
            <Item href={`/h/${horecaIdParam}/config`}>{t('pages.config')}</Item>
          )}
          <ItemButton onClick={logoutCallback}>{t('common.logout')}</ItemButton>
        </Container>
      )}
    </>
  )
}

export default withRouter(Menu)
