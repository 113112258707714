import React from 'react'
import {RouteComponentProps} from 'react-router'
import {useTranslation} from 'react-i18next'

import {Provider as TimeProvider} from '../../shared/time/Provider'
import Nav from '../../shared/components/Nav'
import Header from '../../shared/components/Header'
import {useHorecaContext} from '../../shared/horeca/Provider'
import {useAuthContext} from '../../shared/auth/Provider'
import {User} from '../../types'
import Volume from '../Volume'
import Technic from '../Technic'
import Config from '../Config'
import Team from '../Team'
import NotFound from '../NotFound'
import ContentContainer from './ContentContainer'

interface Props
  extends RouteComponentProps<{
    page?: string
    horecaId?: string
  }> {
  user: User
}

const Horeca: React.FC<Props> = ({user, match}) => {
  const {t} = useTranslation()
  const {isAdmin} = useAuthContext()
  const {horecas} = useHorecaContext()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const horeca = horecaIdParam && horecas && horecas.find(h => h.id === horecaIdParam)

  let hideTimeSelector = false
  let page

  switch (match.params.page) {
    case 'volume':
      page = <Volume />
      break
    case 'technic':
      if (isAdmin || (horeca && horeca.activationType === 'PRO+')) {
        page = <Technic />
      } else {
        page = <NotFound text={t('common.notfound.page')} />
      }
      break
    case 'team':
      page = <Team />
      break
    case 'config':
      hideTimeSelector = true
      page = <Config />
      break
    default:
      page = <NotFound text={t('common.notfound.page')} />
      break
  }

  return (
    <TimeProvider>
      <Nav user={user} />
      <Header hideTimeSelector={hideTimeSelector} />
      <ContentContainer hideTimeSelector={hideTimeSelector}>{page}</ContentContainer>
    </TimeProvider>
  )
}

export default Horeca
