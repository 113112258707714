import {CSSProperties} from 'react'

import colors from '../../../style/colors'
import font from '../../../style/font'

export default {
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
    border: 'none',
    color: state.isSelected ? colors.PRIMARY : colors.DARK,
    fontFamily: font.family
  }),

  container: (provided: CSSProperties) => ({
    ...provided,
    border: 'none',
    color: colors.WHITE,
    display: 'flex',
    height: '2.375rem',
    justifyContent: 'center',
    maxWidth: '18.75rem',
    outline: 'none',
    width: '100%'
  }),

  control: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    boxShadow: 'none',
    color: colors.WHITE,
    fontFamily: font.family,
    justifySelf: 'center',
    maxWidth: '18.75rem',
    outline: 'none',
    width: 'fill-available'
  }),

  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '2em',
    width: '2.375rem'
  }),

  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    display: 'none'
  }),

  menu: (provided: CSSProperties) => ({
    ...provided,
    margin: '0',
    maxWidth: '18.75rem',
    width: '-webkit-fill-available'
  }),

  menuList: (provided: CSSProperties) => ({
    ...provided,
    padding: '0'
  }),

  singleValue: (provided: CSSProperties, state: any) => ({
    ...provided,
    fontSize: '1.2rem',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms'
  })
}
