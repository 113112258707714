import React, {useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/pro-regular-svg-icons'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'

import {Horeca, Product, ProductGroup} from '../../../types'
import Card from '../../../shared/components/Card'
import EmptyState from '../../../shared/components/EmptyState'
import useAxios from '../../../shared/useFetch'
import colors from '../../../shared/style/colors'
import Search from '../../../shared/components/Search'
import Switch from '../Switch'
import IconButton from '../IconButton'
import Form from './Form'
import List from './List'
import Loading from '../../../shared/components/Loading'
import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'

interface Props {
  groups?: ProductGroup[]
  horeca?: Horeca
  onCreate: (product: Product) => void
  onDelete: (product: Product) => void
  onUpdate: (product: Product) => void
  products?: Product[]
  isGetting: boolean
  getError?: AxiosError
}

const ProductCard: React.FC<Props> = ({
  groups,
  horeca,
  onCreate,
  onDelete,
  onUpdate,
  products,
  isGetting,
  getError
}) => {
  const {t} = useTranslation()
  const emptyInitialValues = {name: '', category: 'Beer'}
  const [initialValues, setInitialValues] = useState()
  const [searchKeyword, setSearchKeyword] = useState()
  const currency = horeca ? horeca.currency : ''

  const data =
    searchKeyword && products
      ? products.filter((product: Product) => {
          const keywordLowerCase = searchKeyword.toLowerCase()
          return (
            product.category.toLowerCase() === keywordLowerCase ||
            product.name.toLowerCase().includes(keywordLowerCase)
          )
        })
      : products

  const [{error: updateError, isFetching: isUpdating}, updateCall] = useAxios(
    {
      url: '/horeca/:horecaId/product/:productId',
      method: 'PATCH'
    },
    {
      success: ({data: {product}}) => {
        onUpdate(product)
        setInitialValues(undefined)
      }
    }
  )

  const [{error: createError, isFetching: isCreating}, createCall] = useAxios(
    {
      url: '/horeca/:horecaId/product',
      method: 'POST'
    },
    {
      success: ({data: {product}}) => {
        onCreate(product)
        setInitialValues(undefined)
      }
    }
  )

  const [{isFetching: isDeleting}, deleteCall] = useAxios(
    {
      url: '/horeca/:horecaId/product/:productId',
      method: 'DELETE'
    },
    {
      success: ({data: {product}}) => {
        onDelete(product)
      },
      fail: () => {
        alert(t('config.product.delete.fail'))
      }
    }
  )

  const columns = useMemo(() => {
    return [
      {
        Header: t('config.product.column.plu'),
        accessor: 'plu'
      },
      {
        Header: t('config.product.column.name'),
        accessor: 'name'
      },
      {
        Header: t('config.product.column.category'),
        accessor: 'category'
      },
      {
        Header: t('config.product.column.group'),
        accessor: 'group.name'
      },
      {
        Header: t('config.product.column.portion'),
        Cell: ({row: {original}}: any) =>
          original.portion > 999
            ? original.portion % 1000 === 0
              ? `${(original.portion / 1000).toFixed(0)} L`
              : `${(original.portion / 1000).toFixed(2)} L`
            : `${original.portion} ml`
      },
      {
        Header: t('config.product.column.price'),
        Cell: ({row: {original}}: any) => `${original.price} ${currency}`
      },
      {
        Header: t('config.product.column.actions'),
        Cell: ({row: {original}}: any) => {
          return (
            <>
              <IconButton
                data-tip={t('config.product.edit')}
                type="button"
                onClick={() => {
                  setInitialValues({
                    category: original.category,
                    id: original.id,
                    name: original.name,
                    portion: original.portion,
                    price: original.price,
                    plu: original.plu,
                    group: original.group ? original.group.id : undefined
                  })
                }}
              >
                <FontAwesomeIcon icon={faEdit} color={colors.PRIMARY} />
              </IconButton>
              <IconButton
                data-tip={t('config.product.delete.tooltip')}
                disabled={isDeleting}
                type="button"
                onClick={() => {
                  if (horeca && confirm(t('config.product.delete.confirm'))) {
                    deleteCall({
                      url: `/horeca/${horeca.id}/product/${original.id}`
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} color={colors.PRIMARY} />
              </IconButton>
            </>
          )
        }
      }
    ]
  }, [isDeleting, deleteCall, currency, setInitialValues, horeca])

  return (
    <Card title={t('config.product.title')}>
      {isGetting && <Loading text={t('config.loading.product')} />}
      {!isGetting && getError && (
        <Error component={ErrorComponent} error={getError} converters={[]} />
      )}
      {!isGetting && horeca && initialValues && (
        <Form
          groups={groups}
          isFetching={initialValues.id ? isUpdating : isCreating}
          error={initialValues.id ? updateError : createError}
          cancel={() => setInitialValues(undefined)}
          initialValues={initialValues}
          onSubmit={values => {
            initialValues.id
              ? updateCall({
                  data: values,
                  url: `/horeca/${horeca.id}/product/${initialValues.id}`
                })
              : createCall({
                  data: values,
                  url: `/horeca/${horeca.id}/product`
                })
          }}
        />
      )}
      {!isGetting && !initialValues && (
        <>
          <Switch
            onClick={() => {
              setInitialValues(emptyInitialValues)
            }}
            text={t('config.product.add')}
          />
          {products && products.length > 0 && (
            <Search onSearch={setSearchKeyword} placeholder={t('config.product.search')} />
          )}
          {data && data.length > 0 && <List data={data} columns={columns} />}
          {data && data.length === 0 && <EmptyState text={t('config.product.empty')} />}
        </>
      )}
    </Card>
  )
}

export default ProductCard
