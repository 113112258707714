import styled from 'styled-components'

import media from '../../../shared/style/media'

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.625rem 0;
  width: 3.5rem;
  
  ${media.lessThan('M')`
    display: none;
  `}
`
