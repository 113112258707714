import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEmptySet} from '@fortawesome/pro-solid-svg-icons'

import colors from '../../../shared/style/colors'
import font from '../../../shared/style/font'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;
`

const Text = styled.div`
  color: ${colors.DARK};
  font-family: ${font.family};
  font-size: 1rem;
  margin-top: 1.5rem;
  text-align: center;
`

interface Props {
  text: string
}

const EmptyState: React.FC<Props> = ({text, ...rest}) => {
  return (
    <Container {...rest}>
      <FontAwesomeIcon icon={faEmptySet} size='4x' color={colors.PRIMARY} />
      <Text>{text}</Text>
    </Container>
  )
}

export default EmptyState
