import styled from 'styled-components'

import font from '../../style/font'

export default styled.p`
  font-family: ${font.family};
  font-size: 1rem;
  margin: 1rem 0 0;
  text-align: center;
`
