/* eslint-disable react/jsx-key */
import React from 'react'
// @ts-ignore
import {useTableState, useTable, useExpanded, usePagination} from 'react-table'
import {transparentize} from 'polished'
import {useTranslation} from 'react-i18next'

import {
  TableContainer,
  TableHeader,
  TableCell,
  TableRow,
  TableBody,
  TableHeading
} from '../../../shared/components/Table'
import Pagination from '../../../shared/components/Table/Pagination'
import colors from '../../../shared/style/colors'
import {Horeca, Product, ProductGroup} from '../../../types'
import renderDeviceSubComponent from './DeviceSubComponent'

interface Props {
  columns: any[]
  currency: string
  data: any[]
  products?: Product[]
  groups?: ProductGroup[]
  horeca?: Horeca
}

const List: React.FC<Props> = ({columns, groups, data, ...rest}) => {
  const {t} = useTranslation()
  const tableState = useTableState({pageIndex: 0, pageSize: 5})
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: [{pageIndex}]
  } = useTable(
    {
      columns,
      data,
      state: tableState
    },
    useExpanded,
    usePagination
  )

  return (
    <>
      <TableContainer {...rest} {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((hg: any) => (
            <tr {...hg.getHeaderGroupProps()}>
              {hg.headers.map((col: any) => (
                <TableHeading {...col.getHeaderProps()}>{col.render('Header')}</TableHeading>
              ))}
            </tr>
          ))}
        </TableHeader>
        <TableBody>
          {page.map(
            (row: any, i: number) =>
              prepareRow(row) || (
                <>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell: any) => (
                      <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded ? (
                    <>
                      <tr />
                      <TableRow
                        color={i % 2 === 0 ? transparentize(0.9, colors.PRIMARY) : colors.WHITE}
                      >
                        <TableCell colSpan={columns.length}>
                          {renderDeviceSubComponent({
                            row,
                            t,
                            groups
                          })}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : null}
                </>
              )
          )}
        </TableBody>
      </TableContainer>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        currentPage={pageIndex + 1}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        totalPages={pageCount}
      />
    </>
  )
}

export default List
