import React, {useCallback, useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone, faUserTimes, faEnvelope} from '@fortawesome/pro-regular-svg-icons'
import {useTranslation} from 'react-i18next'
import ReactTooltip from 'react-tooltip'

import {Role} from '../../../types'
import Search from '../../../shared/components/Search'
import Card from '../../../shared/components/Card'
import EmptyState from '../../../shared/components/EmptyState'
import useAxios from '../../../shared/useFetch'
import colors from '../../../shared/style/colors'
import Loading from '../../../shared/components/Loading'
import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Switch from '../Switch'
import IconButton from '../IconButton'
import IconLink from '../IconLink'
import Form from './Form'
import Success from './Success'
import List from './List'
import {AxiosError} from 'axios'

interface Props {
  horecaId?: number
  roles: Role[]
  isGetting: boolean
  getError?: AxiosError
  onRevoke: (role: Role) => void
}

const Team: React.FC<Props> = ({onRevoke, getError, isGetting, roles, horecaId}) => {
  const {t} = useTranslation()
  const [isInInviteMode, setInviteMode] = useState(false)
  const [inviteSent, setInviteSent] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState()

  const toggleInviteMode = useCallback(() => {
    setInviteMode(mode => !mode)
  }, [setInviteMode])

  const data =
    searchKeyword && roles
      ? roles.filter((role: Role) => {
          const name = `${role.owner.firstname} ${role.owner.lastname}`
          const keywordLowerCase = searchKeyword.toLowerCase()
          return (
            name.toLowerCase().includes(keywordLowerCase) ||
            role.owner.email.toLowerCase().includes(keywordLowerCase) ||
            role.name.toLowerCase() === keywordLowerCase
          )
        })
      : roles

  const [{error: inviteError, isFetching: isInviting}, inviteCall] = useAxios(
    {
      url: '/invitation',
      method: 'POST'
    },
    {
      success: () => {
        setInviteSent(true)
        setTimeout(() => {
          setInviteSent(false)
        }, 3000)
        toggleInviteMode()
      }
    }
  )

  const [{error: revokeError, isFetching: isRevoking}, revokeCall] = useAxios(
    {
      url: '/role/:id',
      method: 'DELETE'
    },
    {
      success: ({data: {role}}) => {
        onRevoke(role)
      },
      fail: () => {
        alert(t('config.team.revoke.fail'))
      }
    }
  )

  const columns = useMemo(() => {
    return [
      {
        Header: t('config.team.column.user'),
        Cell: ({row: {original}}: any) =>
          `[${original.name}] ${original.owner.firstname} ${original.owner.lastname}`
      },
      {
        Header: t('config.team.column.actions'),
        Cell: ({row: {original}}: any) => {
          return (
            <>
              <ReactTooltip place="top" type="dark" effect="solid" />
              {original.owner.phone && (
                <IconLink data-tip={original.owner.phone} href={`tel:${original.owner.phone}`}>
                  <FontAwesomeIcon icon={faPhone} color={colors.PRIMARY} />
                </IconLink>
              )}
              {original.owner.email && (
                <IconLink data-tip={original.owner.email} href={`mailto:${original.owner.email}`}>
                  <FontAwesomeIcon icon={faEnvelope} color={colors.PRIMARY} />
                </IconLink>
              )}
              <IconButton
                data-tip={t('config.team.revoke.tooltip')}
                disabled={isRevoking}
                type="button"
                onClick={() => {
                  if (confirm(t('config.team.revoke.confirm'))) {
                    revokeCall({
                      url: `/role/${original.id}`
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faUserTimes} color={colors.PRIMARY} />
              </IconButton>
            </>
          )
        }
      }
    ]
  }, [isRevoking, revokeCall])

  return (
    <Card title={t('config.team.title')}>
      {isGetting && <Loading text={t('config.loading.team')} />}
      {!isGetting && getError && (
        <Error component={ErrorComponent} error={getError} converters={[]} />
      )}
      {!isRevoking && revokeError && (
        <Error component={ErrorComponent} error={revokeError} converters={[]} />
      )}
      {isInInviteMode && (
        <Form
          isFetching={isInviting}
          error={inviteError}
          cancel={toggleInviteMode}
          initialValues={{
            email: '',
            role: 'Waiter'
          }}
          onSubmit={values =>
            inviteCall({
              data: {...values, horeca: horecaId}
            })
          }
        />
      )}
      {!isGetting && !isInInviteMode && (
        <>
          <Switch
            onClick={() => {
              toggleInviteMode()
              setInviteSent(false)
            }}
            text={t('config.team.invite')}
          />
          {inviteSent && <Success>{t('config.team.success')}</Success>}
          {roles && roles.length > 0 && (
            <Search onSearch={setSearchKeyword} placeholder={t('config.team.search')} />
          )}
          {data && data.length > 0 && <List data={data} columns={columns} />}
          {data && data.length === 0 && <EmptyState text={t('config.team.empty')} />}
        </>
      )}
    </Card>
  )
}

export default Team
