import styled from 'styled-components'

import media from '../../shared/style/media'

export default styled.div`
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-items: center;
  padding: 0 0.5rem 2rem;
  transition: all 0.5s ease-in-out;
  width: calc(100% - 1rem);
  max-width: 75rem;
  margin: 0 auto;
  
  ${media.lessThan('S')`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  `}
  
  ${media.lessThan('M')`
    grid-template-columns: 1fr;
  `}
  
  ${media.greaterThan('L')`
    grid-template-columns: 1fr 1fr;
  `}  
`
