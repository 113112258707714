import styled from 'styled-components'

import colors from '../../style/colors'
import font from '../../style/font'

export default styled.input`
  border: 0.0625rem solid ${colors.GREY};
  border-radius: 0.5rem 0 0 0.5rem;
  color: ${colors.DARK};
  font-family: ${font.family};
  padding: 0.5rem;
  width: calc(100% - 3rem - 18px);
  
  &:focus {
    outline: 0;
  }
`
