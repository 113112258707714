import dayjs from 'dayjs'

export const getYesterday = () => {
  const date = dayjs().subtract(1, 'day')
  const compareDate = date.subtract(1, 'week')
  return {
    compareFrom: compareDate.startOf('day'),
    compareTo: compareDate.endOf('day'),
    from: date.startOf('day'),
    option: 0,
    to: date.endOf('day')
  }
}

export const getToday = () => {
  const date = dayjs()
  const compareDate = date.subtract(1, 'week')
  return {
    compareFrom: compareDate.startOf('day'),
    compareTo: compareDate.endOf('day'),
    from: date.startOf('day'),
    option: 1,
    to: date.endOf('day')
  }
}

export const getWeek = () => {
  const date = dayjs()
  let compareDate = date.subtract(1, 'month').startOf('month')
  const weekOfMonth = date.week() - date.startOf('month').week()
  if (weekOfMonth > 1) compareDate = compareDate.add(weekOfMonth, 'week')
  compareDate = compareDate.startOf('week')

  return {
    compareFrom: compareDate,
    compareTo: compareDate.endOf('week'),
    from: date.startOf('week'),
    option: 2,
    to: date.endOf('week')
  }
}

export const getTimeFormat = (option: number): string => {
  if (option <= 3) {
    return 'ddd HH:mm'
  }

  return 'DD MMM YYYY HH:mm'
}

export const INTERVALS = ['1d', '1h', '30m', '15m']

export const getSelectedInterval = (time: number, intervalIndex: number) => {
  if (time < 2) {
    return intervalIndex === 0 ? 1 : intervalIndex
  } else if (time === 2) {
    return intervalIndex > 1 ? 0 : intervalIndex
  }
  return intervalIndex
}

export const getTooltipTimeFormat = (time: number, interval: string) => {
  if (time < 2) {
    return 'HH:mm'
  } else if (time === 2) {
    return interval === '1d' ? 'DDD' : 'DDD HH:mm'
  }
  return interval === '1d' ? 'MMM-DD DDD' : 'MMM-DD HH:mm'
}
