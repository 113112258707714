import styled from 'styled-components'
import {TransitionStatus} from 'react-transition-group/Transition'

import media from '../../style/media'

export default styled.div<{
  filterOpen: boolean
  hideTimeSelector?: boolean
  state: TransitionStatus
}>`
  background: #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  
  transition: 0.5s;
  top: ${({state}) => (state === 'entering' || state === 'entered' ? 0 : '-7rem')};
  
  ${({filterOpen, state}) => filterOpen && `
    top: ${(state === 'entering' || state === 'entered' ? 0 : '-10.6875rem')};
  `}

  ${({hideTimeSelector, state}) => hideTimeSelector && `
    top: ${(state === 'entering' || state === 'entered' ? 0 : '-4.375rem')};
  `}

  ${({filterOpen, state}) => filterOpen && media.lessThan('S')`
    top: ${(state === 'entering' || state === 'entered' ? 0 : '-11.8125rem')};
  `}
`
