import styled from 'styled-components'

import media from '../../style/media'

export default styled.div`
  padding: 0 0 0 3.5rem;
  width: calc(100% - 3.5rem);

  ${media.lessThan('M')`
    padding: 0
    width: 100%;
  `}
`

