import '@babel/polyfill'
import React from 'react'
import {render} from 'react-dom'
import {createGlobalStyle} from 'styled-components'
import {Normalize} from 'styled-normalize'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

import dayjs from 'dayjs'
// @ts-ignore
import en from 'dayjs/locale/en'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(weekOfYear)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale({
  ...en,
  weekStart: 1
})

import './shared/i18n'
import App from './App'
import font from './shared/style/font'

const GlobalStyles = createGlobalStyle`
  body, html, #root {
    background: #e3e3e3;
    height: 100%;
    font-family: ${font.family};
  }
`

const MOUNT_NODE = document.getElementById('root')

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: 'https://50162088439a481b8a0157570951b88b@sentry.io/1464457'})
}

ReactGA.initialize('UA-128968285-3')

render(
  <>
    <Normalize />
    <GlobalStyles />
    <App />
  </>,
  MOUNT_NODE
)
