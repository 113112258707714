import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../style/colors'

export default styled.input<{secondary?: boolean}>`
  background: 'none';
  border: 0.0625rem solid ${colors.GREY};
  border-radius: 0.25rem;
  color: ${colors.BLACK};
  font-size: 1rem;
  padding: 0.625rem 1.25rem;

  &:focus {
    outline: none;
  }

  ${({secondary}) =>
    secondary &&
    `
    background: ${colors.PRIMARY_DARK};
    border: none;
    color: ${colors.WHITE}

    ::placeholder {
      color: ${transparentize(0.5, colors.WHITE)};
    }
  `}
`
