import styled from 'styled-components'
// @ts-ignore
import logo from '../../../assets/logo.png'

export default styled.div`
  width: 16.875rem;
  height: 3.625rem;
  object-fit: cover;
  margin-bottom: 2rem;
  background-image: url(${logo});
`
