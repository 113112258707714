import styled from 'styled-components'

import colors from '../../../shared/style/colors'
import font from '../../../shared/style/font'

export default styled.div<{compare?: boolean}>`
  align-items: center;
  color: ${({compare}) => compare ? colors.GREY : colors.PRIMARY};
  display: flex;
  flex-direction: column;
  font-family: ${font.family};
  justify-content: center;
  padding: 0 1rem;
`
