import {AxiosError} from 'axios'
import {Action} from '../../types'

export interface State {
  error?: AxiosError
  isFetching: boolean
  data?: any
}

export const initialState: State = {
  isFetching: false
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CALL':
      return {...initialState, isFetching: true}
    case 'SUCCESS':
      return {...initialState, data: action.payload}
    case 'FAIL':
      return {...initialState, error: action.payload}
    default:
      return state
  }
}
