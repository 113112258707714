import React from 'react'
import {transparentize} from 'polished'
// @ts-ignore
import {useTableState, useTable, useExpanded, usePagination} from 'react-table'

import colors from '../../shared/style/colors'
import {TableContainer, TableHeader, TableCell,
  TableRow, TableBody, TableHeading} from '../../shared/components/Table'
import Pagination from '../../shared/components/Table/Pagination'
import renderVolumeSubComponent from './VolumeSubComponent'

interface Props {
  columns: any[]
  data: any[]
  isV2?: boolean
}

const List: React.FC<Props> = ({columns, data, isV2, ...rest}) => {
  const tableState = useTableState({pageIndex: 0, pageSize: 10})
  const {getTableProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage,
  pageCount, gotoPage, nextPage, previousPage, state: [{pageIndex}]} = useTable({
    columns,
    data,
    state: tableState
  }, useExpanded, usePagination)

  return (
    <>
      <TableContainer {...rest} {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((hg: any) => (
            <tr {...hg.getHeaderGroupProps()}>
              {hg.headers.map((col: any) => (
                <TableHeading {...col.getHeaderProps()}>
                  {col.render('Header')}
                </TableHeading>
              ))}
            </tr>
          ))}
        </TableHeader>
        <TableBody>
          {page.map((row: any, i: number) => prepareRow(row) || (
            <>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
              {row.isExpanded ? (
                <>
                  <tr />
                  <TableRow color={i % 2 === 0 ? transparentize(0.9, colors.PRIMARY) : colors.WHITE}>
                    <TableCell colSpan={columns.length}>
                      {renderVolumeSubComponent({row, isV2})}
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
            </>
          ))}
        </TableBody>
      </TableContainer>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        currentPage={pageIndex + 1}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        totalPages={pageCount}
      />
    </>
  )
}

export default List
