import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../../../shared/style/colors'

export default styled.div`
  align-items: center;
  background-color: ${transparentize(0.5, colors.BLACK)};
  border-top: 0.0625rem solid ${colors.BLACK};
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem 2rem;
`
