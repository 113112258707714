import React from 'react'

import colors from '../../style/colors'

const Spilled: React.FC = () => (
  <svg width='99' height='63' fill={colors.DARK} fillRule='nonzero'>
    <path d='M7.476 36.5H5v-5h2.476v5zm0 4v11.594c0 3.22 2.478 3.44 5.535 3.44h41.513V23.466H13.01c-3.057 0-5.535.014-5.535 3.234V29.5H5v-2.084C5 22.7 5.802 21 10.336 21H57v37H10.336C5.802 58 5 56.128 5 51.413V40.5h2.476zM50 7.714V18H16V7.714C16 3.454 19.806 0 24.5 0h17C46.194 0 50 3.454 50 7.714zm-2.615 7.715V7.714c0-2.84-2.576-5.143-5.754-5.143H24.369c-3.178 0-5.754 2.303-5.754 5.143v7.715h28.77z' />
    <path d='M44 7.6v7.8c0 1.436-1.287 2.6-2.875 2.6h-17.25C22.287 18 21 16.836 21 15.4V7.6C21 6.164 22.287 5 23.875 5h17.25C42.713 5 44 6.164 44 7.6zm-2.556 0H23.556v7.8h17.888V7.6z' />
    <path d='M8.143 16H57v47H8.143C3.646 63 0 59.287 0 54.706V24.294C0 19.714 3.646 16 8.143 16zm46.379 2.474H7.957c-3.026 0-5.479 2.51-5.479 5.607v30.838c0 3.097 2.453 5.607 5.479 5.607h46.565V18.474z' />
    <path d='M88.307 40.74C94.212 40.74 99 45.721 99 51.87 99 58.017 94.212 63 88.307 63H73.603c-.372 0-.7-.024-.99-.076-.55.05-1.111.076-1.683.076-2.777 0-4.649-.018-6.81-.093-6.937-.241-9.12.82-9.12-1.325V46.304C55 37.062 60.755 31 69.593 31c6.642 0 12.486 4.239 14.89 10.472 1.206-.481 2.498-.733 3.824-.733zm-29.56 19.119c1.303.18 3.114.306 5.311.382 2.148.074 4.02.092 6.8.092.562 0 1.11-.027 1.643-.082l.457.033c.113.03.318.05.605.05H88.44c4.481 0 8.115-3.77 8.115-8.422 0-4.65-3.634-8.42-8.115-8.42-1.42 0-2.783.377-3.99 1.085l-1.484.872-.48-1.7c-1.673-5.93-6.936-10.082-12.982-10.082-7.441 0-12.06 4.85-12.06 12.631v13.316c.319.086.759.17 1.301.245z' />
    <path d='M61.91 43.163c.314-1.905.999-3.328 2.038-4.305 1.035-.97 2.109-1.436 3.258-1.436v-2c-1.682 0-3.237.673-4.627 1.978-1.384 1.3-2.26 3.124-2.643 5.437l1.973.326z' />
  </svg>
)

export default Spilled
