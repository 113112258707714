import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'

import Container from './Container'
import Text from './Text'

interface Props {
  text: string
}

const Loading: React.FC<Props> = ({text}) => {
  return (
    <Container>
      <FontAwesomeIcon icon={faCog} size="2x" spin />
      <Text>{text}</Text>
    </Container>
  )
}

export default Loading
