import styled from 'styled-components'

import font from '../../../style/font'
import colors from '../../../style/colors'

export default styled.div`
  color: ${colors.DARK}; 
  font-family: ${font.family};
  padding: 0 0 0.375rem;
`
