import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {faAngleRight, faAngleDown} from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'

import EmptyState from '../../../shared/components/EmptyState'
import {TableExtender} from '../../../shared/components/Table'
import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import {SafeOnLogV2, CalibrationLogV2, CleaningLogV2, ManagerLogV2, VolumeDataV2} from '../../../types'
import List from '../List'

interface Props extends CategorySelectorProps {
  safeon: SafeOnLogV2[]
  calibration: CalibrationLogV2[]
  cleaning: CleaningLogV2[]
  manager: ManagerLogV2[]
  timeFormat: string
}

const LogsList: React.FC<Props> = ({safeon, calibration, cleaning, manager, timeFormat, ...rest}) => {
  const {t} = useTranslation()
  const selectedCategory = rest.selectedCategory

  const [data, columns] = useMemo(() => {
    switch (selectedCategory) {
      case 'SafeOn':
        return [safeon, [
          {Header: '', id: 'expander', Cell: ({row}: any) => row.original.volume
              && Object.values(row.original.volume).filter((v: VolumeDataV2) => !!v.amount).length > 0 && (
                <TableExtender
                  icon={row.isExpanded ? faAngleDown : faAngleRight}
                  {...row.getExpandedToggleProps()}
                />
              )
          },
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
              return original.key
                ? original.key.owner
                  ? `${original.key.owner.owner.firstname} ${original.key.owner.owner.lastname}`
                  : original.key.name
                : t('common.undefined.key', {id: original.key_id})
            }},
          {Header: t('technic.columns.device'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.device
                ? original.device.name
                  ? original.device.name
                  : `${original.device.model} #${original.device.serial}`
                : t('common.undefined.device', {id: original.device_id})
            }},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      case 'Cleaning':
        return [cleaning, [
          {Header: '', id: 'expander', Cell: ({row}: any) => row.original.volume
              && Object.values(row.original.volume).filter((v: VolumeDataV2) => !!v.amount).length > 0 && (
                <TableExtender
                  icon={row.isExpanded ? faAngleDown : faAngleRight}
                  {...row.getExpandedToggleProps()}
                />
              )
          },
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
              return original.key
                ? original.key.owner
                  ? `${original.key.owner.owner.firstname} ${original.key.owner.owner.lastname}`
                  : original.key.name
                : t('common.undefined.key', {id: original.key_id})
            }},
          {Header: t('technic.columns.device'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.device
                ? original.device.name
                  ? original.device.name
                  : `${original.device.model} #${original.device.serial}`
                : t('common.undefined.device', {id: original.device_id})
            }},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      case 'Calibration':
        return [calibration, [
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any): any => {
              return original.key
                ? original.key.owner
                  ? `${original.key.owner.owner.firstname} ${original.key.owner.owner.lastname}`
                  : original.key.name
                : t('common.undefined.key', {id: original.key_id})
            }},
          {Header: t('technic.columns.device'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.device
                ? original.device.name
                  ? original.device.name
                  : `${original.device.model} #${original.device.serial}`
                : t('common.undefined.device', {id: original.device_id})
            }},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      case 'Manager':
        return [manager, [
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any): any => {
              return original.key
                ? original.key.owner
                  ? `${original.key.owner.owner.firstname} ${original.key.owner.owner.lastname}`
                  : original.key.name
                : t('common.undefined.key', {id: original.key_id})
            }},
          {Header: t('technic.columns.device'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.device
                ? original.device.name
                  ? original.device.name
                  : `${original.device.model} #${original.device.serial}`
                : t('common.undefined.device', {id: original.device_id})
            }},
          {Header: t('technic.columns.when'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.datetime
                ? dayjs(original.datetime).format(timeFormat)
                : '-'
            }},
        ]]
      default:
        return [null, null]
    }
  }, [safeon, timeFormat, selectedCategory])

  return (
    <Card title={t('technic.logs.title')} {...rest}>
      {data && data.length > 0 && columns && (
        <List data={data} columns={columns} isV2 />
      )}
      {selectedCategory && data && data.length === 0 && columns && (
        <EmptyState
          text={
            t(`technic.logs.empty`, {type: t(`technic.logs.${selectedCategory.toLowerCase()}`)})
          }
        />
      )}
    </Card>
  )
}

export default LogsList
