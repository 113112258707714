import styled from 'styled-components'

import media from '../../../shared/style/media'

export default styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  
  ${media.lessThan('M')`
    flex-direction: row;
    justify-content: space-around;
  `}
`
