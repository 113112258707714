import React from 'react'

import colors from '../../../style/colors'

interface Props {
  color?: string
}

const Technic: React.FC<Props> = ({color = colors.WHITE}) => (
  <svg width='35' height='38' fill={color}>
    <g fillRule='nonzero'>
      <path d='M22.616 28.073l5.03 7.457c1.238 1.835 3.719 2.334 5.53 1.111 1.813-1.222 2.28-3.709 1.043-5.543l-7.633-11.316.882-.595-.637-.944-.882.594-.686.463-1.168-1.73-1.338.902-9.119-13.52-.293-1.837L10.302.203l-2.195 1.48 1.471 4.013 1.686.888 9.105 13.5-1.473.993 1.167 1.73-.686.464-.837.564.637.944.837-.564 1.996 2.959.932-.63-.963-1.428 1.04-.702 7.934 11.763a2.884 2.884 0 0 1-2.374-1.275l-5.03-7.458-.933.629zM13.32 7.621l.524-.353.228.336-.524.354-.228-.337zM9.472 2.137l.717-.484 2.102 2.01.273 1.707.643.954-.524.353-.649-.961-1.55-.817-1.012-2.762zm4.711 6.765l.524-.354 7.118 10.553-.524.353-7.118-10.552zm6.282 12.49l.54-.365.027.039 2.388-1.611-.026-.039.406-.274.53.787-3.335 2.25-.53-.787zm.481 2.194l4.707-3.176.396.587-4.707 3.175-.396-.586zm3.812-.344l4.603 6.824.932-.628-4.603-6.825.996-.672 6.6 9.785c.541.803.627 1.779.316 2.617l-2.52-3.736-.932.63 2.789 4.134c-.121.118-.254.228-.399.327-.13.087-.818.57-1.598.457L23.02 24.42l1.739-1.18z' />
      <path d='M17.353 17.264l-.66-1.032-2.174 3.222-.524-.353 2.327-3.45-.66-1.031-2.599 3.852-1.338-.902-1.167 1.73-.687-.463-.881-.594-.637.944.882.595-7.633 11.316c-1.238 1.834-.77 4.321 1.042 5.543 1.812 1.223 4.293.724 5.53-1.11l5.03-7.458-.932-.629-5.03 7.458a2.884 2.884 0 0 1-2.375 1.275l3.337-4.948-3.326 4.926c-.78.113-1.468-.37-1.598-.457a2.809 2.809 0 0 1-.398-.327l2.788-4.135-.932-.629-2.52 3.736a2.884 2.884 0 0 1 .316-2.617l6.6-9.785.996.672-4.603 6.825.932.628 4.604-6.824 1.735 1.177.003-.005 1.041.702-.963 1.429.932.629 1.996-2.96.836.565.637-.944-.836-.564-.687-.463 1.168-1.731-1.473-.994 1.901-2.82zm2.822-4.183l4.382-6.497 1.686-.888 1.47-4.012L25.52.204l-3.043 2.91-.294 1.838-3.816 5.658.706.965 2.041-3.027.524.354-2.168 3.215.706.964zM22.5 7.62l-.227.337-.524-.354.227-.336.524.353zm3.848-5.484l-1.013 2.762-1.55.817-.648.96-.524-.352.643-.954.272-1.706 2.102-2.011.718.484zM15.356 21.392l-.53.787-3.336-2.25.53-.787.406.274-.026.039 2.389 1.61.026-.038.54.365zm-.481 2.194l-.396.586-4.708-3.175.396-.587 4.708 3.176z' />
    </g>
  </svg>
)

export default Technic
