import styled from 'styled-components'

import font from '../../../../shared/style/font'
import colors from '../../../../shared/style/colors'

export default styled.h4`
  color: ${colors.PRIMARY};
  font-family: ${font.family};
  font-size: 2.5rem;
  margin: 2.5rem 0 1.25rem;
`
