import React, {createContext, useCallback, useContext, useEffect} from 'react'
import {AxiosError} from 'axios'

import {Horeca} from '../../types'
import useAxios from '../useFetch'
import {useAuthContext} from '../auth/Provider'

export interface Context {
  error?: AxiosError
  horecas: Horeca[] | null
  isFetching: boolean
  refresh: () => void
}

const HorecaContext = createContext<Context>({
  horecas: null,
  isFetching: false,
  refresh: () => false
})

export const useHorecaContext = () => {
  const store = useContext(HorecaContext)
  if (!store) throw new Error('Can not use `useHorecaContext` outside of a `HorecaContext`')
  return store
}

export const Provider = (props: any) => {
  const {user} = useAuthContext()

  const [{error, isFetching, data}, call] = useAxios({
    url: '/horeca',
    method: 'GET'
  })
  const horecas = data ? data.horecas : null

  useEffect(() => {
    if (user) call()
  }, [user])

  const refresh = useCallback(() => {
    call()
  }, [call])

  const value: Context = {
    horecas,
    isFetching,
    error,
    refresh
  }

  return <HorecaContext.Provider value={value} {...props} />
}
