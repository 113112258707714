import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import colors from '../../../style/colors'

export default styled(FontAwesomeIcon)<{onClick: () => void}>`
  color: ${colors.WHITE};
  cursor: pointer;
  position: fixed;
  right: 1.25rem;
  top: 1.25rem;
  z-index: 3;
`




