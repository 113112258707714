import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../style/colors'

export default styled.select`
  appearance: none;
  background-position: calc(100% - 1.25rem) calc(1rem + 0.125rem), calc(100% - 0.9375rem) calc(1rem + 0.125rem), 100% 0;
  background-size: 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 2.5rem 2.5rem;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, ${colors.DARK} 50%),
    linear-gradient(135deg, ${colors.DARK} 50%, transparent 50%),
    linear-gradient(to right, ${colors.WHITE}, ${colors.WHITE});
  border: 0.0625rem solid ${colors.GREY};
  padding: 0.625rem;
  color: ${({value}) => (!value ? transparentize(0.5, colors.DARK) : colors.DARK)};
  width: 100%;
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    cursor: not-allowed;
  }
`
