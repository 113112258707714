import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {faAngleRight, faAngleDown} from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'

import EmptyState from '../../../shared/components/EmptyState'
import {TableExtender} from '../../../shared/components/Table'
import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import {CalibrationLog, CleaningLog, ManagerLog} from '../../../types'
import List from '../List'

interface Props extends CategorySelectorProps {
  calibration: CalibrationLog[]
  cleaning: CleaningLog[]
  manager: ManagerLog[]
  timeFormat: string
}

//TODO replace any types caused by missing type package of react table (misses types for 7.x)
const KeyLogsList: React.FC<Props> = ({calibration, cleaning, manager, timeFormat, ...rest}) => {
  const {t} = useTranslation()
  const selectedCategory = rest.selectedCategory

  const [data, columns] = useMemo(() => {
    switch (selectedCategory) {
      case 'Cleaning':
        return [cleaning, [
          {Header: '', id: 'expander', Cell: ({row}: any) => row.original.volume
              && Object.values(row.original.volume).length > 0 && (
                <TableExtender
                  icon={row.isExpanded ? faAngleDown : faAngleRight}
                  {...row.getExpandedToggleProps()}
                />
            )
          },
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
            return `[${original.emp_key ? original.emp_key : '-'}] ${original.keyName}`
          }},
          {Header: t('technic.columns.address'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return `${original.mod_addr}:${original.evt_valve_num}`
            }},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      case 'Calibration':
        return [calibration, [
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
              return `[${original.emp_key ? original.emp_key : '-'}] ${original.waiter_name ? original.emp_key : '-'}`
          }},
          {Header: t('technic.columns.device'), accessor: 'mod_addr', minWidth: 100},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      case 'Manager':
        return [manager, [
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
              return `[${original.emp_key ? original.emp_key : '-'}] ${original.waiter_name ? original.emp_key : '-'}`
          }},
          {Header: t('technic.columns.device'), accessor: 'mod_addr', minWidth: 100},
          {Header: t('technic.columns.when'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.evt_datetime
                ? dayjs(original.evt_datetime).format(timeFormat)
                : '-'
            }},
        ]]
      default:
        return [null, null]
    }
  }, [calibration, cleaning, manager, timeFormat, selectedCategory])

  return (
    <Card title={t('technic.keyLogs.title')} {...rest}>
      {data && data.length > 0 && columns && (
        <List data={data} columns={columns} />
      )}
      {selectedCategory && data && data.length === 0 && columns && (
        <EmptyState
          text={
            t(`technic.logs.empty`, {type: t(`technic.keyLogs.${selectedCategory.toLowerCase()}`)})
          }
        />
      )}
    </Card>
  )
}

export default KeyLogsList
