import React from 'react'
import {useTranslation} from 'react-i18next'

import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import ActivityOverTimeGraph from '../ActivityOverTimeGraph'

interface Props extends CategorySelectorProps {
  currency: string
  dataPoints: any[]
  emptyText: string
  valueType: string
  timeFormat: string
  time: number
  selectedInterval: string
  intervalChange: (internal: number) => void
}

const ActivityGraph: React.FC<Props> = ({timeFormat, time, currency, dataPoints, emptyText, valueType,
intervalChange, selectedInterval, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Card title={t('volume.activity.title')} {...rest}>
      <ActivityOverTimeGraph
        currency={currency}
        dataPoints={dataPoints}
        emptyText={emptyText}
        valueType={valueType}
        time={time}
        timeFormat={timeFormat}
        selectedInterval={selectedInterval}
        intervalChange={intervalChange}
        name='noncoffee'
      />
    </Card>
  )
}

export default ActivityGraph
