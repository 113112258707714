import styled from 'styled-components'
import colors from '../../style/colors'
import font from '../../style/font'

export default styled.h3`
  color: ${colors.PRIMARY};
  font-family: ${font.family};
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0.75rem 0;
`
