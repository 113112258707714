import styled from 'styled-components'

export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
`
