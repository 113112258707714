import styled from 'styled-components'

import colors from '../../../style/colors'

export default styled.button`
  border: none;
  cursor: pointer;
  margin: 0 0.5rem;
  outline: 0;
  
  &:disabled {
    cursor: default;
  }
  
  &:disabled > svg {
    color: ${colors.GREY_DARK};
  }
`
