export const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

export interface Category {
  name: string
  i18nKey: string
}

export const CATEGORIES: Category[] = [
  {
    i18nKey: 'categories.beer',
    name: 'Beer' // 0
  },
  {
    i18nKey: 'categories.wine',
    name: 'Wine'
  },
  {
    i18nKey: 'categories.spirits',
    name: 'Spirits'
  },
  {
    i18nKey: 'categories.cocktails',
    name: 'Cocktails'
  },
  {
    i18nKey: 'categories.postmix',
    name: 'Postmix'
  },
  {
    i18nKey: 'categories.water',
    name: 'Water'
  },
  {
    i18nKey: 'categories.juices',
    name: 'Juices'
  },
  {
    i18nKey: 'categories.coffee',
    name: 'Coffee'
  },
  {
    i18nKey: 'categories.long',
    name: 'Long-Drink'
  },
  {
    i18nKey: 'categories.bottles',
    name: 'Bottles'
  }
]

export const GRAPH_COLORS = {
  Beer: '#e9b44c',
  Wine: '#b6202b',
  Spirits: '#1c110a',
  Cocktails: '#94c9a9',
  Postmix: '#434de4',
  Water: '#50A2A7',
  Coffee: '#581908',
  Juices: '#d0ffce',
  'Long-Drink': '#a7ff45',
  Bottles: '#7A6F9B'
}
