import React from 'react'
import dayjs from 'dayjs'

// @ts-ignore
import CanvasJSReact from '../../../shared/canvasjs/canvasjs.react'
import EmptyState from './EmptyState'
import IntervalSelector from './IntervalSelector'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

interface Props {
  currency: string
  dataPoints: any[]
  emptyText: string
  intervalChange: (interval: number) => void
  selectedInterval: string
  time: number
  timeFormat: string
  valueType: string
  name: string
}

const ActivityOverTimeGraph: React.FC<Props> = ({
  intervalChange,
  name,
  time,
  selectedInterval,
  currency,
  valueType,
  emptyText,
  dataPoints,
  timeFormat
}) => {
  let interval, intervalType
  if (['15m', '30m'].includes(selectedInterval)) {
    interval = 4
    intervalType = 'hour'
  } else if (selectedInterval === '1d') {
    interval = 1
    intervalType = 'day'
  } else {
    interval = 4
    intervalType = 'hour'
  }

  if (dataPoints && dataPoints.length === 0) {
    return <EmptyState text={emptyText} />
  }

  return (
    <>
      <CanvasJSChart
        options={{
          animationEnabled: true,
          toolTip: {
            contentFormatter: (e: any) => {
              let content = ''
              for (let i = 0; i < e.entries.length; i++) {
                const x = CanvasJSReact.CanvasJS.formatDate(
                  dayjs(e.entries[i].dataPoint.x).toISOString(),
                  timeFormat
                )
                const y = ['Coffee', 'Bottles'].includes(name)
                  ? `${e.entries[i].dataPoint.y} ${valueType}`
                  : `${(e.entries[i].dataPoint.y / 1000).toFixed(2)} ${valueType}`
                const revenue = `${e.entries[i].dataPoint.revenue.toFixed(2)} ${currency}`
                content = `${x}<br />${revenue}<br />${y}`
              }
              return content
            }
          },
          axisX: {
            valueFormatString: timeFormat,
            interval,
            intervalType
          },
          axisY: {
            minimum: 0,
            labelFormatter: (e: any) =>
              ['Coffee', 'Bottles'].includes(name)
                ? `${e.value} ${valueType}`
                : `${(e.value / 1000).toFixed(2)} ${valueType}`
          },
          data: dataPoints
        }}
      />
      <IntervalSelector
        selectedInterval={selectedInterval}
        onChange={intervalChange}
        name={name}
        time={time}
      />
    </>
  )
}

export default ActivityOverTimeGraph
