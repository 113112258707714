import styled from 'styled-components'

import font from '../../../../shared/style/font'
import colors from '../../../../shared/style/colors'

export default styled.h4`
  color: ${colors.WHITE};
  font-family: ${font.family};
  font-size: 1.5rem;
`
