import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import translationsEn from './locales/en.json'
import translationsDe from './locales/de.json'
import translationsFr from './locales/fr.json'

const resources = {
  en: { translation: translationsEn },
  fr: { translation: translationsFr },
  de: { translation: translationsDe }
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: ['cookie'],
      lookupCookie: 'i18next',
      order: ['cookie', 'navigator']
    },
    resources,
    lng: 'en',
    load: 'languageOnly',
    react: {
      wait: false
    },
    fallbackLng: 'en',
    whitelist: ['en', 'fr', 'de'],
    interpolation: {
      escapeValue: false
    }
  })

export default i18n;
