import React, {useCallback} from 'react'
import {useCookies} from 'react-cookie'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faSignOut} from '@fortawesome/pro-light-svg-icons'
import {Redirect, RouteComponentProps, withRouter} from 'react-router'

import {User} from '../../../types'
import {useHorecaContext} from '../../horeca/Provider'
import {useAuthContext} from '../../auth/Provider'
import Logo from '../Logo'
import Volume from './icons/Volume'
import Technic from './icons/Technic'
import Team from './icons/Team'
import LogoContainer from './LogoContainer'
import Container from './Container'
import List from './List'
import Item from './Item'
import ItemButton from './ItemButton'

interface Props
  extends RouteComponentProps<{
    horecaId?: string
    page?: string
  }> {
  user: User
}

const Nav: React.FC<Props> = ({match, history, user}) => {
  const {logout, isAdmin} = useAuthContext()
  const horecaManagerRole = user.roles.find(r => r.horeca && r.name === 'Manager')
  const {horecas} = useHorecaContext()
  const [cookies, setCookie] = useCookies(['DH'])
  const horecaIdCookie = cookies.DH
  const horecaIdParam = match.params.horecaId
  const pageParam = match.params.page
  const horeca =
    horecaIdParam &&
    horecas &&
    horecas.find(h => h.id === (horecaIdParam ? parseInt(match.params.horecaId) : undefined))

  const logoutCallback = useCallback(() => {
    logout(history)
  }, [history, logout])

  if (!horecaIdParam && horecaIdCookie) {
    return <Redirect to={`/h/${horecaIdCookie}/volume`} push />
  }

  if (!horecaIdParam && horecaManagerRole && horecaManagerRole.horeca) {
    return <Redirect to={`/h/${horecaManagerRole.horeca.id}/volume`} push />
  }

  if (!horecaIdParam && horecas && horecas.length > 0) {
    return <Redirect to={`/h/${horecas[0].id}/volume`} push />
  }

  if (horecaIdParam && horecaIdCookie !== horecaIdParam) {
    setCookie('DH', horecaIdParam, {path: '/'})
  }

  if (horecaIdParam && !pageParam) {
    return <Redirect to={`/h/${horecaIdParam}/volume`} />
  }

  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <List>
        <Item
          icon={<Volume />}
          route={`/h/${horecaIdParam}/volume`}
          selected={!!horecaIdParam && pageParam === 'volume'}
        />
        <Item
          icon={<Team />}
          route={`/h/${horecaIdParam}/team`}
          selected={!!horecaIdParam && pageParam === 'team'}
        />
        {(isAdmin || (horeca && horeca.activationType === 'PRO+')) && (
          <Item
            icon={<Technic />}
            route={`/h/${horecaIdParam}/technic`}
            selected={!!horecaIdParam && pageParam === 'technic'}
          />
        )}
        <Item
          icon={<FontAwesomeIcon icon={faCog} size="2x" color="#fff" />}
          route={`/h/${horecaIdParam}/config`}
          selected={!!horecaIdParam && pageParam === 'config'}
        />
        <ItemButton
          icon={<FontAwesomeIcon icon={faSignOut} size="2x" color="#fff" />}
          onClick={logoutCallback}
          selected={false}
        />
      </List>
    </Container>
  )
}

export default withRouter(Nav)
