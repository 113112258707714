import React from 'react'
import {Link} from 'react-router-dom'
import Container from './Container'

interface Props {
  link: string
  linkText: string
  text: string
}

const AuthLink: React.FC<Props> = ({text, link, linkText}) => {
  return (
    <Container>
      {text}
      <Link to={link}>{linkText}</Link>
    </Container>
  )
}

export default AuthLink
