import styled from 'styled-components'

export default styled.div`
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  justify-items: stretch;
  padding: 0 0.5rem 2rem;
  transition: all 0.5s ease-in-out;
`
