import React from 'react'
import {Router, Switch, Route} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'
import ReactGA from 'react-ga'

import {Provider as AuthProvider} from './shared/auth/Provider'
import AxiosConfig from './shared/useFetch/config'
import PublicRoute from './shared/route/PublicRoute'
import PrivateRoute from './shared/route/PrivateRoute'
import {Provider as HorecaProvider} from './shared/horeca/Provider'
import history from './shared/history'
import ROUTES from './routes'
import Horeca from './private/Horeca'
import Login from './public/Login'
import Signup from './public/Signup'
import Reset from './public/Reset'
import Change from './public/Change'
import Invitation from './public/Invitation'
import Terms from './public/Terms'
import Privacy from './public/Privacy'
import Cookie from './public/Cookie'
import Onboarding from './private/Onboarding'

history.listen(location => {
  ReactGA.pageview(location.pathname)
})

const App = () => {
  return (
    <CookiesProvider>
      <AuthProvider>
        <HorecaProvider>
          <AxiosConfig />
          <Router history={history}>
            <Switch>
              <Route path={ROUTES.COOKIE} component={Cookie} />
              <Route path={ROUTES.TERMS} component={Terms} />
              <Route path={ROUTES.PRIVACY} component={Privacy} />
              <Route path={ROUTES.INVITATION} component={Invitation} />

              <PrivateRoute path={ROUTES.HORECA_SELECTED} component={Horeca} />
              <PrivateRoute path={ROUTES.HORECA} component={Horeca} />

              <PrivateRoute path={ROUTES.ONBOARDING} component={Onboarding} />

              <PublicRoute path={ROUTES.RESET_PASSWORD} component={Reset} />
              <PublicRoute path={ROUTES.CHANGE_PASSWORD} component={Change} />
              <PublicRoute path={ROUTES.SIGN_UP} component={Signup} />
              <PublicRoute path={ROUTES.LOGIN} component={Login} />
              <PublicRoute path="*" component={Login} />
            </Switch>
          </Router>
        </HorecaProvider>
      </AuthProvider>
    </CookiesProvider>
  )
}

export default App
