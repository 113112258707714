import React from 'react'
import {useTranslation} from 'react-i18next'
import {faAngleRight, faAngleDown} from '@fortawesome/pro-regular-svg-icons'

import {TableExtender} from '../../shared/components/Table'
import Card from '../../shared/components/Card'
import List from './List'

interface Props {
  currency: string
  data: any
  download?: React.ReactElement
  totalRevenue?: number
  isWaiter: boolean
}

const TeamList: React.FC<Props> = ({isWaiter, data, currency, download, totalRevenue, ...rest}) => {
  const {t} = useTranslation()

  const columns = [
    {
      Header: '',
      id: 'expander',
      Cell: ({row}: any) => (
        <TableExtender
          icon={row.isExpanded ? faAngleDown : faAngleRight}
          {...row.getExpandedToggleProps()}
        />
      )
    },
    {Header: t('team.list.columns.name'), width: 200, accessor: 'name'},
    {
      Header: t('team.list.columns.revenue'),
      Cell: ({row: {original}}: any) => {
        // @ts-ignore
        const revenue: number = Object.values(original.sold).reduce(
          (t: number, c: any) => t + c.revenue,
          0
        )

        return (
          <span>
            {revenue.toFixed(2)} {currency}{' '}
            {!isWaiter && (
              <strong>
                ({totalRevenue === 0 ? 0 : ((revenue / totalRevenue) * 100).toFixed(2)}
                %)
              </strong>
            )}
          </span>
        )
      }
    }
  ]

  return (
    <Card title={t('team.list.title')} {...rest}>
      {!isWaiter && (
        <p>
          {t('team.total', {
            currency,
            value: totalRevenue ? totalRevenue.toFixed(2) : totalRevenue
          })}
        </p>
      )}
      {data && data.length > 0 && columns && (
        <List data={data} columns={columns} currency={currency} />
      )}
      {!isWaiter && download}
    </Card>
  )
}

export default TeamList
