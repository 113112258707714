import React from 'react'
import styled from 'styled-components'

import {CleaningLog, VolumeData, VolumeDataV2} from '../../types'
import font from '../../shared/style/font'

const List = styled.ul`
  margin: 0;
  padding: 0.5rem 0 0.5rem 4rem;
`

const Item = styled.li`
  font-family: ${font.family};
  font-size: 0.925rem;
  padding: 0.5rem 0;
`

const Volume = styled.span`
  font-weight: bold;
  padding-left: 0.5rem;
`

interface Row {
  original: CleaningLog
}

interface Props {
  row: Row
  isV2?: boolean
}

const VolumeSubComponent: React.FC<Props> = ({row: {original}, isV2}) => {
  if (isV2) return (
    <List>
      {Object.values(original.volume).map((v: VolumeDataV2, i) => {
        if (v.group) return (
          <Item key={i}>
            ({v.group.category}) {v.group.name}:
            <Volume>{(v.amount / 1000).toFixed(2)} L</Volume>
          </Item>
        )
        if (v.product) return (
          <Item key={i}>
            ({v.product.category}) {v.product.name}:
            <Volume>{(v.amount / 1000).toFixed(2)} L</Volume>
          </Item>
        )
        return null
      })}
    </List>
  )

  return (
    <List>
      {Object.values(original.volume).map((v: VolumeData, i) => {
        if (!v.amount) return null
        return (
          <Item key={i}>
            ({v.category}) {v.name}:
            <Volume>{(v.amount / 1000).toFixed(2)} L</Volume>
          </Item>
        )
      })}
    </List>
  )
}

export default VolumeSubComponent
