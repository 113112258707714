import styled from 'styled-components'

import font from '../../style/font'
import colors from '../../style/colors'

export default styled.td`
  color: ${colors.DARK};
  font-family: ${font.family};
  font-size: 0.875rem;
`
