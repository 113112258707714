import styled from 'styled-components'

import colors from '../../shared/style/colors'
import font from '../../shared/style/font'

export default styled.div`
  color: ${colors.DARK};
  font-family: ${font.family};
  font-size: 1rem;
  margin-top: 1.5rem;
  text-align: center;
`
