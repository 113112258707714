import React from 'react'

import Container from './Container'
import Icon from './Icon'
import Text from './Text'

interface Props {
  text: string
}

const EmptyState: React.FC<Props> = ({text}) => {
  return (
    <Container>
      <Icon />
      <Text>{text}</Text>
    </Container>
  )
}

export default EmptyState
