import React from 'react'
import {FieldProps} from 'formik'

import Container from './Container'
import Label from './Label'
import ValidationError from './ValidationError'
import Field from './Field'

export interface Option {
  label: string
  value: string | number
}

interface Props extends FieldProps {
  id: string
  label: string
  placeholder: string
  options: Option[]
}

const Select: React.FC<Props> = ({field, form, label, options, placeholder, ...rest}) => {
  return (
    <Container>
      <Label htmlFor={rest.id}>
        {label}
        {form.submitCount > 0 && form.errors[field.name] && (
          <ValidationError>{form.errors[field.name]}</ValidationError>
        )}
      </Label>
      <Field {...field} {...rest}>
        <option value=''>{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    </Container>
  )
}

export default Select
