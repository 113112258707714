import styled from 'styled-components'

export default styled.div`
  font-size: 1.75rem;
  margin-bottom: 0.25rem;
  width: 100%;
  
  & > sup {
    font-size: 0.75rem;
    left: -0.25rem
    position: relative;
    top: -0.75rem;
    vertical-align: baseline;
  }
`
