import styled from 'styled-components'

import colors from '../../../shared/style/colors'
import media from '../../../shared/style/media'

export default styled.div`
  background-color: ${colors.DARK};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 3.5rem;
  z-index: 1;
  
  ${media.lessThan('M')`
    display: none;
  `}
`
