import styled from 'styled-components'

import colors from '../../style/colors'

export default styled.div`
  position: fixed;
  padding: 2rem;
  border-radius: 0.25rem;
  background-color: ${colors.WHITE};
  min-width: 20rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
 
`