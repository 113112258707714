import styled from 'styled-components'

import font from '../../../style/font'
import media from '../../../style/media'

export default styled.label`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${font.family};
  justify-content: center;
  margin: 0 1rem;
  
  & input {
    margin: 0.25rem 0 0 0;
    text-align: center;
    width: 8rem;
  }
  
  ${media.lessThan('S')`
    flex-direction: row;
    justify-content: space-between;
    margin: 0.25rem 0;
    
    & input {
      margin: 0 0 0 1rem;
    }
  `}
`
