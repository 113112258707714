import styled from 'styled-components'

import font from '../../../shared/style/font'

export default styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  flex-direction: column;
  font-family: ${font.family};
`
