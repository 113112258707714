import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Container from './Container'
import Action from './Action'
import Text from './Text'

const Marketing: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Container>
      <Text>
        <Trans
          i18nKey='volume.psr.marketing.text'
          components={[<strong/>]}
        />
      </Text>
      <Action href='mailto:innovation@drinkotec.ch'>
        {t('volume.psr.marketing.action')}
      </Action>
    </Container>
  )
}

export default Marketing
