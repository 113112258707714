import React, {useState} from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {AxiosError} from 'axios'

import FormContainer from '../../shared/components/Form'
import Button from '../../shared/components/Button'
import Input from '../../shared/components/Input'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'
import ROUTES from '../../routes'
import Agreement from './Agreement'

interface Form {
  email: string
  firstname: string
  lastname: string
  password: string
}

interface Props extends FormikConfig<Form> {
  isFetching?: boolean
  error?: AxiosError
}

const SignupForm: React.FC<Props> = ({onSubmit, initialValues, isFetching, error}) => {
  const {t} = useTranslation()
  const [agreed, setAgreed] = useState(false)

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstname: Yup.string().required(t('common.error.required')),
        lastname: Yup.string().required(t('common.error.required')),
        email: Yup.string()
          .email(t('common.error.email.format'))
          .required(t('common.error.required')),
        password: Yup.string()
          .min(6, t('common.error.password.length'))
          .required(t('common.error.required'))
      })}
      render={({handleSubmit}) => (
        <FormContainer onSubmit={handleSubmit}>
          {error && (
            <Error
              component={ErrorComponent}
              secondary
              error={error}
              converters={[
                {
                  key: 'signup.error.invalid',
                  check: err =>
                    !!err.response &&
                    ['UsageError', 'TypeError', 'TokenInvalid', 'TokenExpired'].includes(
                      err.response.data.name
                    )
                },
                {
                  key: 'signup.error.exists',
                  check: err => !!err.response && err.response.data.code === 'E_UNIQUE'
                }
              ]}
            />
          )}

          <Field
            id="signup-firstname"
            name="firstname"
            type="text"
            component={Input}
            label={t('common.firstname')}
            placeholder={t('common.firstname')}
            secondary
          />
          <Field
            id="signup-lastname"
            name="lastname"
            type="text"
            component={Input}
            label={t('common.lastname')}
            placeholder={t('common.lastname')}
            secondary
          />
          <Field
            id="signup-email"
            name="email"
            type="email"
            component={Input}
            label={t('common.email')}
            placeholder={t('common.email')}
            secondary
          />
          <Field
            id="signup-password"
            name="password"
            type="password"
            component={Input}
            label={t('common.password.label')}
            placeholder={t('common.password.label')}
            secondary
          />

          <Agreement>
            <input type="checkbox" onChange={() => setAgreed(!agreed)} checked={agreed} />
            {t('signup.agree')}&nbsp;
            <Link to={ROUTES.TERMS}>{t('common.terms')}</Link>,&nbsp;
            <Link to={ROUTES.PRIVACY}>{t('common.privacy')}</Link>&nbsp;&amp;&nbsp;
            <Link to={ROUTES.COOKIE}>{t('common.cookie')}</Link>.
          </Agreement>

          <Button disabled={isFetching || !agreed} type="submit" secondary>
            {isFetching && <FontAwesomeIcon icon={faCog} spin />}
            {!isFetching && t('signup.text')}
          </Button>
        </FormContainer>
      )}
    />
  )
}

export default SignupForm
