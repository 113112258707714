import React from 'react'

const Logo: React.FC = () => (
  <svg width='34' height='50'>
    <path
      d='M7.544 15.326v3.294H0v-3.294C0 6.896 7.008 0 15.573 0h2.854C26.992 0 34 6.897 34 15.326v3.294h-7.544v-3.294c0-4.357-3.602-7.901-8.03-7.901h-2.853c-4.427 0-8.029 3.544-8.029 7.901zM26.456 31.38H34v3.294C34 43.104 26.992 50 18.427 50h-2.854C7.008 50 0 43.103 0 34.674V31.38h7.544v3.294c0 4.356 3.602 7.901 8.03 7.901h2.853c4.427 0 8.029-3.545 8.029-7.901V31.38z'
      fill='#B6202B'
      fillRule='evenodd'
    />
  </svg>
)

export default Logo
