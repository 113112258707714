import styled from 'styled-components'

import colors from '../../../../shared/style/colors'
import font from '../../../../shared/style/font'

export default styled.div`
  color: ${colors.WHITE};
  font-family: ${font.family};
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
`
