import React from 'react'

import Container from './Container'
import Header from './Header'
import Title from './Title'
import CategorySelector, {Option} from '../CategorySelector'
import Body from './Body'

export interface CategorySelectorProps {
  categories?: string[]
  categoryOptions?: Option[]
  onCategoryChange?: (category: string) => void
  selectedCategory?: string
}

interface Props extends CategorySelectorProps {
  title: string
}

const Card: React.FC<Props> = ({
  children,
  title,
  categories,
  categoryOptions,
  selectedCategory,
  onCategoryChange,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Header>
        <Title>{title}</Title>
        {onCategoryChange && (
          <CategorySelector
            categories={categories}
            categoryOptions={categoryOptions}
            selected={selectedCategory}
            onChange={onCategoryChange}
          />
        )}
      </Header>
      <Body>{children}</Body>
    </Container>
  )
}

export default Card
