import {useCallback, useReducer} from 'react'
import {default as axios, AxiosRequestConfig, AxiosError, AxiosResponse} from 'axios'

import {initialState, reducer, State} from './reducer'

interface Thunk {
  fail?: (err: AxiosError) => void
  success?: (res: AxiosResponse) => void
}

export default (
  config: AxiosRequestConfig,
  thunk?: Thunk
): [State, (config?: AxiosRequestConfig) => void] => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const call = useCallback(
    async (additionalConfig?: AxiosRequestConfig) => {
      try {
        dispatch({type: 'CALL'})
        const response = await axios({
          ...config,
          ...additionalConfig
        })
        dispatch({type: 'SUCCESS', payload: response.data})
        if (thunk && thunk.success) thunk.success(response)
      } catch (e) {
        if (e.message !== 'CANCEL') {
          dispatch({type: 'FAIL', payload: e})
          if (thunk && thunk.fail) thunk.fail(e)
        }
      }
    },
    [dispatch, config, thunk]
  )

  return [state, call]
}
