import React, {useCallback, useEffect, useState} from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {useTranslation} from 'react-i18next'

import useAxios from '../../shared/useFetch'
import ErrorComponent from '../../shared/components/Error/Public'
import {useAuthContext} from '../../shared/auth/Provider'
import {Horeca, Key, Device, Product, ProductGroup, Role} from '../../types'
import CardsContainer from '../Horeca/CardsContainer'
import InfoCard from './Info'
import TeamCard from './Team'
import GroupCard from './Group'
import ProductCard from './Product'
import DeviceCard from './Device'
import KeyCard from './Key'

const Config: React.FC<
  RouteComponentProps<{
    horecaId?: string
  }>
> = ({match}) => {
  const {t} = useTranslation()
  const {user} = useAuthContext()

  const isTechnic = user && user.roles && user.roles.find(r => r.name === 'Technic')
  const isManager = user && user.roles && user.roles.find(r => r.name === 'Manager')
  const [horecaInfo, setHorecaInfo] = useState<Horeca>()
  const [roles, setRoles] = useState<Role[]>([])
  const [keys, setKeys] = useState<Key[]>()
  const [groups, setGroups] = useState<ProductGroup[]>()
  const [products, setProducts] = useState<Product[]>()
  const [devices, setDevices] = useState<Device[]>()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined

  const [{error: getHorecaError, isFetching: isGettingHoreca}, getHorecaCall] = useAxios(
    {
      url: `/horeca/${horecaIdParam}`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setHorecaInfo(data.horeca)
      }
    }
  )

  const [{error: getRolesError, isFetching: isGettingRoles}, getRolesCall] = useAxios(
    {
      url: `/role?horeca=${horecaIdParam}`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setRoles(data.roles)
      }
    }
  )

  const [{error: getGroupsError, isFetching: isGettingGroups}, getGroupsCall] = useAxios(
    {
      url: `/horeca/${horecaIdParam}/productgroup`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setGroups(data.groups)
      }
    }
  )

  const [{error: getProductsError, isFetching: isGettingProducts}, getProductsCall] = useAxios(
    {
      url: `/horeca/${horecaIdParam}/product`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setProducts(data.products)
      }
    }
  )

  const [{error: getDevicesError, isFetching: isGettingDevices}, getDevicesCall] = useAxios(
    {
      url: `/horeca/${horecaIdParam}/device`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setDevices(data.devices)
      }
    }
  )

  const [{error: getKeysError, isFetching: isGettingKeys}, getKeysCall] = useAxios(
    {
      url: `/horeca/${horecaIdParam}/key`,
      method: 'GET'
    },
    {
      success: ({data}) => {
        setKeys(data.keys)
      }
    }
  )

  useEffect(() => {
    if (horecaIdParam) {
      getRolesCall()
      getHorecaCall()
      getGroupsCall()
      getProductsCall()
      getDevicesCall()
      getKeysCall()
    }
  }, [horecaIdParam])

  const onRevokeRole = useCallback(
    (role: Role) => {
      if (roles) setRoles(roles.filter((r: Role) => r.id !== role.id))
    },
    [setRoles, roles]
  )

  const onGroupDelete = useCallback(
    (group: ProductGroup) => {
      if (groups) setGroups(groups.filter(g => g.id !== group.id))
    },
    [groups, setGroups]
  )

  const onGroupCreate = useCallback(
    (group: ProductGroup) => {
      if (groups) setGroups([group, ...groups])
    },
    [groups, setGroups]
  )
  const onGroupUpdate = useCallback(
    (group: ProductGroup) => {
      if (groups) {
        const index = groups.findIndex(g => g.id === group.id)
        groups[index] = group
        setGroups(groups)
      }
    },
    [groups, setGroups]
  )

  const onProductDelete = useCallback(
    (product: Product) => {
      if (products) setProducts(products.filter(p => p.id !== product.id))
    },
    [products, setProducts]
  )
  const onProductCreate = useCallback(
    (product: Product) => {
      if (products) setProducts([product, ...products])
    },
    [products, setProducts]
  )
  const onProductUpdate = useCallback(
    (product: Product) => {
      if (products) {
        const index = products.findIndex(p => p.id === product.id)
        products[index] = product
        setProducts(products)
      }
    },
    [products, setProducts]
  )

  const onDeviceCreate = useCallback(
    (device: Device) => {
      if (devices) setDevices([device, ...devices])
    },
    [devices, setDevices]
  )
  const onDeviceUpdate = useCallback(
    (device: Device) => {
      if (devices) {
        const index = devices.findIndex(d => d.id === device.id)
        devices[index] = device
        setDevices(devices)
      }
    },
    [devices, setDevices]
  )

  const onKeyDelete = useCallback(
    (key: Key) => {
      if (keys) setKeys(keys.filter(k => k.id !== key.id))
    },
    [keys, setKeys]
  )
  const onKeyCreate = useCallback(
    (key: Key) => {
      if (keys) setKeys([key, ...keys])
    },
    [keys, setKeys]
  )
  const onKeyUpdate = useCallback(
    (key: Key) => {
      if (keys) {
        const index = keys.findIndex(k => k.id === key.id)
        keys[index] = key
        setKeys(keys)
      }
    },
    [keys, setKeys]
  )

  if (!isManager && !isTechnic) return <ErrorComponent>{t('common.error.403')}</ErrorComponent>

  return (
    <>
      <CardsContainer>
        <InfoCard
          onUpdate={setHorecaInfo}
          isGetting={isGettingHoreca}
          getError={getHorecaError}
          horeca={horecaInfo}
        />
        {!isTechnic && (
          <TeamCard
            onRevoke={onRevokeRole}
            getError={getRolesError}
            isGetting={isGettingRoles}
            roles={roles}
            horecaId={horecaIdParam}
          />
        )}
        <GroupCard
          horeca={horecaInfo}
          groups={groups}
          isGetting={isGettingGroups}
          getError={getGroupsError}
          onCreate={onGroupCreate}
          onUpdate={onGroupUpdate}
          onDelete={onGroupDelete}
        />
        <ProductCard
          horeca={horecaInfo}
          groups={groups}
          products={products}
          isGetting={isGettingProducts}
          getError={getProductsError}
          onCreate={onProductCreate}
          onUpdate={onProductUpdate}
          onDelete={onProductDelete}
        />
        <DeviceCard
          horeca={horecaInfo}
          products={products}
          groups={groups}
          devices={devices}
          onCreate={onDeviceCreate}
          onUpdate={onDeviceUpdate}
          isGetting={isGettingDevices}
          getError={getDevicesError}
        />
        <KeyCard
          horeca={horecaInfo}
          roles={roles}
          keys={keys}
          onCreate={onKeyCreate}
          onUpdate={onKeyUpdate}
          onDelete={onKeyDelete}
          isGetting={isGettingKeys}
          getError={getKeysError}
        />
      </CardsContainer>
    </>
  )
}

export default withRouter(Config)
