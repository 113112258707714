import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../style/colors'
import media from '../../style/media'

export default styled.div`
  background: ${colors.WHITE};
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 ${transparentize(0.5, colors.BLACK)};
  min-width: 18.75rem;
  padding: 0.625rem;
  width: calc(100% - 1.25rem);
  max-width: 37.5rem;
  
  ${media.lessThan('M')`
    margin-bottom: 1.5rem;
  `}
`
