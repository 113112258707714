import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../../shared/style/colors'

export default styled.div`
  background: ${colors.WHITE};
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 ${transparentize(0.5, colors.BLACK)};
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
`
