export default {
  DARK: '#262626', // MINES_SHAFT
  PRIMARY: '#b6202b', // CARDINAL
  PRIMARY_DARK: '#7f161e', // DARK_CARDINAL
  PRIMARY_DARKEST: '#640A11', // DARK_TAN
  WHITE: '#FFF',
  BLACK: '#000',
  GREY: '#CBCACA',
  GREY_DARK: '#BCBCBC', //DARK_GREY
  LINK_BLUE: '#0000FF',
  SUCCESS: '#59b567'
}
