import React, {createContext, useCallback, useContext, useState} from 'react'
import {useCookies} from 'react-cookie'
import {default as dayjs, Dayjs} from 'dayjs'
import ReactGA from 'react-ga'

import {getToday, getWeek, getYesterday} from './index'

interface State {
  compareFrom?: Dayjs
  compareTo?: Dayjs
  from: Dayjs
  option: number
  to: Dayjs
}

export interface Context extends State {
  selectYesterday: () => void
  selectToday: () => void
  selectWeek: () => void
  selectFilter: () => void
  selectFilterFrom: (date: Date | null) => void
  selectFilterTo: (date: Date | null) => void
}

const TimeContext = createContext<Context | null>(null)

export const useTimeContext = () => {
  const store = useContext(TimeContext)
  if (!store)
    throw new Error('Can not use `useTimeContext` outside of a `TimeContext`')
  return store
}

export const Provider = (props: any) => {
  const [cookies, setCookie] = useCookies(['DT', 'DTS', 'DTE'])
  const timeCookie = parseInt(cookies.DT)
  let initialState: State = getYesterday()
  if (timeCookie === 1) {
    initialState = getToday()
  } else if (timeCookie === 2) {
    initialState = getWeek()
  } else if (timeCookie === 3) {
    initialState = {
      from: dayjs(cookies.DTS),
      option: 3,
      to: dayjs(cookies.DTE)
    }
  }

  const [state, setState] = useState<State>(initialState)
  const from = state.from
  const to = state.to

  const selectYesterday = useCallback(() => {
    setState(getYesterday())
    setCookie('DT', 0, {path: '/'})
    ReactGA.event({
      category: 'Time Selector',
      action: 'Select',
      label: 'Time selection',
      value: 0
    })
  }, [setState, setCookie])

  const selectToday = useCallback(() => {
    setState(getToday())
    setCookie('DT', 1, {path: '/'})
    ReactGA.event({
      category: 'Time Selector',
      action: 'Select',
      label: 'Time selection',
      value: 1
    })
  }, [setState, setCookie])

  const selectWeek = useCallback(() => {
    setState(getWeek())
    setCookie('DT', 2, {path: '/'})
    ReactGA.event({
      category: 'Time Selector',
      action: 'Select',
      label: 'Time selection',
      value: 2
    })
  }, [setState, setCookie])

  const selectFilter = useCallback(() => {
    setState({
      from,
      option: 3,
      to: to.isSameOrAfter(dayjs()) ? dayjs().endOf('day') : to
    })
    setCookie('DT', 3, {path: '/'})
    setCookie('DTS', from.toISOString(), {path: '/'})
    setCookie('DTE', to.toISOString(), {path: '/'})
    ReactGA.event({
      category: 'Time Selector',
      action: 'Select',
      label: 'Time selection',
      value: 3
    })
  }, [setState, from, to, setCookie])

  const selectFilterFrom = useCallback((date: Date | null) => {
    const from = date ? dayjs(date) : dayjs()
    let isOutOfRange = to && (from.isSameOrAfter(to) || from.add(7, 'day').isSameOrBefore(to))
    let toDate = isOutOfRange ? from.add(7, 'day').endOf('day') : to
    if (toDate.isSameOrAfter(dayjs())) toDate = dayjs()

    setState({
      from,
      option: 3,
      to: toDate
    })
    setCookie('DTS', from.toISOString(), {path: '/'})
    setCookie('DTE', toDate.toISOString(), {path: '/'})
  }, [setState, to, setCookie])

  const selectFilterTo = useCallback((date: Date) => {
    const to = date ? dayjs(date) : dayjs()
    setState({
      from,
      option: 3,
      to
    })
    setCookie('DTS', from.toISOString(), {path: '/'})
    setCookie('DTE', to.toISOString(), {path: '/'})
  }, [setState, from, setCookie])

  const value: Context = {
    ...state,
    selectYesterday,
    selectToday,
    selectWeek,
    selectFilter,
    selectFilterFrom,
    selectFilterTo
  }

  return <TimeContext.Provider value={value} {...props} />
}
