import React, {useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/pro-regular-svg-icons'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'

import {Horeca, Key, Role} from '../../../types'
import Card from '../../../shared/components/Card'
import EmptyState from '../../../shared/components/EmptyState'
import useAxios from '../../../shared/useFetch'
import colors from '../../../shared/style/colors'
import Search from '../../../shared/components/Search'
import Switch from '../Switch'
import IconButton from '../IconButton'
import Form from './Form'
import List from './List'
import Loading from '../../../shared/components/Loading'
import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'

interface Props {
  keys?: Key[]
  horeca?: Horeca
  onCreate: (key: Key) => void
  onDelete: (key: Key) => void
  onUpdate: (key: Key) => void
  roles?: Role[]
  isGetting: boolean
  getError?: AxiosError
}

const ProductCard: React.FC<Props> = ({
  keys,
  horeca,
  onCreate,
  onDelete,
  onUpdate,
  roles,
  isGetting,
  getError
}) => {
  const {t} = useTranslation()
  const emptyInitialValues = {name: ''}
  const [initialValues, setInitialValues] = useState()
  const [searchKeyword, setSearchKeyword] = useState()

  const data =
    searchKeyword && keys
      ? keys.filter((key: Key) => {
          const keywordLowerCase = searchKeyword.toLowerCase()
          return (
            (key.name && key.name.toLowerCase().includes(keywordLowerCase)) ||
            (key.owner &&
              `${key.owner.owner.firstname} ${key.owner.owner.lastname}`
                .toLowerCase()
                .includes(keywordLowerCase)) ||
            (key.owner && key.owner.name.toLowerCase().includes(keywordLowerCase))
          )
        })
      : keys

  const [{error: updateError, isFetching: isUpdating}, updateCall] = useAxios(
    {
      url: '/horeca/:horecaId/key/:keyId',
      method: 'PATCH'
    },
    {
      success: ({data: {key}}) => {
        onUpdate(key)
        setInitialValues(undefined)
      }
    }
  )

  const [{error: createError, isFetching: isCreating}, createCall] = useAxios(
    {
      url: '/horeca/:horecaId/key',
      method: 'POST'
    },
    {
      success: ({data: {key}}) => {
        onCreate(key)
        setInitialValues(undefined)
      }
    }
  )

  const [{isFetching: isDeleting}, deleteCall] = useAxios(
    {
      url: '/horeca/:horecaId/key/:keyId',
      method: 'DELETE'
    },
    {
      success: ({data: {key}}) => {
        onDelete(key)
      },
      fail: () => {
        alert(t('config.key.delete.fail'))
      }
    }
  )

  const columns = useMemo(() => {
    return [
      {
        Header: t('config.key.column.identifier'),
        accessor: 'identifier'
      },
      {
        Header: t('config.key.column.name'),
        Cell: ({row: {original}}: any) =>
          original.owner
            ? `${original.owner.owner.firstname} ${original.owner.owner.lastname} [${original.owner.name}]`
            : original.name
      },
      {
        Header: t('config.key.column.actions'),
        Cell: ({row: {original}}: any) => {
          return (
            <>
              <IconButton
                data-tip={t('config.key.edit')}
                type="button"
                onClick={() => {
                  setInitialValues(
                    original.owner
                      ? {
                          id: original.id,
                          name: original.name,
                          identifier: original.identifier,
                          owner: original.owner.id
                        }
                      : {
                          id: original.id,
                          identifier: original.identifier,
                          name: original.name
                        }
                  )
                }}
              >
                <FontAwesomeIcon icon={faEdit} color={colors.PRIMARY} />
              </IconButton>
              <IconButton
                data-tip={t('config.key.delete.tooltip')}
                disabled={isDeleting}
                type="button"
                onClick={() => {
                  if (horeca && confirm(t('config.key.delete.confirm'))) {
                    deleteCall({
                      url: `/horeca/${horeca.id}/key/${original.id}`
                    })
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} color={colors.PRIMARY} />
              </IconButton>
            </>
          )
        }
      }
    ]
  }, [isDeleting, deleteCall, setInitialValues, horeca])

  return (
    <Card title={t('config.key.title')}>
      {isGetting && <Loading text={t('config.loading.key')} />}
      {!isGetting && getError && (
        <Error component={ErrorComponent} error={getError} converters={[]} />
      )}
      {!isGetting && horeca && initialValues && (
        <Form
          roles={roles}
          isFetching={initialValues.id ? isUpdating : isCreating}
          error={initialValues.id ? updateError : createError}
          cancel={() => setInitialValues(undefined)}
          initialValues={initialValues}
          onSubmit={values => {
            initialValues.id
              ? updateCall({
                  data: values,
                  url: `/horeca/${horeca.id}/key/${initialValues.id}`
                })
              : createCall({
                  data: values,
                  url: `/horeca/${horeca.id}/key`
                })
          }}
        />
      )}
      {!isGetting && !initialValues && (
        <>
          <Switch
            onClick={() => {
              setInitialValues(emptyInitialValues)
            }}
            text={t('config.key.add')}
          />
          {keys && keys.length > 0 && (
            <Search onSearch={setSearchKeyword} placeholder={t('config.key.search')} />
          )}
          {data && data.length > 0 && <List data={data} columns={columns} />}
          {data && data.length === 0 && <EmptyState text={t('config.key.empty')} />}
        </>
      )}
    </Card>
  )
}

export default ProductCard
