import styled from 'styled-components'

import media from '../../style/media'

export const Row = styled.div`
  display: flex;
  justify-content: center;
  
  ${media.lessThan('M')`
    justify-content: space-between;
  `}
`

export const TimeRow = styled(Row)`
  padding-bottom: 0.75rem;
  
  ${media.lessThan('M')`
    justify-content: center;
  `}
`
