import dayjs from 'dayjs'
import randomColor from 'randomcolor'

import {CaveEvent} from '../../types'

interface LineValue {
  x: Date
  y: number
}

export interface GraphLine {
  color: string
  dataPoints: LineValue[]
  legendText: string
  showInLegend: boolean
  type: string
}

type Measurements = [GraphLine[], GraphLine[]]

const colors = [
  '#e9b44c',
  '#b6202b',
  '#1c110a',
  '#94c9a9',
  '#434de4',
  '#50A2A7',
  '#581908',
  '#d0ffce',
  '#a7ff45',
  '#7A6F9B'
]

export const measurementNormalizer = (data: CaveEvent[], device): Measurements => {
  const keys = device ? Object.keys(device.config) : []
  const initial = keys.reduce(
    (t, k) => {
      if (k.startsWith('temp')) {
        const lineNumber = k.match(/[0-9]+/)[0] || 1
        t[0].push({
          color: colors[lineNumber - 1],
          dataPoints: [],
          legendText: device.config[k],
          showInLegend: true,
          type: 'line'
        })
      } else {
        const lineNumber = k.match(/[0-9]+/)[0] || 1
        t[1].push({
          color: lineNumber > colors.length ? colors[lineNumber - 1] : randomColor(),
          dataPoints: [],
          legendText: device.config[k],
          showInLegend: true,
          type: 'line'
        })
      }
      return t
    },
    [[], []]
  )
  return data.reduce((t: Measurements, e: CaveEvent) => {
    const time = dayjs(e.datetime).toDate()

    for (let i = 0; i < 4; i++) {
      if (e[`temp${i + 1}`] !== '999.99') {
        t[0][i].dataPoints.push({
          x: time,
          y: parseFloat(e[`temp${i + 1}`])
        })
      }

      if (i < 3 && e[`press${i + 1}`] !== '999.99') {
        t[1][i].dataPoints.push({
          x: time,
          y: parseFloat(e[`press${i + 1}`])
        })
      }
    }
    return t
  }, initial)
}
