import dayjs from 'dayjs'
import randomColor from 'randomcolor'

import {VolumeEvent, TranslateFunction, EventV2, VolumeEventV2, WasteEventV2} from '../../types'
import colors from '../../shared/style/colors'
import {ComparisonData} from './ComparisonBoxes'
import {CATEGORIES, Category, GRAPH_COLORS} from '../../constants'

interface CoffeeQuantity {
  label: string
  revenue: number
  y: number
  color: string
}

interface Activity {
  x: Date
  y: number
  revenue: number
  c: string
}

interface Response {
  activityOverTime: any[]
  availableCategories: string[]
  coffeeActivity: Activity[]
  coffeeQuantity: CoffeeQuantity[]
  coffeeWaste: any[]
  bottlesActivity: Activity[]
  bottlesQuantity: CoffeeQuantity[]
  comparisons: ComparisonData[]
  psr: any
  revenue: string
  volumes: any[]
  wastes: any[]
}

const getTotalVolume = (events: VolumeEvent[], useUnit?: boolean): number => {
  const total = events.reduce((t: number, e: VolumeEvent) => t + e.volume, 0)
  if (useUnit) return total
  // converts ml to L
  return parseFloat((total / 1000).toFixed(2))
}

const getTotalVolumeV2 = (events: EventV2[], useUnit?: boolean): number => {
  const total = events.reduce((t: number, e: EventV2) => {
    return t + (useUnit ? e.quantity : e.volume * e.quantity)
  }, 0)
  if (useUnit) return total
  // converts ml to L
  return parseFloat((total / 1000).toFixed(2))
}

const getTotalRevenue = (events: VolumeEvent[]): number => {
  return parseFloat(
    events
      .reduce((t: number, e: any) => {
        if (typeof e.product === 'string') return t
        if (e.product) {
          if (['Coffee', 'Bottles'].includes(e.product.category)) {
            return t + e.product.price
          }
          return t + parseFloat(((e.volume / e.product.portion) * e.product.price).toFixed(2))
        }
        return t + 0
      }, 0)
      .toFixed(2)
  )
}

const getTotalRevenueV2 = (events: VolumeEventV2[]): number => {
  return parseFloat(
    events
      .reduce((t: number, e: VolumeEventV2) => {
        if (e.product) {
          if (['Coffee', 'Bottles'].includes(e.product.category)) {
            return t + e.product.price * e.quantity
          }
          return (
            t +
            parseFloat((((e.volume * e.quantity) / e.product.portion) * e.product.price).toFixed(2))
          )
        }
        return t + 0
      }, 0)
      .toFixed(2)
  )
}

interface VolumesGroupedByCategory {
  [category: string]: VolumeEvent[]
}

const groupVolumesByCategory = (events: VolumeEvent[]): VolumesGroupedByCategory => {
  return events.reduce((t: VolumesGroupedByCategory, e: VolumeEvent) => {
    let category = ''
    if (typeof e.product === 'string') {
      category = e.category || ''
    } else if (e.product && e.product.category) category = e.product.category

    if (category in t) {
      t[category].push(e)
    } else {
      t[category] = [e]
    }

    return t
  }, {})
}

interface VolumesGroupedByCategoryV2 {
  [category: string]: VolumeEventV2[]
}

const groupVolumesByCategoryV2 = (events: VolumeEventV2[]): VolumesGroupedByCategoryV2 => {
  return events.reduce((t: VolumesGroupedByCategoryV2, e: VolumeEventV2) => {
    if (e.product) {
      if (e.product.category in t) {
        t[e.product.category].push(e)
      } else {
        t[e.product.category] = [e]
      }
    }
    return t
  }, {})
}

interface WasteGroupedByCategoryV2 {
  [category: string]: WasteEventV2[]
}

const groupWasteByCategoryV2 = (events: WasteEventV2[]): WasteGroupedByCategoryV2 => {
  return events.reduce((t: WasteGroupedByCategoryV2, e: WasteEventV2) => {
    if (e.category) {
      if (e.category in t) {
        t[e.category].push(e)
      } else {
        t[e.category] = [e]
      }
    }
    return t
  }, {})
}

interface VolumesGroupedByInterval {
  [date: string]: Activity
}

const groupByTimeInterval = (events: VolumeEvent[], interval: string): VolumesGroupedByInterval => {
  return events.reduce((t: any, e: VolumeEvent) => {
    let key
    if (interval === '15m') {
      const q = Math.floor(dayjs(e.evt_datetime).minute() / 15)
      key = dayjs(e.evt_datetime)
        .minute(q * 15)
        .second(0)
        .millisecond(0)
        .toString()
    } else if (interval === '30m') {
      const q = Math.floor(dayjs(e.evt_datetime).minute() / 30)
      key = dayjs(e.evt_datetime)
        .minute(q * 30)
        .second(0)
        .millisecond(0)
        .toString()
    } else if (interval === '1h') {
      key = dayjs(e.evt_datetime)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toString()
    } else {
      key = dayjs(e.evt_datetime)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toString()
    }

    if (!t[key]) {
      t[key] = {
        x: dayjs(key).toDate(),
        y: 0,
        revenue: 0
      }
    }
    t[key].y += e.volume
    t[key].revenue += e.product && typeof e.product !== 'string' ? e.product.price : 0
    t[key].c = e.product ? (typeof e.product === 'string' ? e.category : e.product.category) : ''

    return t
  }, {})
}

interface VolumesGroupedByIntervalV2 {
  [date: string]: Activity
}

const groupByTimeIntervalV2 = (
  events: VolumeEventV2[],
  interval: string
): VolumesGroupedByIntervalV2 => {
  return events.reduce((t: any, e: VolumeEventV2) => {
    let key
    if (interval === '15m') {
      const q = Math.floor(dayjs(e.datetime).minute() / 15)
      key = dayjs(e.datetime)
        .minute(q * 15)
        .second(0)
        .millisecond(0)
        .toString()
    } else if (interval === '30m') {
      const q = Math.floor(dayjs(e.datetime).minute() / 30)
      key = dayjs(e.datetime)
        .minute(q * 30)
        .second(0)
        .millisecond(0)
        .toString()
    } else if (interval === '1h') {
      key = dayjs(e.datetime)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toString()
    } else {
      key = dayjs(e.datetime)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toString()
    }
    if (!t[key]) t[key] = {x: dayjs(key).toDate(), y: 0, revenue: 0}
    t[key].y +=
      e.product && ['Coffee', 'Bottles'].includes(e.product.category)
        ? e.quantity
        : e.volume * e.quantity
    t[key].revenue += e.product
      ? ['Coffee', 'Bottles'].includes(e.product.category)
        ? e.product.price * e.quantity
        : parseFloat((((e.volume * e.quantity) / e.product.portion) * e.product.price).toFixed(2))
      : 0
    t[key].c = e.product ? e.product.category : ''
    return t
  }, {})
}

export const volumesNormalizer = (
  volumes: VolumeEvent[],
  compare: VolumeEvent[],
  waste: VolumeEvent[],
  currency: string,
  selectedInterval: string,
  category: string,
  timeFormat: string,
  t: TranslateFunction
): Response => {
  const totalSoldVolume = getTotalVolume(
    volumes.filter((e: VolumeEvent) => {
      if (!e.product) {
        return false
      }
      const category = typeof e.product === 'string' ? e.product : e.product.category
      return ['Water', 'Coffee', 'Bottles'].indexOf(category) === -1
    })
  )
  const totalWasteVolume = getTotalVolume(
    waste.filter((e: VolumeEvent) => {
      if (!e.product) {
        return false
      }
      const category = typeof e.product === 'string' ? e.product : e.product.category
      return ['Water', 'Coffee', 'Bottles'].indexOf(category) === -1
    })
  )
  const totalVolume = totalSoldVolume + totalWasteVolume
  const psr = [
    {
      color: colors.PRIMARY,
      exploded: true,
      indexLabel: t('volume.psr.poured'),
      indexLabelFontColor: colors.WHITE,
      indexLabelFontSize: 16,
      name: t('volume.psr.poured'),
      volume: totalWasteVolume,
      y: totalVolume === 0 ? 0 : (totalWasteVolume / totalVolume) * 100
    },
    {
      color: '#e9b44c',
      indexLabel: t('volume.psr.sold'),
      indexLabelFontColor: colors.WHITE,
      indexLabelFontSize: 16,
      name: t('volume.psr.sold'),
      volume: totalSoldVolume,
      y: totalVolume === 0 ? 0 : (totalSoldVolume / totalVolume) * 100
    }
  ]
  const revenue = getTotalRevenue(volumes)
  const volumesByCategory = groupVolumesByCategory(volumes)
  const compareByCategory = groupVolumesByCategory(compare)
  const wasteByCategory = groupVolumesByCategory(waste)
  const comparisons = CATEGORIES.filter((category: Category) => {
    return category.name in volumesByCategory || category.name in compareByCategory
  }).map((category: Category) => {
    const currentVolume = volumesByCategory[category.name]
      ? getTotalVolume(
          volumesByCategory[category.name],
          ['Coffee', 'Bottles'].includes(category.name)
        )
      : 0
    const currentRevenue = volumesByCategory[category.name]
      ? getTotalRevenue(volumesByCategory[category.name])
      : 0

    const compareVolume = compareByCategory[category.name]
      ? getTotalVolume(
          compareByCategory[category.name],
          ['Coffee', 'Bottles'].includes(category.name)
        )
      : 0
    const compareRevenue = compareByCategory[category.name]
      ? getTotalRevenue(compareByCategory[category.name])
      : 0

    return {
      category: t(category.i18nKey),
      compare: {
        volume: ['Coffee', 'Bottles'].includes(category.name)
          ? `${compareVolume} Units`
          : `${compareVolume} L`,
        revenue: compareRevenue
      },
      current: {
        volume: ['Coffee', 'Bottles'].includes(category.name)
          ? `${currentVolume} Units`
          : `${currentVolume} L`,
        revenue: currentRevenue
      }
    }
  })

  const coffeeGroupedByProduct = volumesByCategory['Coffee']
    ? volumesByCategory['Coffee'].reduce((t: any, e: VolumeEvent) => {
        if (e.product) {
          const key = typeof e.product === 'string' ? e.product : e.product.id
          if (key in t) {
            t[key].push(e)
          } else {
            t[key] = [e]
          }
        }
        return t
      }, {})
    : {}

  const coffeeQuantity = Object.values(coffeeGroupedByProduct)
    .map((events: VolumeEvent[]) => {
      const name =
        events[0] && events[0].product
          ? typeof events[0].product === 'string'
            ? events[0].product
            : events[0].product.name
          : ''

      return {
        label: name,
        revenue: getTotalRevenue(events),
        y: getTotalVolume(events, true),
        color: GRAPH_COLORS.Coffee
      }
    })
    .sort((a: any, b: any) => b.y - a.y)

  const coffeeActivity = volumesByCategory['Coffee']
    ? Object.values(groupByTimeInterval(volumesByCategory['Coffee'], selectedInterval))
    : []

  const coffeeWasteGroupedByProduct = wasteByCategory['Coffee']
    ? wasteByCategory['Coffee'].reduce((t: any, e: VolumeEvent) => {
        if (e.product) {
          const key = typeof e.product === 'string' ? e.product : e.product.id
          if (key in t) {
            t[key].push(e)
          } else {
            t[key] = [e]
          }
        }
        return t
      }, {})
    : {}
  const coffeeWasteColorsByProduct = randomColor({
    count: Object.keys(coffeeWasteGroupedByProduct).length,
    hue: GRAPH_COLORS.Coffee
  })

  const coffeeWaste = Object.values(coffeeWasteGroupedByProduct).map(
    (events: VolumeEvent[], i: number) => {
      const name =
        events[0] && events[0].product
          ? typeof events[0].product === 'string'
            ? events[0].product
            : events[0].product.name
          : ''
      return {
        label: `${name}: ${events.length} ${t('volume.coffee.unit')}`,
        name: name,
        indexLabelFontSize: 16,
        color: coffeeWasteColorsByProduct[i],
        y: events.length / waste.length,
        volume: events.length
      }
    }
  )

  const bottlesGroupedByProduct = volumesByCategory['Bottles']
    ? volumesByCategory['Bottles'].reduce((t: any, e: VolumeEvent) => {
        if (e.product) {
          const key = typeof e.product === 'string' ? e.product : e.product.id
          if (key in t) {
            t[key].push(e)
          } else {
            t[key] = [e]
          }
        }
        return t
      }, {})
    : {}

  const bottlesQuantity = Object.values(bottlesGroupedByProduct)
    .map((events: VolumeEvent[]) => {
      const name =
        events[0] && events[0].product
          ? typeof events[0].product === 'string'
            ? events[0].product
            : events[0].product.name
          : ''

      return {
        label: name,
        revenue: getTotalRevenue(events),
        y: getTotalVolume(events, true),
        color: GRAPH_COLORS.Bottles
      }
    })
    .sort((a: any, b: any) => b.y - a.y)

  const bottlesActivity = volumesByCategory['Bottles']
    ? Object.values(groupByTimeInterval(volumesByCategory['Bottles'], selectedInterval))
    : []

  const nonCoffeeVolumesByCategory = {...volumesByCategory}
  const nonCoffeeWasteByCategory = {...wasteByCategory}
  delete nonCoffeeVolumesByCategory['Coffee']
  delete nonCoffeeVolumesByCategory['Bottles']
  delete nonCoffeeVolumesByCategory['']
  delete nonCoffeeWasteByCategory['Coffee']
  delete nonCoffeeWasteByCategory['Water']
  delete nonCoffeeWasteByCategory['Bottles']
  delete nonCoffeeWasteByCategory['']

  let nonCoffeeVolumesData, nonCoffeeWasteData: any[]
  if (category === 'Summary') {
    nonCoffeeVolumesData = Object.values(nonCoffeeVolumesByCategory)
      .map((events: VolumeEvent[]) => {
        const key =
          events[0] && events[0].product
            ? typeof events[0].product === 'string'
              ? events[0].category
                ? events[0].category
                : ''
              : events[0].product.category
            : ''

        return {
          label: key,
          revenue: getTotalRevenue(events),
          y: getTotalVolume(events),
          color: GRAPH_COLORS[key] || '#000'
        }
      })
      .sort((a: any, b: any) => b.y - a.y)
    nonCoffeeWasteData = Object.values(nonCoffeeWasteByCategory).map((events: VolumeEvent[]) => {
      const volume = getTotalVolume(events)
      const key =
        events[0] && events[0].product
          ? typeof events[0].product === 'string'
            ? events[0].category
              ? events[0].category
              : ''
            : events[0].product.category
          : ''
      return {
        label: `${key}: ${volume} L`,
        name: key,
        indexLabelFontSize: 16,
        color: GRAPH_COLORS[key] || '#000',
        y: volume / totalWasteVolume,
        volume
      }
    })
  } else {
    const productsGrouped = nonCoffeeVolumesByCategory[category]
      ? nonCoffeeVolumesByCategory[category].reduce((t: any, e: VolumeEvent) => {
          if (e.product) {
            let key
            if (typeof e.product === 'string') {
              key = e.product
            } else {
              if (e.product.group) {
                key = `g-${e.product.group.id}`
              } else {
                key = e.product.id
              }
            }
            if (key in t) {
              t[key].push(e)
            } else {
              t[key] = [e]
            }
          }
          return t
        }, {})
      : {}

    nonCoffeeVolumesData = Object.values(productsGrouped)
      .map((events: VolumeEvent[]) => {
        const label =
          events[0] && events[0].product
            ? typeof events[0].product === 'string'
              ? events[0].product
              : events[0].product.group
              ? events[0].product.group.name
              : events[0].product.name
            : ''
        return {
          label,
          revenue: getTotalRevenue(events),
          y: getTotalVolume(events),
          color: GRAPH_COLORS[category],
          count: events.length,
          portion:
            events[0].product && typeof events[0].product !== 'string'
              ? events[0].product.portion
              : 0
        }
      })
      .sort((a: any, b: any) => b.y - a.y)

    if (nonCoffeeWasteByCategory[category] && nonCoffeeWasteByCategory[category].length > 0) {
      const nonCoffeeWasteVolumeForCategory = getTotalVolume(nonCoffeeWasteByCategory[category])
      const nonCoffeeWasteByProductForCategory = nonCoffeeWasteByCategory[category].reduce(
        (t: any, e: VolumeEvent) => {
          if (e.product) {
            const key = typeof e.product === 'string' ? e.product : e.product.id
            if (key in t) {
              t[key].push(e)
            } else {
              t[key] = [e]
            }
          }
          return t
        },
        {}
      )

      const colorsForWasteProduct = randomColor({
        count: Object.keys(nonCoffeeWasteByProductForCategory).length,
        hue: GRAPH_COLORS[category]
      })

      nonCoffeeWasteData = Object.values(nonCoffeeWasteByProductForCategory).map(
        (events: VolumeEvent[], i: number) => {
          const volume = getTotalVolume(events)
          const key =
            events[0] && events[0].product
              ? typeof events[0].product === 'string'
                ? events[0].category
                  ? events[0].category
                  : ''
                : events[0].product.category
              : ''
          return {
            label: `${key}: ${volume} L`,
            name: key,
            indexLabelFontSize: 16,
            color: colorsForWasteProduct[i],
            y: volume / nonCoffeeWasteVolumeForCategory,
            volume
          }
        }
      )
    } else {
      nonCoffeeWasteData = []
    }
  }

  let activityOverTime = Object.values(nonCoffeeVolumesByCategory).map((events: VolumeEvent[]) => {
    const cat =
      events[0] && events[0].product
        ? typeof events[0].product === 'string'
          ? events[0].category
            ? events[0].category
            : ''
          : events[0].product.category
        : ''

    return {
      type: category === 'Summary' ? 'stackedColumn' : 'column',
      color: GRAPH_COLORS[cat],
      xValueFormatString: timeFormat,
      showInLegend: true,
      legendText: cat,
      dataPoints: Object.values(groupByTimeInterval(events, selectedInterval))
    }
  })
  if (category !== 'Summary') {
    activityOverTime = activityOverTime.filter(g => g.legendText === category)
  }

  return {
    activityOverTime,
    availableCategories: Object.keys(volumesByCategory),
    volumes: nonCoffeeVolumesData,
    coffeeActivity,
    coffeeQuantity,
    coffeeWaste,
    comparisons,
    psr,
    revenue: `${revenue} ${currency}`,
    wastes: nonCoffeeWasteData,
    bottlesActivity,
    bottlesQuantity
  }
}

export const volumesNormalizerV2 = (
  volumes: VolumeEventV2[],
  compare: VolumeEventV2[],
  waste: WasteEventV2[],
  currency: string,
  selectedInterval: string,
  category: string,
  timeFormat: string,
  t: TranslateFunction
): Response => {
  const volumesWithProductInfo = volumes.filter(e => !!e.product)
  const compareWithProductInfo = compare.filter(e => !!e.product)
  const wasteWithProductInfo = waste.filter(e => !!e.name)
  const totalSoldVolume = getTotalVolumeV2(
    volumesWithProductInfo.filter(e => {
      return e.product && !['Water', 'Coffee', 'Bottles'].includes(e.product.category)
    })
  )
  const totalWasteVolume = getTotalVolumeV2(
    wasteWithProductInfo.filter(e => {
      return e.category && !['Water', 'Coffee', 'Bottles'].includes(e.category)
    })
  )
  const psr = [
    {
      color: colors.PRIMARY,
      exploded: true,
      indexLabel: t('volume.psr.poured'),
      indexLabelFontColor: colors.WHITE,
      indexLabelFontSize: 16,
      name: t('volume.psr.poured'),
      volume: totalWasteVolume,
      y:
        totalSoldVolume + totalWasteVolume === 0
          ? 0
          : (totalWasteVolume / (totalSoldVolume + totalWasteVolume)) * 100
    },
    {
      color: '#e9b44c',
      indexLabel: t('volume.psr.sold'),
      indexLabelFontColor: colors.WHITE,
      indexLabelFontSize: 16,
      name: t('volume.psr.sold'),
      volume: totalSoldVolume,
      y:
        totalSoldVolume + totalWasteVolume === 0
          ? 0
          : (totalSoldVolume / (totalSoldVolume + totalWasteVolume)) * 100
    }
  ]
  const revenue = `${getTotalRevenueV2(volumesWithProductInfo)} ${currency}`
  const volumesByCategory = groupVolumesByCategoryV2(volumesWithProductInfo)
  const compareByCategory = groupVolumesByCategoryV2(compareWithProductInfo)
  const wasteByCategory = groupWasteByCategoryV2(wasteWithProductInfo)
  const comparisons = CATEGORIES.filter((category: Category) => {
    return category.name in volumesByCategory || category.name in compareByCategory
  }).map((category: Category) => {
    const useUnit = ['Coffee', 'Bottles'].includes(category.name)
    const currentValues = volumesByCategory[category.name]
    const currentVolume = currentValues ? getTotalVolumeV2(currentValues, useUnit) : 0
    const currentRevenue = currentValues ? getTotalRevenueV2(currentValues) : 0

    const compareValues = compareByCategory[category.name]
    const compareVolume = compareValues ? getTotalVolumeV2(compareValues, useUnit) : 0
    const compareRevenue = compareValues ? getTotalRevenueV2(compareValues) : 0

    return {
      category: t(category.i18nKey),
      compare: {
        volume: `${compareVolume} ${useUnit ? 'Units' : 'L'}`,
        revenue: compareRevenue
      },
      current: {
        volume: `${currentVolume} ${useUnit ? 'Units' : 'L'}`,
        revenue: currentRevenue
      }
    }
  })
  const availableCategories = Object.keys(volumesByCategory)

  const coffeeGroupedByProduct = volumesByCategory['Coffee']
    ? volumesByCategory['Coffee'].reduce((t: any, e: VolumeEventV2) => {
        if (e.product) {
          if (e.product.id in t) {
            t[e.product.id].push(e)
          } else {
            t[e.product.id] = [e]
          }
        }
        return t
      }, {})
    : {}
  const coffeeQuantity = Object.values(coffeeGroupedByProduct)
    .map((events: VolumeEventV2[]) => {
      const label = events[0] && events[0].product ? events[0].product.name : ''
      return {
        label,
        revenue: getTotalRevenueV2(events),
        y: getTotalVolumeV2(events, true),
        color: GRAPH_COLORS.Coffee
      }
    })
    .sort((a: any, b: any) => b.y - a.y)
  const coffeeActivity = volumesByCategory['Coffee']
    ? Object.values(groupByTimeIntervalV2(volumesByCategory['Coffee'], selectedInterval))
    : []
  const coffeeWasteGroupedByProduct = wasteByCategory['Coffee']
    ? wasteByCategory['Coffee'].reduce((t: any, e: WasteEventV2) => {
        if (e.name) {
          if (e.name in t) {
            t[e.name].push(e)
          } else {
            t[e.name] = [e]
          }
        }
        return t
      }, {})
    : {}
  const coffeeWasteColorsByProduct = randomColor({
    count: Object.keys(coffeeWasteGroupedByProduct).length,
    hue: GRAPH_COLORS.Coffee
  })
  const coffeeWaste = Object.values(coffeeWasteGroupedByProduct).map(
    (events: WasteEventV2[], i: number) => {
      const name = events[0] && events[0].name ? events[0].name : ''
      return {
        label: `${name}: ${events.length} ${t('volume.coffee.unit')}`,
        name,
        indexLabelFontSize: 16,
        color: coffeeWasteColorsByProduct[i],
        y: events.length / waste.length,
        volume: events.length
      }
    }
  )
  const bottlesGroupedByProduct = volumesByCategory['Bottles']
    ? volumesByCategory['Bottles'].reduce((t: any, e: VolumeEventV2) => {
        if (e.product) {
          if (e.product.id in t) {
            t[e.product.id].push(e)
          } else {
            t[e.product.id] = [e]
          }
        }
        return t
      }, {})
    : {}
  const bottlesQuantity = Object.values(bottlesGroupedByProduct)
    .map((events: VolumeEventV2[]) => {
      const label = events[0] && events[0].product ? events[0].product.name : ''
      return {
        label,
        revenue: getTotalRevenueV2(events),
        y: getTotalVolumeV2(events, true),
        color: GRAPH_COLORS.Bottles
      }
    })
    .sort((a: any, b: any) => b.y - a.y)
  const bottlesActivity = volumesByCategory['Bottles']
    ? Object.values(groupByTimeIntervalV2(volumesByCategory['Bottles'], selectedInterval))
    : []

  const nonCoffeeVolumesByCategory = {...volumesByCategory}
  const nonCoffeeWasteByCategory = {...wasteByCategory}
  delete nonCoffeeVolumesByCategory['Coffee']
  delete nonCoffeeVolumesByCategory['Bottles']
  delete nonCoffeeVolumesByCategory['']
  delete nonCoffeeWasteByCategory['Coffee']
  delete nonCoffeeWasteByCategory['Water']
  delete nonCoffeeWasteByCategory['Bottles']
  delete nonCoffeeWasteByCategory['']

  let nonCoffeeVolumesData, nonCoffeeWasteData: any[]
  if (category === 'Summary') {
    nonCoffeeVolumesData = Object.values(nonCoffeeVolumesByCategory)
      .map((events: VolumeEventV2[]) => {
        const label = events[0] && events[0].product ? events[0].product.category : ''
        return {
          label,
          revenue: getTotalRevenueV2(events),
          y: getTotalVolumeV2(events),
          color: GRAPH_COLORS[label]
        }
      })
      .sort((a: any, b: any) => b.y - a.y)
    nonCoffeeWasteData = Object.values(nonCoffeeWasteByCategory).map((events: WasteEventV2[]) => {
      const volume = getTotalVolumeV2(events)
      const key = events[0] && events[0].category ? events[0].category : ''
      return {
        label: `${key}: ${volume} L`,
        name: key,
        indexLabelFontSize: 16,
        color: GRAPH_COLORS[key] || '#000',
        y: volume / totalWasteVolume,
        volume
      }
    })
  } else {
    const productsGrouped = nonCoffeeVolumesByCategory[category]
      ? nonCoffeeVolumesByCategory[category].reduce((t: any, e: VolumeEventV2) => {
          if (e.product) {
            const key = e.product.group ? `g-${e.product.group.id}` : e.product.id
            if (key in t) {
              t[key].push(e)
            } else {
              t[key] = [e]
            }
          }
          return t
        }, {})
      : {}
    nonCoffeeVolumesData = Object.values(productsGrouped)
      .map((events: VolumeEventV2[]) => {
        return {
          label:
            events[0] && events[0].product
              ? events[0].product.group
                ? events[0].product.group.name
                : events[0].product.name
              : '',
          revenue: getTotalRevenueV2(events),
          y: getTotalVolumeV2(events),
          color: GRAPH_COLORS[category],
          count: events.length,
          portion: events[0] && events[0].product ? events[0].product.portion : 0
        }
      })
      .sort((a: any, b: any) => b.y - a.y)

    if (nonCoffeeWasteByCategory[category] && nonCoffeeWasteByCategory[category].length) {
      const nonCoffeeWasteVolumeForCategory = getTotalVolumeV2(nonCoffeeWasteByCategory[category])
      const nonCoffeeWasteByProductForCategory = nonCoffeeWasteByCategory[category].reduce(
        (t: any, e: WasteEventV2) => {
          if (e.name) {
            if (e.name in t) {
              t[e.name].push(e)
            } else {
              t[e.name] = [e]
            }
          }
          return t
        },
        {}
      )
      const colorsForWasteProduct = randomColor({
        count: Object.keys(nonCoffeeWasteByProductForCategory).length,
        hue: GRAPH_COLORS[category]
      })
      nonCoffeeWasteData = Object.values(nonCoffeeWasteByProductForCategory).map(
        (events: WasteEventV2[], i: number) => {
          const volume = getTotalVolumeV2(events)
          const key = events[0] && events[0].category ? events[0].category : ''
          return {
            label: `${key}: ${volume} L`,
            name: key,
            indexLabelFontSize: 16,
            color: colorsForWasteProduct[i],
            y: volume / nonCoffeeWasteVolumeForCategory,
            volume
          }
        }
      )
    } else {
      nonCoffeeWasteData = []
    }
  }
  let activityOverTime = Object.values(nonCoffeeVolumesByCategory).map(
    (events: VolumeEventV2[]) => {
      const cat = events[0] && events[0].product ? events[0].product.category : ''
      return {
        type: category === 'Summary' ? 'stackedColumn' : 'column',
        color: GRAPH_COLORS[cat],
        xValueFormatString: timeFormat,
        showInLegend: true,
        legendText: cat,
        dataPoints: Object.values(groupByTimeIntervalV2(events, selectedInterval))
      }
    }
  )
  if (category !== 'Summary')
    activityOverTime = activityOverTime.filter(g => g.legendText === category)

  return {
    psr,
    revenue,
    comparisons,
    coffeeWaste,
    coffeeQuantity,
    coffeeActivity,
    availableCategories,
    volumes: nonCoffeeVolumesData,
    wastes: nonCoffeeWasteData,
    activityOverTime,
    bottlesActivity,
    bottlesQuantity
  }
}
