import React, {useCallback, useState} from 'react'

import colors from '../../../style/colors'
import Container from './Container'

interface Props {
  icon: React.ReactElement
  selected: boolean
  onClick: () => void
}

const ItemButton: React.FC<Props> = ({icon, onClick, selected}) => {
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [setIsHovered])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [setIsHovered])

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selected={selected}
      onClick={onClick}
    >
      {isHovered && !selected ? React.cloneElement(icon, {color: colors.PRIMARY}) : icon}
    </Container>
  )
}

export default ItemButton
