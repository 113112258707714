import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/pro-regular-svg-icons'

import colors from '../../style/colors'
import Container from './Container'
import Input from './Input'
import Button from './Button'

interface Props {
  placeholder: string
  onSearch: (keyword: string) => void
}

const Search: React.FC<Props> = ({onSearch, ...rest}) => {
  const [keyword, setKeyword] = useState()

  return (
    <Container>
      <Input type='text' value={keyword} onChange={(e: any) => setKeyword(e.target.value)}  {...rest} />
      <Button onClick={() => {
        onSearch(keyword)
      }}>
        <FontAwesomeIcon icon={faSearch} color={colors.WHITE} />
      </Button>
    </Container>
  )
}

export default Search
