import React, {useEffect} from 'react'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {useCookies} from 'react-cookie'

import {useAuthContext} from '../auth/Provider'

const AxiosConfig: React.FC = () => {
  const [cookies] = useCookies(['DAT'])
  const token = cookies.DAT
  const {authenticate, logout} = useAuthContext()

  useEffect(() => {
    axios.defaults.baseURL =
      process.env.NODE_ENV === 'production'
        ? 'https://oldapi.drinkotec.net/api/v2'
        : 'http://localhost:1337/api/v2'

    const reqInt = axios.interceptors.request.use((req: AxiosRequestConfig): AxiosRequestConfig => {
      if (token && req.headers && req.url && req.url.indexOf('/public/') !== 0) {
        req.headers.Authorization = `Bearer ${token}`
      }
      return req
    }, Promise.reject)
    const resInt = axios.interceptors.response.use(
      async res => res,
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      }
    )
    return () => {
      axios.interceptors.request.eject(reqInt)
      axios.interceptors.response.eject(resInt)
    }
  }, [token, logout, authenticate])

  return null
}

export default AxiosConfig
