import styled from 'styled-components'

import colors from '../../style/colors'

export default styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.DARK};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
