import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'

import {Horeca} from '../../../types'
import Card from '../../../shared/components/Card'
import useAxios from '../../../shared/useFetch'
import ErrorComponent from '../../../shared/components/Error/Public'
import Error from '../../../shared/components/Error'
import Switch from '../Switch'
import List from './List'
import Item from './Item'
import Form from './Form'
import Loading from '../../../shared/components/Loading'

interface Props {
  horeca?: Horeca
  isGetting: boolean
  getError?: AxiosError
  onUpdate: (horeca: Horeca) => void
}

const Info: React.FC<Props> = ({onUpdate, isGetting, getError, horeca}) => {
  const {t} = useTranslation()
  const [isInEditMode, setEditMode] = useState(false)

  const toggleEditMode = useCallback(() => {
    setEditMode(mode => !mode)
  }, [setEditMode])

  const [{error: updateError, isFetching: isUpdating}, updateCall] = useAxios(
    {
      url: `/horeca/${horeca ? horeca.id : ''}`,
      method: 'PATCH'
    },
    {
      success: ({data: {horeca}}) => {
        onUpdate(horeca)
        toggleEditMode()
      }
    }
  )

  return (
    <Card title={t('config.info.title')}>
      {isGetting && <Loading text={t('config.loading.info')} />}
      {!isGetting && getError && (
        <Error
          component={ErrorComponent}
          error={getError}
          converters={[
            {
              key: 'common.notfound.horeca',
              check: (err: AxiosError) =>
                err.response ? err.response.data.name === 'NotFound' : false
            }
          ]}
        />
      )}
      {!isGetting && horeca && isInEditMode && (
        <Form
          isFetching={isUpdating}
          error={updateError}
          cancel={toggleEditMode}
          initialValues={{
            address: horeca.address ? horeca.address : '',
            currency: horeca.currency,
            id: horeca.id,
            openingHour: horeca.openingHour
          }}
          onSubmit={values => updateCall({data: values})}
        />
      )}
      {!isGetting && !isInEditMode && (
        <>
          <Switch onClick={toggleEditMode} text={t('config.info.edit')} />
          {horeca && (
            <List>
              {horeca.org_id && (
                <Item>
                  <h6>{t('config.info.iot')}:</h6> <span>{horeca.org_id}</span>
                </Item>
              )}
              <Item>
                <h6>{t('config.info.address')}:</h6>{' '}
                <span>{horeca.address ? horeca.address : '-'}</span>
              </Item>
              <Item>
                <h6>{t('config.info.openingHour')}:</h6>{' '}
                <span>{horeca.openingHour ? `${horeca.openingHour}:00` : '-'}</span>
              </Item>
              <Item>
                <h6>{t('config.info.currency')}:</h6> <span>{horeca.currency}</span>
              </Item>
            </List>
          )}
        </>
      )}
    </Card>
  )
}

export default Info
