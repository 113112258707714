import React from 'react'

//TODO needs content
const Terms = () => {
  return (
    <div>
      Terms of service
    </div>
  )
}

export default Terms
