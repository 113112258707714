import styled from 'styled-components'

import colors from '../../style/colors'
import font from '../../style/font'

export default styled.p`
  color: ${colors.WHITE};
  font-family: ${font.family};
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem;
  text-align: center;
`
