import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import Select from '../../../shared/components/Select'
import Button from '../../../shared/components/Button'
import {CATEGORIES, Category} from '../../../constants'
import FormContainer from '../FormContainer'
import ButtonsContainer from '../ButtonsContainer'
import {ProductGroup} from '../../../types'

interface FormProps {
  id?: number
  category: string
  group?: number
  name: string
  portion?: number
  price?: number
  plu?: number
}

interface Props extends FormikConfig<FormProps> {
  cancel: () => void
  error?: AxiosError
  groups?: ProductGroup[]
  isFetching?: boolean
}

const Form: React.FC<Props> = ({
  cancel,
  error,
  groups,
  initialValues,
  isFetching,
  onSubmit,
  ...rest
}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('common.error.required')),
        category: Yup.string().required(t('common.error.required')),
        group: Yup.number(),
        portion: Yup.number().required(t('common.error.required')),
        price: Yup.number().required(t('common.error.required'))
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && <Error component={ErrorComponent} error={error} converters={[]} />}
            <Field
              id="product-form-name"
              name="name"
              type="text"
              component={Input}
              label={t('config.product.form.name.label')}
              placeholder={t('config.product.form.name.placeholder')}
            />
            <Field
              id="product-form-category"
              name="category"
              options={CATEGORIES.map((category: Category) => ({
                label: t(category.i18nKey),
                value: category.name
              }))}
              component={Select}
              label={t('config.product.form.category.label')}
              placeholder={t('config.product.form.category.placeholder')}
            />
            {groups && groups.length > 0 && (
              <Field
                id="product-form-group"
                name="group"
                options={groups.map((group: ProductGroup) => ({
                  label: group.name,
                  value: group.id
                }))}
                component={Select}
                label={t('config.product.form.group.label')}
                placeholder={t('config.product.form.group.placeholder')}
              />
            )}
            <Field
              id="product-form-plu"
              name="plu"
              type="number"
              step="1"
              component={Input}
              label={t('config.product.form.plu.label')}
              placeholder={t('config.product.form.plu.placeholder')}
            />
            <Field
              id="product-form-portion"
              name="portion"
              type="number"
              step="1"
              component={Input}
              label={t('config.product.form.portion.label')}
              placeholder={t('config.product.form.portion.placeholder')}
            />
            <Field
              id="product-form-price"
              name="price"
              type="number"
              step=".01"
              component={Input}
              label={t('config.product.form.price.label')}
              placeholder={t('config.product.form.price.placeholder')}
            />

            <ButtonsContainer>
              <Button
                tertiary
                disabled={isFetching}
                type="button"
                onClick={() => {
                  props.handleReset()
                  cancel()
                }}
              >
                {t('config.product.form.cancel')}
              </Button>

              <Button disabled={isFetching} type="submit">
                {isFetching && <FontAwesomeIcon icon={faCog} spin />}
                {!isFetching &&
                  t(`config.product.form.submit.${initialValues.id ? 'update' : 'create'}`)}
              </Button>
            </ButtonsContainer>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default Form
