import React, {useCallback} from 'react'
import Select from 'react-select'
import {RouteComponentProps, withRouter} from 'react-router'
import {useTranslation} from 'react-i18next'

import {useHorecaContext} from '../../../horeca/Provider'
import {Horeca} from '../../../../types'
import Error from '../../Error'
import ErrorComponent from './Error'
import ErrorContainer from './ErrorContainer'
import Container from './Container'
import Retry from './Retry'
import styles from './styles'
import SelectorContainer from './SelectorContainer'

interface Option {
  label: string
  value: number
}

const HorecaSelector: React.FC<
  RouteComponentProps<{
    horecaId?: string
    page?: string
  }>
> = ({match, history}) => {
  const {t} = useTranslation()
  const {error, horecas, isFetching, refresh} = useHorecaContext()
  const pageParam = match.params.page
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const push = history.push

  const onChange = useCallback(
    (o: Option) => {
      push(`/h/${o.value}/${pageParam ? pageParam : 'volume'}`)
    },
    [push, pageParam]
  )

  const selectedHoreca = horecas && horecas.find((h: Horeca) => h.id === horecaIdParam)

  return (
    <Container>
      <SelectorContainer>
        <Select
          isLoading={isFetching}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#ddd',
              primary: 'none',
              neutral20: '#333',
              neutral30: '#333',
              neutral40: '#333',
              neutral50: '#333',
              neutral60: '#333',
              neutral70: '#333',
              neutral80: '#333'
            }
          })}
          styles={styles}
          options={
            horecas
              ? horecas.map((h: Horeca) => ({
                  label: h.name,
                  value: h.id
                }))
              : []
          }
          onChange={onChange}
          value={
            selectedHoreca
              ? {
                  label: selectedHoreca.name,
                  value: selectedHoreca.id
                }
              : null
          }
        />
      </SelectorContainer>
      {error && (
        <ErrorContainer>
          <Error component={ErrorComponent} error={error} />
          <Retry onClick={refresh}>{t('common.retry')}</Retry>
        </ErrorContainer>
      )}
    </Container>
  )
}

export default withRouter(HorecaSelector)
