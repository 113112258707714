import styled from 'styled-components'
import media from '../../../shared/style/media'

export default styled.div`
  align-items: center
  display: flex;
  justify-content: space-around;
  padding: 0.75rem;
  
  & > div {
    width: 50%;
  }
  
  ${media.lessThan('M')`
    flex-direction: column;
    
    & > div {
      width: 100%;
    }
  `}
`
