import React from 'react'
import {Redirect} from 'react-router'

import {User} from '../../types'
import {useHorecaContext} from '../../shared/horeca/Provider'
import Horeca from './Horeca'
import Company from './Company'

interface Props {
  user: User
}

const Onboarding: React.FC<Props> = ({user}) => {
  const {horecas} = useHorecaContext()

  if (user && user.roles && user.roles.length === 0) return <Company />
  if (horecas && horecas.length === 0) {
    return <Horeca />
  } else if (horecas && horecas.length > 0) {
    return <Redirect to={`/h/${horecas[0].id}/config`} push />
  }
  return null
}

export default Onboarding
