import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {CSVLink} from 'react-csv'

import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import BarGraph from '../BarGraph'
import {useTimeContext} from '../../../shared/time/Provider'
import {useAuthContext} from '../../../shared/auth/Provider'

interface Props extends CategorySelectorProps {
  currency: string
  dataPoints: any[]
  emptyText: string
  valueType: string
  isMobile: boolean
}

const VolumesGraph: React.FC<Props> = ({
  currency,
  dataPoints,
  emptyText,
  valueType,
  isMobile,
  horeca,
  ...rest
}) => {
  const {t} = useTranslation()
  const {to, from} = useTimeContext()
  const {isAdmin} = useAuthContext()
  const selectedCategory = rest.selectedCategory
  const csvData = useMemo(
    () => [
      [
        selectedCategory === 'Summary' ? t('volume.csv.category') : t('common.product'),
        `${t('common.revenue')} (${currency})`,
        `${t('common.volume')} (L)`
      ],
      ...dataPoints.map(dp => [dp.label, dp.revenue, dp.y])
    ],
    [currency, dataPoints]
  )

  return (
    <Card title={t('volume.volume.title')} {...rest}>
      <BarGraph
        currency={currency}
        dataPoints={dataPoints}
        emptyText={emptyText}
        valueType={valueType}
        isMobile={isMobile}
      />
      {(isAdmin || (horeca && ['PRO', 'PRO+'].includes(horeca.activationType))) && (
        <CSVLink
          data={csvData}
          filename={`${selectedCategory}-${to.format('DD-MM-YYYY')}-${from.format(
            'DD-MM-YYYY'
          )}.csv`}
        >
          {t('common.download')}
        </CSVLink>
      )}
    </Card>
  )
}

export default VolumesGraph
