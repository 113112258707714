import React, {useEffect, useState} from 'react'
import {Redirect, RouteComponentProps} from 'react-router'
import {useTranslation} from 'react-i18next'

import useAxios from '../../shared/useFetch'
import Background from '../../shared/route/PublicRoute/Background'
import Content from '../../shared/route/PublicRoute/Content'
import Logo from '../../shared/route/PublicRoute/Logo'
import ErrorComponent from '../../shared/components/Error/Public'
import Error from '../../shared/components/Error'
import Loading from '../../shared/components/Loading'

const Invitation: React.FC<RouteComponentProps> = ({}) => {
  const {t} = useTranslation()
  const [responded, setResponded] = useState()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')
  const status = query.get('status')

  const [{error, isFetching}, call] = useAxios(
    {
      url: '/invitation/respond',
      method: 'POST'
    },
    {
      success: () => {
        setResponded(true)
      }
    }
  )

  useEffect(() => {
    if (token && status) call({data: {token, status}})
  }, [token, status])

  return (
    <Background>
      <Logo />
      <Content>
        {!isFetching && error && <Error component={ErrorComponent} error={error} converters={[]} />}
        {isFetching && <Loading text={t('invitation.loading')} />}
        {!isFetching && responded && <Redirect to="/" />}
      </Content>
    </Background>
  )
}

export default Invitation
