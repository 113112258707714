import React, {useState, useEffect, useCallback} from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'

import {TIME_FORMAT} from '../../constants'
import {getTimeFormat} from '../../shared/time'
import Loading from '../../shared/components/Loading'
import EmptyState from '../../shared/components/EmptyState'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'
import useAxios from '../../shared/useFetch'
import {useTimeContext} from '../../shared/time/Provider'
import {useHorecaContext} from '../../shared/horeca/Provider'
import {Horeca} from '../../types'
import CardsContainer from '../Horeca/CardsContainer'
import CenterContainer from '../Horeca/CenterContainer'
import PressureGraph from './PressureGraph'
import TemperatureGraph from './TemperatureGraph'
import KeyLogsList from './KeyLogsList'
import AlertLogsList from './AlertLogsList'
import {measurementNormalizer} from './normalizers'
import LogsList from './LogsList'

const Technic: React.FC<
  RouteComponentProps<{
    horecaId?: string
  }>
> = ({match}) => {
  const {t} = useTranslation()
  const {option, to, from} = useTimeContext()
  const {horecas} = useHorecaContext()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const horeca = horecaIdParam && horecas && horecas.find((h: Horeca) => h.id === horecaIdParam)
  const openingHour = horeca ? horeca.openingHour : null
  const isV1 = horeca && !!horeca.org_id

  const timeFormat = getTimeFormat(option)

  const [selectedDevice, setSelectedDevice] = useState<string>()
  const [deviceIds, setDeviceIds] = useState<string[]>()
  const [devices, setDevices] = useState()

  const keyLogCategories = [
    {value: 'Cleaning', label: t('technic.keyLogs.cleaning')},
    {value: 'Calibration', label: t('technic.keyLogs.calibration')},
    {value: 'Manager', label: t('technic.keyLogs.manager')}
  ]
  const [selectedKeyLogCategory, setSelectedKeyLogCategory] = useState<string>('Cleaning')

  const alertLogCategories = [{value: 'SafeOn', label: t('technic.alertLogs.safeon')}]
  const [selectedAlertLogCategory, setSelectedAlertLogCategory] = useState<string>('SafeOn')

  const logCategories = [
    {value: 'SafeOn', label: t('technic.alertLogs.safeon')},
    {value: 'Cleaning', label: t('technic.keyLogs.cleaning')},
    {value: 'Calibration', label: t('technic.keyLogs.calibration')},
    {value: 'Manager', label: t('technic.keyLogs.manager')}
  ]
  const [selectedLogCategory, setSelectedLogCategory] = useState<string>('Cleaning')

  const deviceChange = useCallback(
    d => {
      setSelectedDevice(d)
    },
    [setSelectedDevice]
  )

  const keyLogCategoryChange = useCallback(
    d => {
      setSelectedKeyLogCategory(d)
    },
    [setSelectedKeyLogCategory]
  )

  const alertLogCategoryChange = useCallback(
    d => {
      setSelectedAlertLogCategory(d)
    },
    [setSelectedAlertLogCategory]
  )

  const logCategoryChange = useCallback(
    d => {
      setSelectedLogCategory(d)
    },
    [setSelectedLogCategory]
  )

  const [{data, error, isFetching}, call] = useAxios(
    {
      url: `/horeca/:horecaId/technic`,
      method: 'GET'
    },
    {
      success: ({data: {measurements, devices}}) => {
        setDevices(devices)
        const deviceIds = measurements ? Object.keys(measurements) : []
        if (deviceIds.length > 0) {
          setDeviceIds(
            deviceIds.map(id => {
              const device = devices
                ? devices.find(d => {
                    return d.serial == id
                  })
                : null
              const label = device ? device.name : id
              return {value: id, label}
            })
          )
          setSelectedDevice(deviceIds[0])
        }
      }
    }
  )

  const [temperatureData, pressureData] =
    data && data.measurements && selectedDevice && devices && data.measurements[selectedDevice]
      ? measurementNormalizer(
          data.measurements[selectedDevice],
          devices.find(d => d.serial == selectedDevice)
        )
      : [null, null]

  useEffect(() => {
    if (openingHour !== null) {
      call({
        url: `/horeca/${horecaIdParam}/technic`,
        params: {
          start: isV1
            ? from.add(openingHour, 'hour').format(TIME_FORMAT)
            : from.add(openingHour, 'hour').toISOString(),
          end: isV1
            ? to.add(openingHour, 'hour').format(TIME_FORMAT)
            : to.add(openingHour, 'hour').toISOString()
        }
      })
    }
  }, [horecaIdParam, openingHour, to, from])

  if (isFetching) {
    return <Loading text={t('technic.loading')} />
  }

  if (
    data &&
    (!data.measurements || Object.keys(data.measurements).length === 0) &&
    data.safeon.length === 0 &&
    data.manager.length === 0 &&
    data.calibration.length === 0 &&
    data.cleaning.length === 0
  ) {
    return <EmptyState text={t('technic.empty')} />
  }

  return (
    <>
      {error && (
        <Error
          component={ErrorComponent}
          error={error}
          converters={[
            {
              key: 'common.notfound.horeca',
              check: (err: AxiosError) => (err.response ? err.response.status === 404 : false)
            },
            {
              key: 'common.error.notActivated',
              check: (err: AxiosError) => {
                return err.response
                  ? err.response.status === 400 && err.response.data.name === 'NotActive'
                  : false
              }
            }
          ]}
        />
      )}

      {data && (
        <CardsContainer>
          {temperatureData && (
            <TemperatureGraph
              device={
                devices && selectedDevice ? devices.find(d => d.serial == selectedDevice) : null
              }
              categoryOptions={deviceIds}
              onCategoryChange={deviceChange}
              selectedCategory={selectedDevice}
              timeFormat={timeFormat}
              data={temperatureData}
            />
          )}
          {pressureData && (
            <PressureGraph
              device={
                devices && selectedDevice ? devices.find(d => d.serial == selectedDevice) : null
              }
              categoryOptions={deviceIds}
              onCategoryChange={deviceChange}
              selectedCategory={selectedDevice}
              timeFormat={timeFormat}
              data={pressureData}
            />
          )}
          {data && isV1 && (
            <>
              <KeyLogsList
                categoryOptions={keyLogCategories}
                onCategoryChange={keyLogCategoryChange}
                selectedCategory={selectedKeyLogCategory}
                cleaning={data.cleaning}
                calibration={data.calibration}
                manager={data.manager}
                timeFormat={timeFormat}
              />
              <AlertLogsList
                categoryOptions={alertLogCategories}
                onCategoryChange={alertLogCategoryChange}
                selectedCategory={selectedAlertLogCategory}
                safeon={data.safeon}
                timeFormat={timeFormat}
              />
            </>
          )}
        </CardsContainer>
      )}
      {data && !isV1 && (
        <CenterContainer>
          <LogsList
            categoryOptions={logCategories}
            onCategoryChange={logCategoryChange}
            selectedCategory={selectedLogCategory}
            safeon={data.safeon}
            calibration={data.calibration}
            cleaning={data.cleaning}
            manager={data.manager}
            timeFormat={timeFormat}
          />
        </CenterContainer>
      )}
    </>
  )
}

export default withRouter(Technic)
