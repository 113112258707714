import styled from 'styled-components'

import colors from '../../../style/colors'
import media from '../../../style/media'

export default styled.button<{selected: boolean}>`
  align-items: center;
  background-color: ${({selected}) => (selected ? colors.PRIMARY : 'transparent')};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.625rem 0;
  width: 3.5rem;
  
  ${media.lessThan('M')`
    height: 3.5rem;
    padding: 0 0.625rem;
  `}
`
