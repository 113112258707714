import styled from 'styled-components'

import colors from '../../../../shared/style/colors'
import font from '../../../../shared/style/font'

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  width: 100%;
  
  & > span {
    color: ${colors.DARK};
    font-family: ${font.family};
    margin: 0 0.25rem;
  }
  
  & input {
    margin: 0 0.25rem 0 0;
  }
`
