import React from 'react'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'

import Container from './Container'
import Card from './Card'
import Title from './Title'
import Content from './Content'
import Column from './Column'
import Revenue from './Revenue'
import Volume from './Volume'
import Time from './Time'

interface Values {
  revenue: number
  volume: string
}

export interface ComparisonData {
  category: string
  compare: Values
  current: Values
}

interface Props {
  currency: string
  data: ComparisonData[]
  timeOption: number
}

const ComparisonBoxes: React.FC<Props> = ({currency, data, timeOption, ...rest}) => {
  const {t} = useTranslation()

  let compareTime: string, time: string
  switch (timeOption) {
    case 0:
      time = t('time.yesterday')
      compareTime = t('volume.comparison.last', {
        day: dayjs().subtract(1, 'day').format('dddd'),
      })
     break
    case 1:
      time = t('time.today')
      compareTime = t('volume.comparison.last', {
          day: dayjs().format('dddd'),
        })
      break
    case 2:
      time = t('time.week')
      const weekOfMonth = (dayjs().week() - dayjs().startOf('month').week()) + 1
      let ordinal
      if (weekOfMonth === 1) {
        ordinal = `${weekOfMonth}st`
      } else if (weekOfMonth === 2) {
        ordinal = `${weekOfMonth}nd`
      } else if (weekOfMonth === 3) {
        ordinal = `${weekOfMonth}rd`
      } else {
        ordinal = `${weekOfMonth}th`
      }

      compareTime = t('volume.comparison.week', {
        ordinal,
        month: dayjs().subtract(1, 'month').format('MMM')
      })
      break
  }

  if (timeOption === 3) return null

  return (
    <Container {...rest}>
      {data.map((c: ComparisonData) => (
        <Card key={c.category}>
          <Title>{c.category}</Title>
          <Content>
            <Column>
              <Revenue>{c.current.revenue} <sup>{currency}</sup></Revenue>
              <Volume>{c.current.volume}</Volume>
              <Time>{time}</Time>
            </Column>
            <Column compare>
              <Revenue>{c.compare.revenue} <sup>{currency}</sup></Revenue>
              <Volume>{c.compare.volume}</Volume>
              <Time compare>{compareTime}</Time>
            </Column>
          </Content>
        </Card>
      ))}
    </Container>
  )
}

export default ComparisonBoxes
