import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {faAngleRight, faAngleDown} from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'

import EmptyState from '../../../shared/components/EmptyState'
import {TableExtender} from '../../../shared/components/Table'
import Card, {CategorySelectorProps} from '../../../shared/components/Card'
import {SafeOnLog} from '../../../types'
import List from '../List'

interface Props extends CategorySelectorProps {
  safeon: SafeOnLog[]
  timeFormat: string
}

const AlertLogsList: React.FC<Props> = ({safeon, timeFormat, ...rest}) => {
  const {t} = useTranslation()
  const selectedCategory = rest.selectedCategory

  const [data, columns] = useMemo(() => {
    switch (selectedCategory) {
      case 'SafeOn':
        return [safeon, [
          {Header: '', id: 'expander', Cell: ({row}: any) => row.original.volume
              && Object.values(row.original.volume).length > 0 && (
                <TableExtender
                  icon={row.isExpanded ? faAngleDown : faAngleRight}
                  {...row.getExpandedToggleProps()}
                />
              )
          },
          {Header: t('technic.columns.key'), width: 200, Cell: ({row: {original}}: any) => {
            return `[${original.emp_key ? original.emp_key : '-'}] ${original.keyName}`
          }},
          {Header: t('technic.columns.address'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return `${original.mod_addr}:${original.evt_valve_num}`
            }},
          {Header: t('technic.columns.start'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.on
                ? dayjs(original.on).format(timeFormat)
                : '-'
            }},
          {Header: t('technic.columns.duration'), minWidth: 100,
            Cell: ({row: {original}}: any) => {
              return original.duration
                ? original.duration
                : '-'
            }}
        ]]
      default:
        return [null, null]
    }
  }, [safeon, timeFormat, selectedCategory])

  return (
    <Card title={t('technic.alertLogs.title')} {...rest}>
      {data && data.length > 0 && columns && (
        <List data={data} columns={columns} />
      )}
      {selectedCategory && data && data.length === 0 && columns && (
        <EmptyState
          text={
            t(`technic.logs.empty`, {type: t(`technic.alertLogs.${selectedCategory.toLowerCase()}`)})
          }
        />
      )}
    </Card>
  )
}

export default AlertLogsList
