import React from 'react'
import {FieldProps} from 'formik'

import Container from './Container'
import Label from './Label'
import ValidationError from './ValidationError'
import Field from './Field'

interface Props extends FieldProps {
  id: string
  label: string
}

const TextArea: React.FC<Props> = ({field, form, label, ...rest}) => {
  return (
    <Container>
      <Label htmlFor={rest.id}>
        {label}
        {form.submitCount > 0 && form.errors[field.name] && (
          <ValidationError>{form.errors[field.name]}</ValidationError>
        )}
      </Label>
      <Field {...field} {...rest} />
    </Container>
  )
}

export default TextArea
