import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'

import {useAuthContext} from '../../auth/Provider'
import {useHorecaContext} from '../../horeca/Provider'
import ROUTES from '../../../routes'
import Background from './Background'
import Content from './Content'
import Logo from './Logo'

interface Props extends RouteProps {
  component: React.ComponentType<any>
}

const PublicRoute: React.FC<Props> = ({component: Component, ...rest}) => {
  const {user} = useAuthContext()
  const {horecas, isFetching} = useHorecaContext()

  if (user) {
    if (user.roles.length === 0 || (!isFetching && horecas && horecas.length === 0)) {
      return <Redirect to={ROUTES.ONBOARDING} push />
    }
    return <Redirect to={ROUTES.HORECA} push />
  }

  return (
    <Background>
      <Logo />
      <Content>
        <Route {...rest} render={props => <Component {...props} />} />
      </Content>
    </Background>
  )
}

export default PublicRoute
