import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import Select from '../../../shared/components/Select'
import Button from '../../../shared/components/Button'
import FormContainer from '../FormContainer'
import ButtonsContainer from '../ButtonsContainer'

interface FormProps {
  email: string
  role: string
}

interface Props extends FormikConfig<FormProps> {
  cancel: () => void
  error?: AxiosError
  isFetching?: boolean
}

const Form: React.FC<Props> = ({cancel, error, initialValues, isFetching, onSubmit, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().required(t('common.error.required')),
        role: Yup.string().required(t('common.error.required'))
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && (
              <Error
                component={ErrorComponent}
                error={error}
                converters={[
                  {
                    key: 'config.team.errors.hasRole',
                    check: (err: AxiosError) =>
                      err.response ? err.response.data.name === 'AlreadyHasRole' : false
                  },
                  {
                    key: 'config.team.errors.exists',
                    check: (err: AxiosError) =>
                      err.response ? err.response.data.name === 'AlreadyExists' : false
                  }
                ]}
              />
            )}
            <Field
              id="invite-form-role"
              name="role"
              options={[
                {label: 'Waiter', value: 'Waiter'},
                {label: 'Manager', value: 'Manager'},
                {label: 'Investor', value: 'Investor'}
              ]}
              component={Select}
              label={t('config.invite.form.role.label')}
              placeholder={t('config.invite.form.role.placeholder')}
            />
            <Field
              id="invite-form-email"
              name="email"
              type="email"
              component={Input}
              label={t('config.invite.form.email.label')}
              placeholder={t('config.invite.form.email.placeholder')}
            />

            <ButtonsContainer>
              <Button
                tertiary
                disabled={isFetching}
                type="button"
                onClick={() => {
                  props.handleReset()
                  cancel()
                }}
              >
                {t('config.invite.form.cancel')}
              </Button>

              <Button disabled={isFetching} type="submit">
                {isFetching && <FontAwesomeIcon icon={faCog} spin />}
                {!isFetching && t('config.invite.form.submit')}
              </Button>
            </ButtonsContainer>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default Form
