import React, {useCallback, useEffect, useState} from 'react'
import {Transition} from 'react-transition-group'
import {TransitionStatus} from 'react-transition-group/Transition'

import Logo from '../Logo'
import Container from './Container'
import LogoContainer from './LogoContainer'
import HorecaSelector from './HorecaSelector'
import TimeSelector from './TimeSelector'
import {Row, TimeRow} from './Row'
import Menu from './Menu'
import {useTimeContext} from '../../time/Provider'

interface Props {
  hideTimeSelector?: boolean
}

const Header: React.FC<Props> = ({hideTimeSelector}) => {
  const {option} = useTimeContext()
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [showHeader, setShowHeader] = useState<boolean>(true)

  const handleScroll = useCallback(() => {
    const pos = document.body.getBoundingClientRect().top
    setShowHeader(pos > scrollPosition)
    setScrollPosition(pos)
  }, [scrollPosition, setScrollPosition, setShowHeader])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Transition in={showHeader} timeout={500}>
      {(state: TransitionStatus) => (
        <Container state={state} filterOpen={option === 3} hideTimeSelector={hideTimeSelector}>
          <Row>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <HorecaSelector />
            <Menu />
          </Row>
          {!hideTimeSelector && (
            <TimeRow>
              <TimeSelector />
            </TimeRow>
          )}
        </Container>
      )}
    </Transition>
  )
}

export default Header
