import React from 'react'

// @ts-ignore
import CanvasJSReact from '../../../shared/canvasjs/canvasjs.react'
import {GraphLine} from '../normalizers'
import EmptyState from './EmptyState'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export interface LineGraphProps {
  data: GraphLine[]
  timeFormat: string
}

interface Props extends LineGraphProps {
  emptyText: string
  valueType: string
}

const LineGraph: React.FC<Props> = ({emptyText, data, timeFormat, valueType, ...rest}) => {
  const isEmpty = data.every((line: GraphLine) => line.dataPoints.length === 0)
  const graphTimeFormat = timeFormat === 'ddd HH:mm' ? 'DDD HH:mm' : timeFormat

  if (isEmpty) return <EmptyState text={emptyText} />

  return (
    <CanvasJSChart
      options={{
        animationEnabled: true,
        axisX: {
          valueFormatString: graphTimeFormat
        },
        axisY: {
          labelFormatter: (e: any) => `${e.value.toFixed(1)} ${valueType}`
        },
        data: data,
        toolTip: {
          contentFormatter: (e: any) => {
            let content = ''
            for (let i = 0; i < e.entries.length; i++) {
              const x = CanvasJSReact.CanvasJS.formatDate(e.entries[i].dataPoint.x, graphTimeFormat)
              const y = `${e.entries[i].dataPoint.y} ${valueType}`
              content = `${x}<br />${y}`
            }
            return content
          }
        },
        zoomEnabled: true
      }}
      {...rest}
    />
  )
}

export default LineGraph
