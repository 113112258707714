const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  TERMS: '/terms-of-service',
  PRIVACY: '/privacy-policy',
  COOKIE: '/cookie-policy',
  INVITATION: '/invitation',

  HORECA: '/h',
  HORECA_SELECTED: '/h/:horecaId(\\d+)/:page',
  ONBOARDING: '/onboarding'
}

export default ROUTES
