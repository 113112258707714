import styled from 'styled-components'
import {transparentize} from 'polished'

import font from '../../style/font'
import colors from '../../style/colors'

export default styled.textarea`
  border: 0.0625rem solid ${colors.GREY};
  border-radius: 0.5rem;
  color: ${colors.DARK};
  font-size: 1rem;
  font-family: ${font.family};
  padding: 0.625rem 1.25rem;
  resize: none;
  
  ::placeholder {
    color: ${transparentize(0.5, colors.DARK)};
  }
  
  &:focus {
    outline: 0;
  }
`
