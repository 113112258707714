import {generateMedia, pxToRem} from 'styled-media-query'

const REM_RATIO = 16;
const breakpoints = pxToRem({
  XXL: '1440px',
  XL: '1200px',
  L: '992px',
  M: '768px',
  S: '500px'
}, REM_RATIO)
const media = generateMedia(breakpoints)

export default media
