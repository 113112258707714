import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../style/colors'

export default styled.label<{secondary?: boolean}>`
  color: ${colors.BLACK};
  font-size: 0.8rem;
  margin-bottom: 0.375rem;

  ${({secondary}) => secondary && `
    color: ${transparentize(0.5, colors.WHITE)};
  `}
`
