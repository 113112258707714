import styled from 'styled-components'

export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 18.75rem;
  padding: 1.25rem 0;
  width: 100%
`
