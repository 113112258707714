import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactGA from 'react-ga'

import useAxios from '../../shared/useFetch'
import AuthLink from '../../shared/components/AuthLink'
import Message from '../../shared/components/Message'
import ROUTES from '../../routes'
import Form from './Form'

const initialValues = {email: ''}

const Reset: React.FC = () => {
  const {t} = useTranslation()
  const [isSent, setIsSent] = useState(false)
  const [{error, isFetching}, call] = useAxios(
    {
      url: '/user/password/reset',
      method: 'POST'
    },
    {
      success: () => {
        ReactGA.event({
          category: 'Reset Password',
          action: 'Requested',
          label: 'Reset password email requested'
        })
        setIsSent(true)
      },
      fail: err => {
        if (err.response) {
          ReactGA.event({
            category: 'Reset Password',
            action: `${err.response.status} - ${err.response.data.name}`,
            label: 'Reset password failed',
            nonInteraction: true
          })
        }
      }
    }
  )

  const onSubmit = useCallback(
    values => {
      call({data: values})
    },
    [call]
  )

  if (isSent) return <Message>{t('reset.success')}</Message>

  return (
    <>
      <Form
        error={error}
        isFetching={isFetching}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <AuthLink text={t('reset.login.text')} linkText={t('reset.login.link')} link={ROUTES.LOGIN} />
    </>
  )
}

export default Reset
