import styled from 'styled-components'

import colors from '../../../style/colors'
import font from '../../../style/font'

export default styled.div`
  color: ${colors.PRIMARY};
  font-family: ${font.family};
  font-size: 0.875rem;
`
