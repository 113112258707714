import React, {useCallback, useEffect, useState} from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {AxiosError, default as axios} from 'axios'
import {useTranslation} from 'react-i18next'

import {useTimeContext} from '../../shared/time/Provider'
import {useHorecaContext} from '../../shared/horeca/Provider'
import {Horeca} from '../../types'
import {getSelectedInterval, getTooltipTimeFormat, INTERVALS} from '../../shared/time'
import useAxios from '../../shared/useFetch'
import {CATEGORIES, Category, TIME_FORMAT} from '../../constants'
import Loading from '../../shared/components/Loading'
import EmptyState from '../../shared/components/EmptyState'
import Error from '../../shared/components/Error'
import ErrorComponent from '../../shared/components/Error/Public'
import CardsContainer from '../Horeca/CardsContainer'
import {volumesNormalizer, volumesNormalizerV2} from './normalizers'
import Pulse from './Pulse'
import ComparisonBoxes from './ComparisonBoxes'
import CoffeeGraph from './CoffeeGraph'
import BottlesGraph from './BottlesGraph'
import VolumesGraph from './VolumesGraph'
import ActivityGraph from './ActivityGraph'
import WasteGraph from './WasteGraph'

const Volume: React.FC<
  RouteComponentProps<{
    horecaId?: string
  }>
> = ({match}) => {
  const {t} = useTranslation()
  const {option, to, from, compareTo, compareFrom} = useTimeContext()
  const {horecas} = useHorecaContext()
  const horecaIdParam = match.params.horecaId ? parseInt(match.params.horecaId) : undefined
  const horeca = horecaIdParam && horecas && horecas.find((h: Horeca) => h.id === horecaIdParam)
  const openingHour = horeca ? horeca.openingHour : null
  const currency = horeca ? horeca.currency : 'CHF'
  const isV1 = horeca && !!horeca.org_id

  const [isMobile, setIsMobile] = useState(window.innerWidth < 801)
  const [intervalIndex, setIntervalIndex] = useState(1)
  const [category, setCategory] = useState('Summary')
  const [coffeeGraphType, setCoffeeGraphType] = useState('Quantities')
  const [bottlesGraphType, setBottlesGraphType] = useState('Quantities')

  const selectedInterval = getSelectedInterval(option, intervalIndex)
  const timeFormat = getTooltipTimeFormat(option, INTERVALS[selectedInterval])

  const coffeeGraphTypeChange = useCallback(
    c => {
      setCoffeeGraphType(c)
    },
    [setCoffeeGraphType]
  )
  const bottlesGraphTypeChange = useCallback(
    c => {
      setBottlesGraphType(c)
    },
    [setBottlesGraphType]
  )

  const [{data, error, isFetching}, call] = useAxios({
    url: '/horeca/:horecaId/volume',
    method: 'GET'
  })

  const {
    psr,
    revenue,
    comparisons,
    coffeeQuantity,
    coffeeActivity,
    coffeeWaste,
    wastes,
    availableCategories,
    volumes,
    activityOverTime,
    bottlesQuantity,
    bottlesActivity
  } = data
    ? isV1
      ? volumesNormalizer(
          data.volumes,
          data.compareVolumes,
          data.waste,
          currency,
          INTERVALS[selectedInterval],
          category,
          timeFormat,
          t
        )
      : volumesNormalizerV2(
          data.volumes,
          data.compareVolumes,
          data.waste,
          currency,
          INTERVALS[selectedInterval],
          category,
          timeFormat,
          t
        )
    : {
        psr: null,
        revenue: '',
        comparisons: [],
        activityOverTime: [],
        coffeeQuantity: [],
        coffeeActivity: [],
        coffeeWaste: [],
        bottlesQuantity: [],
        bottlesActivity: [],
        volumes: [],
        availableCategories: [],
        wastes: []
      }

  const categoryOptions = [
    {
      label: t('volume.tags.summary'),
      value: 'Summary'
    },
    ...CATEGORIES.filter((c: Category) => {
      return availableCategories.includes(c.name) && !['Coffee', 'Bottles'].includes(c.name)
    }).map((c: Category) => ({
      label: t(c.i18nKey),
      value: c.name
    }))
  ]

  useEffect(() => {
    const resize = () => setIsMobile(window.innerWidth < 801)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [setIsMobile])

  useEffect(() => {
    let source = axios.CancelToken.source()
    if (openingHour !== null) {
      call({
        url: `/horeca/${horecaIdParam}/volume`,
        cancelToken: source.token,
        params: {
          start: isV1
            ? from.add(openingHour, 'hour').format(TIME_FORMAT)
            : from.add(openingHour, 'hour').toISOString(),
          end: isV1
            ? to.add(openingHour, 'hour').format(TIME_FORMAT)
            : to.add(openingHour, 'hour').toISOString(),
          cStart: compareFrom
            ? isV1
              ? compareFrom.add(openingHour, 'hour').format(TIME_FORMAT)
              : compareFrom.add(openingHour, 'hour').toISOString()
            : undefined,
          cEnd: compareTo
            ? isV1
              ? compareTo.add(openingHour, 'hour').format(TIME_FORMAT)
              : compareTo.add(openingHour, 'hour').toISOString()
            : undefined
        }
      })
    }
    return () => {
      source.cancel('CANCEL')
    }
  }, [horecaIdParam, openingHour, to, from, compareFrom, compareTo])

  if (isFetching) {
    return <Loading text={t('volume.loading')} />
  }

  if (
    data &&
    (data.volumes.length === 0 && data.compareVolumes.length === 0 && data.waste.length === 0)
  ) {
    return <EmptyState text={t('volume.empty')} />
  }

  return (
    <>
      {error && (
        <Error
          component={ErrorComponent}
          error={error}
          converters={[
            {
              key: 'common.notfound.horeca',
              check: (err: AxiosError) => (err.response ? err.response.status === 404 : false)
            },
            {
              key: 'common.error.notActivated',
              check: (err: AxiosError) => {
                return err.response
                  ? err.response.status === 400 && err.response.data.name === 'NotActive'
                  : false
              }
            }
          ]}
        />
      )}

      {psr && <Pulse psr={psr} revenue={revenue} horeca={horeca} />}

      {comparisons && comparisons.length > 0 && (
        <ComparisonBoxes data={comparisons} timeOption={option} currency={currency} />
      )}

      {data && (
        <CardsContainer>
          {((coffeeQuantity && coffeeQuantity.length > 0) ||
            (coffeeWaste && coffeeWaste.length > 0)) && (
            <CoffeeGraph
              isMobile={isMobile}
              categoryOptions={[
                {value: 'Quantities', label: t('volume.coffee.quantity')},
                {value: 'Activity', label: t('volume.coffee.activity')},
                {value: 'Waste', label: t('volume.coffee.waste')}
              ]}
              time={option}
              currency={currency}
              selectedCategory={coffeeGraphType}
              onCategoryChange={coffeeGraphTypeChange}
              quantityGraphDataPoints={coffeeQuantity}
              activityDataPoints={coffeeActivity}
              wasteDataPoints={coffeeWaste}
              timeFormat={timeFormat}
              selectedInterval={INTERVALS[selectedInterval]}
              intervalChange={setIntervalIndex}
              horeca={horeca}
            />
          )}
          {availableCategories.length > 0 && (
            <VolumesGraph
              currency={currency}
              onCategoryChange={setCategory}
              selectedCategory={category}
              categoryOptions={categoryOptions}
              dataPoints={volumes}
              emptyText={t('volume.volume.empty')}
              valueType="L"
              isMobile={isMobile}
              horeca={horeca}
            />
          )}
          {availableCategories.length > 0 && (
            <ActivityGraph
              currency={currency}
              dataPoints={activityOverTime}
              emptyText={t('volume.volume.empty')}
              valueType="L"
              timeFormat={timeFormat}
              time={option}
              selectedInterval={INTERVALS[selectedInterval]}
              intervalChange={setIntervalIndex}
              categoryOptions={categoryOptions}
              onCategoryChange={setCategory}
              selectedCategory={category}
            />
          )}
          {bottlesQuantity && bottlesQuantity.length > 0 && (
            <BottlesGraph
              isMobile={isMobile}
              categoryOptions={[
                {value: 'Quantities', label: t('volume.bottles.quantity')},
                {value: 'Activity', label: t('volume.bottles.activity')}
              ]}
              time={option}
              currency={currency}
              selectedCategory={bottlesGraphType}
              onCategoryChange={bottlesGraphTypeChange}
              quantityGraphDataPoints={bottlesQuantity}
              activityDataPoints={bottlesActivity}
              timeFormat={timeFormat}
              selectedInterval={INTERVALS[selectedInterval]}
              intervalChange={setIntervalIndex}
              horeca={horeca}
            />
          )}
          {availableCategories.length > 0 && (
            <WasteGraph
              dataPoints={wastes}
              emptyText={t('volume.waste.empty')}
              valueType="L"
              onCategoryChange={setCategory}
              selectedCategory={category}
              categoryOptions={categoryOptions}
            />
          )}
        </CardsContainer>
      )}
    </>
  )
}

export default withRouter(Volume)
