import styled from 'styled-components'
import font from '../../../shared/style/font'
import colors from '../../../shared/style/colors'

export default styled.div`
  display: flex;
  padding: 0.325rem 0;
  
  & > h6 {
    color: ${colors.DARK};
    font-family: ${font.family};
    font-size: 1rem;
    margin: 0;
  }
  
  & > span {
    color: ${colors.GREY_DARK};
    margin-left: 1rem;
  }
`
