import styled from 'styled-components'

import font from '../../../shared/style/font'
import colors from '../../../shared/style/colors'

export default styled.h2`
  color: ${colors.PRIMARY_DARK};
  font-family: ${font.family};
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
`
