import styled from 'styled-components'

import colors from '../../style/colors'

export default styled.button`
  background: ${colors.PRIMARY};
  border: none;
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  padding: 0.5625rem 1rem;
  
  &:hover {
    background: ${colors.PRIMARY_DARKEST};
  }
  
  &:focus {
    outline: 0;
  }
`
