import styled from 'styled-components'
import {transparentize} from 'polished'

import colors from '../../shared/style/colors'
import font from '../../shared/style/font'

export default styled.p`
  color: ${transparentize(0.5, colors.WHITE)};
  font-family: ${font.family};
  font-size: 0.9rem;
  text-align: center;
  
  & a {
    color: ${transparentize(0.3, colors.WHITE)};
    text-decoration: none;
  }
`
