import React from 'react'
import {useTranslation} from 'react-i18next'

import Column from '../Column'
import Title from './Title'
import PieGraph from '../../PieGraph'

interface Props {
  psr: any[]
}

const PSR: React.FC<Props> = ({psr}) => {
  const {t} = useTranslation()

  return (
    <Column>
      <Title>{t('volume.psr.title')}</Title>
      <PieGraph dataPoints={psr} isPSR />
    </Column>
  )
}

export default PSR
