import React from 'react'
import styled from 'styled-components'

import {Device, ProductGroup} from '../../../types'
import font from '../../../shared/style/font'

const List = styled.ul`
  margin: 0;
  padding: 0.5rem 0 0.5rem 4rem;
`

const Item = styled.li`
  font-family: ${font.family};
  font-size: 0.925rem;
  padding: 0.5rem 0;
`

const Volume = styled.span`
  font-weight: bold;
  padding-left: 0.25rem;
`

interface Row {
  original: Device
}

interface TOptions {
  [key: string]: string | number
}

interface Props {
  groups?: ProductGroup[]
  row: Row
  t: (key: string, options?: TOptions) => string
}

const DeviceSubComponent: React.FC<Props> = ({row: {original}, groups, t}) => {
  return (
    <List>
      {Object.keys(original.config).map((k: string) => {
        let value, type, number
        let show = true

        if (original.model === 'CASCADE+') {
          type = 'line'
          number = parseInt(k, 10)
          const group =
            groups && original.config[k] && groups.find(g => g.id === original.config[k])
          value = group ? `${group.name} (${group.category})` : ''
        } else {
          const isTemp = k.includes('temp')
          type = isTemp ? 'temp' : 'press'
          const numberMatch = k.match(/\d+/)
          number = numberMatch ? numberMatch[0] : ''
          value = original.config[k]
        }

        return (
          show && (
            <Item key={k}>
              [{t(`config.device.form.${type}.label`, {number})}]:
              <Volume>{value}</Volume>
            </Item>
          )
        )
      })}
    </List>
  )
}

export default DeviceSubComponent
