import styled from 'styled-components'

import colors from '../../../style/colors'
import font from '../../../style/font'

export default styled.div`
  color: ${colors.LINK_BLUE};
  cursor: pointer;
  font-family: ${font.family};
  font-size: 0.875rem;
  margin-left: 0.5rem;
`
