import styled from 'styled-components'

import colors from '../../style/colors'
import font from '../../style/font'

export default styled.div`
  font-family: ${font.family};
  background-color: ${colors.PRIMARY};
  border-radius: 0.25rem;
  width: 100%;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
`
