import React, {useCallback} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFastForward, faForward, faFastBackward, faBackward} from '@fortawesome/pro-solid-svg-icons'
import {useTranslation} from 'react-i18next'

import colors from '../../../style/colors'
import Button from './Button'
import ButtonContainer from './ButtonContainer'
import Container from './Container'
import Count from './Count'

interface Props {
  canNextPage: boolean
  canPreviousPage: boolean
  currentPage: number
  gotoPage: (page: number) => void
  nextPage: () => void
  previousPage: () => void
  totalPages: number
}

const Pagination: React.FC<Props> = ({
 canNextPage, canPreviousPage, gotoPage, nextPage, previousPage, currentPage, totalPages
}) => {
  const {t} = useTranslation()

  const toFirstPage = useCallback(() => {
    gotoPage(0)
  }, [gotoPage])

  const toLastPage = useCallback(() => {
    gotoPage(totalPages - 1)
  }, [gotoPage, totalPages])

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={toFirstPage} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faFastBackward} color={colors.DARK} />
        </Button>
        <Button onClick={previousPage} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faBackward} color={colors.DARK} />
        </Button>
        <Button onClick={nextPage} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faForward} color={colors.DARK} />
        </Button>
        <Button onClick={toLastPage} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faFastForward} color={colors.DARK} />
        </Button>
      </ButtonContainer>
      <Count>
        {`${currentPage} ${t('common.pagination.of')} ${totalPages}`}
      </Count>
    </Container>
  )
}

export default Pagination
