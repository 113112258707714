import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import TextArea from '../../../shared/components/TextArea'
import Select from '../../../shared/components/Select'
import Button from '../../../shared/components/Button'
import FormContainer from './FormContainer'

const options: any[] = []

for (let i = 0; i < 24; i++) {
  options.push({
    label: `${i}:00`,
    value: i
  })
}

interface FormProps {
  name: string
  address: string
  currency: string
  openingHour?: number
}

interface Props extends FormikConfig<FormProps> {
  error?: AxiosError
  isFetching?: boolean
}

const Form: React.FC<Props> = ({error, initialValues, isFetching, onSubmit, ...rest}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('common.error.required')),
        address: Yup.string(),
        currency: Yup.string().required(t('common.error.required')),
        openingHour: Yup.number().required(t('common.error.required'))
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && <Error component={ErrorComponent} error={error} converters={[]} />}
            <Field
              id="horeca-form-name"
              name="name"
              type="text"
              component={Input}
              label={t('horeca.create.form.name.label')}
              placeholder={t('horeca.create.form.name.placeholder')}
            />
            <Field
              id="horeca-form-address"
              name="address"
              component={TextArea}
              label={t('horeca.create.form.address.label')}
              placeholder={t('horeca.create.form.address.placeholder')}
            />
            <Field
              id="horeca-form-opening-hour"
              name="openingHour"
              options={options}
              component={Select}
              label={t('horeca.create.form.openingHour.label')}
              placeholder={t('horeca.create.form.openingHour.placeholder')}
            />
            <Field
              id="horeca-form-currency"
              name="currency"
              type="text"
              component={Input}
              label={t('horeca.create.form.currency.label')}
              placeholder={t('horeca.create.form.currency.placeholder')}
            />

            <Button disabled={isFetching} type="submit">
              {isFetching && <FontAwesomeIcon icon={faCog} spin />}
              {!isFetching && t('horeca.create.form.submit')}
            </Button>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default Form
