import React from 'react'
import {Formik, Field, FormikConfig} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/pro-light-svg-icons'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {AxiosError} from 'axios'

import Error from '../../../shared/components/Error'
import ErrorComponent from '../../../shared/components/Error/Public'
import Input from '../../../shared/components/Input'
import Button from '../../../shared/components/Button'
import FormContainer from '../FormContainer'
import ButtonsContainer from '../ButtonsContainer'

interface Config {
  [key: string]: string
}

interface FormProps {
  id?: number
  name: string
  config: Config
}

interface Props extends FormikConfig<FormProps> {
  cancel: () => void
  error?: AxiosError
  isFetching?: boolean
}

const CaveForm: React.FC<Props> = ({
  cancel,
  error,
  initialValues,
  isFetching,
  onSubmit,
  ...rest
}) => {
  const {t} = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('common.error.required'))
      })}
      onSubmit={onSubmit}
      render={props => {
        return (
          <FormContainer onSubmit={props.handleSubmit}>
            {error && <Error component={ErrorComponent} error={error} converters={[]} />}
            <Field
              id="custom-form-name"
              name="name"
              type="text"
              component={Input}
              label={t('config.device.form.name.label')}
              placeholder={t('config.device.form.name.placeholder')}
            />

            {Object.keys(initialValues.config).map((key: string) => {
              const isTemp = key.includes('temp')
              const numberMatch = key.match(/\d+/)
              const number = numberMatch ? numberMatch[0] : ''
              const type = isTemp ? 'temp' : 'press'

              return (
                <Field
                  id={`custom-form-${key}`}
                  name={`config.${key}`}
                  component={Input}
                  label={t(`config.device.form.${type}.label`, {number})}
                  placeholder={t(`config.device.form.${type}.placeholder`, {number})}
                />
              )
            })}

            <ButtonsContainer>
              <Button
                tertiary
                disabled={isFetching}
                type="button"
                onClick={() => {
                  props.handleReset()
                  cancel()
                }}
              >
                {t('config.device.form.cancel')}
              </Button>

              <Button disabled={isFetching} type="submit">
                {isFetching && <FontAwesomeIcon icon={faCog} spin />}
                {!isFetching && t(`config.device.form.submit.update`)}
              </Button>
            </ButtonsContainer>
          </FormContainer>
        )
      }}
      {...rest}
    />
  )
}

export default CaveForm
