import styled from 'styled-components'
import colors from '../../../shared/style/colors'

export default styled.div<{compare?: boolean}>`
  font-size: 0.9rem;
  width: 100%;
  
  ${({compare}) => !compare && `
    color: ${colors.DARK};
  `}
`
