import styled from 'styled-components'

export default styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25rem 0.5rem;

  &:focus {
    outline: 0;
  }
`
