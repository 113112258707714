import styled from 'styled-components'

// @ts-ignore
import backgroundImage from '../../../assets/background.jpg'

export default styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${backgroundImage});
  padding: 0;
  margin-bottom: 2rem;
`
