import styled from 'styled-components'
import colors from '../../../../shared/style/colors'
import font from '../../../../shared/style/font'

export default styled.a`
  background-color: ${colors.WHITE};
  color: ${colors.PRIMARY};
  font-family: ${font.family};
  margin-left: 1rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  
  &:hover {
    background-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
  }
`
