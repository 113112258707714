import styled from 'styled-components'

import font from '../../../style/font'
import colors from '../../../style/colors'

export default styled.button`
  color: ${colors.WHITE};
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: ${font.family};
  font-size: 1.2rem;
  padding: 0.625rem;
  text-decoration: none;
  
  &:hover {
    color: ${colors.PRIMARY};
  }
`
